import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(props => ({
  borderRadius: props.theme.app.button.borderRadius,
  fontWeight: props.theme.app.button.fontWeight,
  textTransform: "none",
}));

const MuiButton = (props: ButtonProps) => {
  return (
    <StyledButton {...props} />
  );
}

export default MuiButton;