import { styled } from '@mui/material/styles';
import Typography from "@mui/material/Typography";

const StyledTitle = styled(Typography)((props) => ({
  "&&": {
    background: "linear-gradient(#FF6188, #FFB199)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    lineHeight: "21px"
  }
}));

export {
  StyledTitle
}