import { NumberUnit } from "src/enums";

export function displayNumber({ 
  input, 
  unit,
  showUnit = false
} : {
  input: string | number | undefined, 
  unit?: NumberUnit,
  showUnit?: boolean
}): string {
  let numericInput = Number(input);
  let displayUnit = "";

  if(!input || isNaN(numericInput)) {
    return "-";
  }
  
  if(unit === NumberUnit.B || (!unit && Math.abs(numericInput) > 1000000000)) {
    numericInput = numericInput / 1000000000;
    displayUnit = NumberUnit.B;
  } else if(unit === NumberUnit.M || (!unit && Math.abs(numericInput) > 100000)) {
    numericInput = numericInput / 1000000;
    displayUnit = NumberUnit.M;
  }

  if(Number.isInteger(numericInput) || Number.isInteger(+numericInput.toFixed(2))) {
    return `${numericInput.toFixed(0)}${showUnit ? displayUnit : ''}`;
  }

  return `${numericInput.toFixed(2)}${showUnit ? displayUnit : ''}`;
}

export function displayPercentage(
  {
    input, 
    isMultiple = true
  } :
  {
    input: string | undefined;
    isMultiple?: boolean
  }) {

  const numericInput = Number(input);

  if(!input || isNaN(numericInput)) {
    return "-";
  }

  const changeDirection = numericInput > 0 ? '+' : '';
  const changePercentage = isMultiple ? numericInput * 100 : numericInput;

  if(Number.isInteger(numericInput)) {
    return `${changeDirection}${(changePercentage).toFixed(0)}%`;
  }

  return `${changeDirection}${(changePercentage).toFixed(2)}%`;
}