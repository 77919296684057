import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';

import * as queries from 'src/graphql/queries';
import { ChartOverviewUiMinute, ChartOverviewUi, SymbolChart, MinChartRange, DayChartRange, ChartRange } from 'src/types';
import { isChartOverviewMinute, isChartRangeMinute } from 'src/utils/typeGuards';

type GetChartOverviewMinuteResponse = {
  data: {
    getChartOverviewUi: SymbolChart<ChartOverviewUiMinute>[]
  }
}

type GetChartOverviewDayResponse = {
  data: {
    getChartOverviewUi: SymbolChart<ChartOverviewUi>[]
  }
}

async function getChartOverviewMinute(range: MinChartRange, symbols: string[]): Promise<SymbolChart<ChartOverviewUiMinute>[]> {
  const { data: { getChartOverviewUi }} = 
    await (API.graphql(graphqlOperation(
      queries.getChartOverviewUiMinute, 
      { range, symbols }
    )) as Promise<GetChartOverviewMinuteResponse>);

  return getChartOverviewUi;
}

async function getChartOverviewDay(range: DayChartRange, symbols: string[]): Promise<SymbolChart<ChartOverviewUi>[]> {
  const { data: { getChartOverviewUi }} = 
    await (API.graphql(graphqlOperation(
      queries.getChartOverviewUi, 
      { range, symbols }
    )) as Promise<GetChartOverviewDayResponse>);

  return getChartOverviewUi;
}

function useChartOverview(range: DayChartRange, symbols: string[]): UseQueryResult<ChartOverviewUi[]>
function useChartOverview(range: MinChartRange, symbols: string[]): UseQueryResult<ChartOverviewUiMinute[]>
function useChartOverview(range: ChartRange, symbols: string[]): UseQueryResult<ChartOverviewUi[] | ChartOverviewUiMinute[]>
function useChartOverview(range: MinChartRange | DayChartRange, symbols: string[]): UseQueryResult<ChartOverviewUi[] | ChartOverviewUiMinute[]> {
  const options: UseQueryOptions<
    SymbolChart<ChartOverviewUiMinute>[] | SymbolChart<ChartOverviewUi>[], 
    unknown, 
    ChartOverviewUiMinute[] | ChartOverviewUi[]
  > = { 
    enabled: !!range && symbols.length > 0,
    cacheTime: 0,
    refetchInterval: 15000,
    select: (chartData) => {
      if(chartData.length === 1) {
        // query for one symbol
        return chartData[0].data;
      }

      const firstSymbolData = chartData[0].data;

      const result = firstSymbolData.map((firstSymbolDataPoint, dataIndex) => {
        let dataPoint = {} as any;

        const symbol = firstSymbolDataPoint.symbol
        const xAxisKey = firstSymbolDataPoint.date;
        const price = firstSymbolDataPoint.close;

        dataPoint['xAxisKey'] = xAxisKey;
        dataPoint['date'] = firstSymbolDataPoint.date;
        dataPoint['minute'] = isChartOverviewMinute(firstSymbolDataPoint) ? firstSymbolDataPoint.minute : null;

        dataPoint[`${symbol}Symbol`] = symbol;
        dataPoint[`${symbol}Price`] = price;

        for(let i = 1; i < chartData.length; i++) {
          const symbolChartData = chartData[i].data;
          const symbolChartDataPoint = symbolChartData[dataIndex];
          // Assume each symbols returns same amount of data point
          const currentSymbol = symbolChartDataPoint.symbol;
          const currentPrice = symbolChartDataPoint.close;

          dataPoint[`${currentSymbol}Symbol`] = currentSymbol;
          dataPoint[`${currentSymbol}Price`] = currentPrice;
        }

        return dataPoint;
      });
      
      return result;
    }
  };

  const queryFn = isChartRangeMinute(range) ? () => getChartOverviewMinute(range, symbols) : () => getChartOverviewDay(range, symbols);

  return useQuery(
    ['chartOverview', range, symbols],
    queryFn, 
    options
  );
}

export default useChartOverview;