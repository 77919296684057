import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { useTheme, Theme } from '@mui/material/styles';

import {
  Grid,
	BoxPrimary,
} from 'src/components/mui';
import TitleWithCurrency from 'src/components/TitleWithCurrency';
import {
  StyledSector,
  StyledSymbolChange
} from './styled';

import { useQuotes } from 'src/hooks';
import { TRealTimeQuoteFull } from 'src/types';
import { QuoteChange } from 'src/enums';
import { getChangeColor } from "src/utils";
import { displayPercentage } from "src/utils/numberUtils";

function Sectors() {
  const theme = useTheme();
  const symbols = SectorsConfig.map(({ symbol }) => symbol);

  const { data: realtimeQuotes, isLoading, isSuccess, isFetched } = useQuotes(symbols);

  if(isLoading || !isFetched) {
    return (
      <BoxPrimary>
        <TitleWithCurrency title="Sectors" hideCurrency />
        <Grid container spacing={1}>
          <Grid item xs={6} md={4} lg={12}>
            <Skeleton variant="text" width="100%" />
          </Grid>
          <Grid item xs={6} md={4} lg={12}>
            <Skeleton variant="text" width="100%" />
          </Grid>
          <Grid item xs={6} md={4} lg={12}>
            <Skeleton variant="text" width="100%" />
          </Grid>
          <Grid item xs={6} md={4} lg={12}>
            <Skeleton variant="text" width="100%" />
          </Grid>
          <Grid item xs={6} md={4} lg={12}>
            <Skeleton variant="text" width="100%" />
          </Grid>
          <Grid item xs={6} md={4} lg={12}>
            <Skeleton variant="text" width="100%" />
          </Grid>
        </Grid>
      </BoxPrimary>
    )
  }

  return isSuccess ? (
    <BoxPrimary>
      <TitleWithCurrency title="Sectors" hideCurrency />
      <Grid container spacing={1}>
        {renderSectors(realtimeQuotes, theme)}
      </Grid>
    </BoxPrimary>
  ) : null;
}

function renderSectors(realtimeQuotes: TRealTimeQuoteFull[] | undefined, theme: Theme) {

  if(!realtimeQuotes) {
    return null;
  }

  return SectorsConfig.map(({ name, symbol }) => {
    let changeDirection: QuoteChange = QuoteChange.Flat;

    const quote = realtimeQuotes.find((eachQuote) => eachQuote.symbol === symbol);

    if(!quote) {
      return (
        <Grid item xs={6} md={4} lg={6} key={name}>
          <StyledSector changeDirection={changeDirection} p={1}>
            <Typography variant="caption" style={{ textTransform: "capitalize" }}>{name}</Typography>
            <StyledSymbolChange>
              <Typography variant="body1">{symbol}</Typography>
            </StyledSymbolChange>
          </StyledSector>
        </Grid>
      );
    }

    changeDirection = +quote.change > 0 ? QuoteChange.Up : +quote.change === 0 ? QuoteChange.Flat : QuoteChange.Down;

    const sectorProps = {
      changeDirection 
    }

    const isMarketOpen = quote.isUSMarketOpen !== 'False';
    const changeColor = getChangeColor({ isCompare: false, change: quote.change, isMarketOpen, theme });

    return (
      <Grid item xs={6} md={4} lg={6} key={name}>
        <StyledSector {...sectorProps} p={1}>
          <Typography variant="caption" style={{ textTransform: "capitalize" }}>{name}</Typography>
          <StyledSymbolChange>
            <Typography variant="body1">{symbol}</Typography>
            <Typography variant="body1" color={changeColor}>{`${displayPercentage({ input: quote?.changePercent })}`}</Typography>
          </StyledSymbolChange>
        </StyledSector>
      </Grid>
    )
  })
}

const SectorsConfig = [
  { name: 'technology', symbol: 'XLK' },
  { name: 'healthcare', symbol: 'XLV' },
  { name: 'consumer staples', symbol: 'XLP' },
  { name: 'utilities', symbol: 'XLU' },
  { name: 'consumer discr.', symbol: 'XLY' },
  { name: 'communication service', symbol: 'XLC' },
  { name: 'basic materials', symbol: 'XLB' },
  { name: 'financial services', symbol: 'XLF' },
  { name: 'industrials', symbol: 'XLI' },
  { name: 'energy', symbol: 'XLE' },
  { name: 'real estate', symbol: 'XLRE' },
];

export default Sectors;