import * as React from 'react';
import { ModalType } from 'src/enums'; 

type Action = { type: ModalType, data?: any };
type Dispatch = (action: Action) => void;
type State = {
  type: ModalType,
  data?: any
};
type ModalProviderProps = { children: React.ReactNode };

const ModalStateContext = React.createContext<
  {state: State; dispatch: Dispatch} | undefined
>(undefined);

function modalReducer(state: State, action: Action) {
  switch (action.type) {
    case ModalType.SignUpMFA:
    case ModalType.ChangePasswordMFA:
    case ModalType.UpdateEmailMFA:
    case ModalType.ForgotPasswordSubmit:
    case ModalType.PressRelease: {
      return {
        type: action.type,
        data: action.data
      };
    }
    case ModalType.SignIn:
    case ModalType.SignUp:
    case ModalType.ForgotPassword:
    case ModalType.None: {
      return {
        type: action.type,
      };
    }
    default: {
      throw new Error(`Unhandled modal type: ${action.type}`);
    }
  }
}

function ModalProvider({children}: ModalProviderProps) {
  const [state, dispatch] = React.useReducer(modalReducer, { type: ModalType.None });
  
  const value = { state, dispatch };

  return (
    <ModalStateContext.Provider value={value}>
      {children}
    </ModalStateContext.Provider>
  );
}

function useModal() {
  const context = React.useContext(ModalStateContext);

  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return context;
}
export { ModalProvider, useModal }