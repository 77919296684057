import {
  Grid,
	BoxPrimary
} from 'src/components/mui';
import WatchList from './watchlist';
import TopGainers from './gainers';
import TopLosers from './losers';
import TrendingStocks from './trendingStocks';
import Markets from './markets';
import Sectors from './sectors';
import FeedsContainer from 'src/containers/feeds';

const Home = () => {

	return (
		<Grid container spacing={2}>
			{/* Left */}
			<Grid item xs={12} md={8}>
				<Markets />
				<WatchList />
				<BoxPrimary>
					<FeedsContainer />
				</BoxPrimary>
			</Grid>
			{/* Right */}
			<Grid item xs={12} md={4}>
				<TopGainers />
				<TopLosers />
				<TrendingStocks />
				<Sectors />
			</Grid>
		</Grid>
	)
}

export default Home;