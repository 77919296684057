import { Switch, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";

import {
  BoxPrimary,
  Grid,
} from 'src/components/mui';
import { SentryRoute } from "src/components";
import { StyledContent } from "./styled";
import Overview from "./overview";
import Research from "./estimates";
import Valuations from "./valuations";
import Financials from "./financials";
import PeerComparisons from "./peerComparisons";
import Ownerships from "./ownerships";
import News from "./news";
import StockSummary from "./StockSummary";
import PressRelease from 'src/components/watchlist/PressRelease';

import { useSymbol } from "src/hooks";
import { Paths, StockPathEntity } from "src/routes/paths";
import { StockRouteParam } from './types';

const Stock = () => {

  const { symbolId } = useParams<StockRouteParam>();

  const { data: symbol } = useSymbol(symbolId);

  return (
    <Grid container spacing={1}>
      <StockSummary symbolId={symbolId} symbol={symbol} />

      <Grid container item sm={12} md={8}>
        <StyledContent container item sm={12} md={12}>
          <Switch>
            <SentryRoute exact path={`${Paths.STOCK}/${StockPathEntity.News}/:symbolId`}>
              <News symbol={symbol?.symbol} />
            </SentryRoute>  
            <SentryRoute exact path={`${Paths.STOCK}/${StockPathEntity.Ownerships}/:symbolId`}>
              <Ownerships symbol={symbol?.symbol} />
            </SentryRoute>
            <SentryRoute exact path={`${Paths.STOCK}/${StockPathEntity.Comparisons}/:symbolId`}>
              <PeerComparisons />
            </SentryRoute>
            <SentryRoute exact path={`${Paths.STOCK}/${StockPathEntity.Financials}/:symbolId`}>
              <Financials symbol={symbol?.symbol} />
            </SentryRoute>
            <SentryRoute exact path={`${Paths.STOCK}/${StockPathEntity.Valuations}/:symbolId`}>
              <Valuations symbol={symbol?.symbol} />
            </SentryRoute>
            <SentryRoute exact path={`${Paths.STOCK}/${StockPathEntity.Research}/:symbolId`}>
              <Research symbol={symbol?.symbol} />
            </SentryRoute>
            <SentryRoute path={`${Paths.STOCK}/${StockPathEntity.Overview}/:symbolId`}>
              <Overview symbolId={symbolId} />
            </SentryRoute>
          </Switch>
        </StyledContent>
      </Grid>
      <Grid item sm={12} md={4}>
        <BoxPrimary p={2} mb={2}>
          <Typography variant="h6" color="textSecondary" align="left">Press Releases</Typography>
          <PressRelease stack={true} symbol={symbol?.symbol || null} limit={20} />
        </BoxPrimary>
      </Grid>
    </Grid>
  );
}

export default Stock;