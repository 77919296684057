import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';

import * as queries from 'src/graphql/queries';
import { IndexChartOverview, SymbolChart, ChartRange } from 'src/types';

type GetIndexChartOverviewResponse = {
  data: {
    getIndexChartOverview: SymbolChart<IndexChartOverview>[]
  }
}

async function getIndexChartOverviewMinute(range: ChartRange, symbols: string[]): Promise<SymbolChart<IndexChartOverview>[]> {
  const { data: { getIndexChartOverview }} = 
    await (API.graphql(graphqlOperation(
      queries.getIndexChartOverview, 
      { range, symbols }
    )) as Promise<GetIndexChartOverviewResponse>);

  return getIndexChartOverview;
}

function useIndexChartOverview(range: ChartRange, symbols: string[]): UseQueryResult<IndexChartOverview[]> {
  const options: UseQueryOptions<
    SymbolChart<IndexChartOverview>[], 
    unknown, 
    IndexChartOverview[]
  > = { 
    enabled: !!range && symbols.length > 0,
    cacheTime: 0,
    refetchInterval: 15000,
    select: (chartData) => {
      if(chartData.length === 1) {
        return chartData[0].data;
      }

      const firstSymbolData = chartData[0].data;

      const result = firstSymbolData.map((firstSymbolDataPoint, dataIndex) => {
        let dataPoint = {} as any;

        const symbol = firstSymbolDataPoint.symbol
        const xAxisKey = firstSymbolDataPoint.date;
        const price = firstSymbolDataPoint.close;

        dataPoint['xAxisKey'] = xAxisKey;
        dataPoint['date'] = firstSymbolDataPoint.date;
        dataPoint['minute'] = firstSymbolDataPoint.minute;

        dataPoint[`${symbol}Symbol`] = symbol;
        dataPoint[`${symbol}Price`] = price;

        for(let i = 1; i < chartData.length; i++) {
          const symbolChartData = chartData[i].data;
          const symbolChartDataPoint = symbolChartData[dataIndex];
          // Assume each symbols returns same amount of data point
          const currentSymbol = symbolChartDataPoint.symbol;
          const currentPrice = symbolChartDataPoint.close;

          dataPoint[`${currentSymbol}Symbol`] = currentSymbol;
          dataPoint[`${currentSymbol}Price`] = currentPrice;
        }

        return dataPoint;
      });

      return result;
    }
  };

  return useQuery(
    ['chartOverview', range, symbols],
    () => getIndexChartOverviewMinute(range, symbols), 
    options
  );
}

export default useIndexChartOverview;