import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';

import * as queries from 'src/graphql/queries';
import { FmpTicker } from 'src/types';

type GetMostGainerResponse = {
  data: {
    getMostGainer: FmpTicker[]
  }
}

async function getMostGainer(): Promise<FmpTicker[]> {
  const { data: { getMostGainer }} = 
    await (API.graphql(graphqlOperation(queries.getMostGainer)) as Promise<GetMostGainerResponse>);

  return getMostGainer;
}

function useTopGainers(): UseQueryResult<FmpTicker[]> {
  const options: UseQueryOptions<FmpTicker[]> = {
    staleTime: 60 * 1000 * 60 * 12
  };

  return useQuery(
    ['topGainers'],
    () => getMostGainer(),
    options
  );
}

export default useTopGainers;