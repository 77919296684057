import { TooltipProps } from "recharts/types/component/Tooltip";

import {
  Box,
} from 'src/components/mui';
import { ChartRange } from 'src/types';

function Tooltip({
  active,
  payload,
  render
} : TooltipProps<string, string> & { range?: ChartRange, render: Function }) {
  if(active && payload && payload.length) {
    return (
      <Box 
        sx={{ 
          background: (theme) => theme.app.background.cardPrimary,
          borderRadius: (theme) => `${theme.shape.borderRadius} px`,
          padding: 1.5,
        }}
      >
        {
         render(payload)
        }
        
      </Box>
    );
  }

  return null;
}

export default Tooltip;