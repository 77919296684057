import { styled } from '@mui/material/styles';
import { Box, BoxProps } from 'src/components/mui/Box';

import { QuoteChange } from 'src/enums';

type CustomStyledSectorProps = {
  changeDirection: QuoteChange,
} & BoxProps;

const StyledSector = styled(
  ({ changeDirection, ...otherProps }: CustomStyledSectorProps) => (<Box {...otherProps} />)
)((props) => ({
  backgroundColor: props.changeDirection === QuoteChange.Up ? props.theme.palette.success.light : props.theme.palette.error.light,
  color: props.theme.palette.text.secondary,
  borderRadius: props.theme.shape.borderRadius
}));

const StyledSymbolChange = styled('div')((props) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  color: props.theme.palette.text.secondary,
}));

export {
  StyledSector,
  StyledSymbolChange
}
