import { MouseEvent } from 'react';
import { useForgotPasswordSubmit, useForgotPassword, useSignIn } from 'src/hooks';

function useChangePasswordMfa({
  username, 
  newPassword,
}: {
  username: string;
  newPassword: string;
}) : {
  handleConfirm: (code: string) => void;
  handleResend: (event: MouseEvent<HTMLButtonElement>) => void;
  handleErrorClose: (event: MouseEvent<HTMLButtonElement>) => void;
  isConfirmLoading: boolean;
  isResendLoading: boolean;
  isError: boolean;
  shouldClose: boolean;
  hint: string;
  errorMsg?: string;
} {

  const forgotPasswordSubmitMutation = useForgotPasswordSubmit();
  const forgotPasswordMutation = useForgotPassword();
  const signInMutation = useSignIn();
  
  const hint = `You're almost done! We just sent the code to ${username}`;

  if(forgotPasswordSubmitMutation.isSuccess) {
    if(signInMutation.isIdle) {
      signInMutation.mutate({
        email: username,
        password: newPassword
      });
    }
  }

  function handleErrorClose() {
    forgotPasswordSubmitMutation.reset();
    forgotPasswordMutation.reset();
  }

  function handleResendForgotPassword() {
    forgotPasswordMutation.mutate({ username });
  }

  function handleConfirmChangePassword(code: string) {
    forgotPasswordSubmitMutation.mutate({
      username,
      newPassword,
      code
    });
  }

  return {
    handleConfirm: handleConfirmChangePassword,
    handleResend: handleResendForgotPassword,
    handleErrorClose,
    isConfirmLoading: forgotPasswordSubmitMutation.isLoading,
    isResendLoading: forgotPasswordMutation.isLoading,
    isError: forgotPasswordSubmitMutation.isError || forgotPasswordMutation.isError,
    shouldClose: forgotPasswordSubmitMutation.isSuccess,
    hint,
    errorMsg: forgotPasswordSubmitMutation.error?.message || forgotPasswordMutation.error?.message
  }
}

export default useChangePasswordMfa;