import { useState } from 'react';
import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Box } from 'src/components/mui';
import { StyledStripRow, StyledTableCell } from 'src/components/tables/styled';
import { SkeletonLoader } from 'src/components';

function OwnershipTable({
  columns,
  rows,
  isLoading,
  isFetched,
  isError,
} : {
  columns: any,
  rows: any,
  isLoading: boolean,
  isFetched: boolean,
  isError: boolean,
}) {
  const ROWS_PAGE_PAGE = 10;

  const [page, setPage] = useState(0);

  const theme = useTheme();
  const matchesUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  if(isLoading || !isFetched) {
    return (
      <>
        <Skeleton variant="text" width="100%" sx={{ mb: 1 }} />
        <SkeletonLoader noOfLoader={3} isColumn={true} height={100} />
        <Skeleton variant="text" width="100%" />
      </>
    )
  }

  if(!rows || isError) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant="body1" color="textSecondary" fontWeight={600}>No Data</Typography>
      </Box>
    );
  }

  return (
    <>
      <Box overflow="auto">
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column: any, headColIndex: number) => {
                const sx = headColIndex === 0 && matchesUpSm ? { position: "sticky", left: 0, background: 'white' } as SxProps<Theme> : {};

                return (
                  <StyledTableCell
                    variant="head"
                    key={column.id}
                    align={column.align}
                    sx={sx}
                  >
                    <Typography variant="body2" color="textPrimary" fontWeight={500} sx={{ whiteSpace: 'nowrap' }}>{column.label}</Typography>
                  </StyledTableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * ROWS_PAGE_PAGE, page * ROWS_PAGE_PAGE + ROWS_PAGE_PAGE)
              .map((row: any, index: number) => {
                return (
                  <StyledStripRow key={index}>
                    {columns.map((column: any, colIndex: number) => {
                      const value = row[column.id];
                      const sx = colIndex === 0 && matchesUpSm ? { position: "sticky", left: 0, background: 'inherit' } as SxProps<Theme> : {};

                      return (
                        <StyledTableCell key={column.id} align={column.align} sx={sx}>
                          <Typography variant="body2" color="textSecondary" sx={{ whiteSpace: 'nowrap' }}>
                            { 
                              column.format
                                ? column.format(value)
                                : value
                            }
                          </Typography>
                        </StyledTableCell>
                      );
                    })}
                  </StyledStripRow>
                );
              })}
          </TableBody>
        </Table>
      </Box>
      
      <TablePagination
        component="div"
        rowsPerPageOptions={[ROWS_PAGE_PAGE]}
        count={rows.length}
        rowsPerPage={ROWS_PAGE_PAGE}
        page={page}
        onPageChange={handleChangePage}
        sx={{
          '& p': {
            margin: 'auto'
          }
        }}
      />
    </>
  )
}

export default OwnershipTable;