import { styled } from '@mui/material/styles';
import CardHeader, { CardHeaderProps } from '@mui/material/CardHeader';

export const StyledCardHeader = styled(CardHeader)({
  '&&': {
    padding: 0
  }
});

const CardHeaderDefault = (props: CardHeaderProps) => {
  return (
    <StyledCardHeader {...props} titleTypographyProps={{ variant: 'h6', color: props.color || 'textPrimary' }} />
  )
}

export {
  CardHeaderDefault,
}