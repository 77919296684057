import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";

import {
  Box,
  BoxPrimary,
  CardHeaderDefault
} from 'src/components/mui';
import InsightContainer from "src/components/insight/InsightContainer";
import InsightDefinitionContainer from "src/components/insight/InsightDefinitionContainer";
import { useInsights } from "src/hooks";
import * as queries from 'src/graphql/queries';

const RisksAndHighlights = ({
  symbol
} : {
  symbol: string | undefined
}) => {
  const symbols = symbol ? [symbol] : [];

  const theme = useTheme();

  const { 
    data: valuationData, 
    isFetched: isValuationFetched, 
    isLoading: isValuationLoading, 
    isSuccess: isValuationSuccess 
  } = useInsights(symbols, queries.getInsightMatrixValuation);
  const valuationInsights = valuationData?.data.getInsightMatrixValuation.find(
    ({ symbol: eachSymbol }) => eachSymbol === symbol
  );

  const { 
    data: financialHealthData, 
    isFetched: isFinancialHealthFetched, 
    isLoading: isFinancialHealthLoading, 
    isSuccess: isFinancialHealthSuccess 
  } = useInsights(symbols, queries.getInsightMatrixFinancialHealth);
  const financialHealthInsights = financialHealthData?.data.getInsightMatrixFinancialHealth.find(
    ({ symbol: eachSymbol }) => eachSymbol === symbol
  );

  const { 
    data: growthData, 
    isFetched: isGrowthFetched, 
    isLoading: isGrowthLoading, 
    isSuccess: isGrowthSuccess 
  } = useInsights(symbols, queries.getInsightMatrixGrowth);
  const growthInsights = growthData?.data.getInsightMatrixGrowth.find(({ symbol: eachSymbol }) => eachSymbol === symbol);

  const { 
    data: ownershipData, 
    isFetched: isOwnershipFetched, 
    isLoading: isOwnershipLoading, 
    isSuccess: isOwnershipSuccess 
  } = useInsights(symbols, queries.getInsightMatrixShareholderOwnership);
  const ownershipInsights = ownershipData?.data.getInsightMatrixShareholderOwnership.find(
    ({ symbol: eachSymbol }) => eachSymbol === symbol
  );

  return (
    <BoxPrimary mb={2}>
      <CardHeaderDefault title="Risks & Highlights" sx={{ mb: 1 }} />
      <Box display="flex" justifyContent="flex-end" mb={1}>
        <InsightDefinitionContainer />
      </Box>
      <Box mb={1}>
        <Typography 
          variant="subtitle1" 
          color="textSecondary" 
          style={{ fontWeight: theme.typography.fontWeightBold }}
        >
          Growth
        </Typography>
      </Box>
      <InsightContainer 
        insights={growthInsights?.insights}
        isLoading={isGrowthLoading}
        isSuccess={isGrowthSuccess}
        isFetched={isGrowthFetched}
      />

      <Box mb={1}>
        <Typography 
          variant="subtitle1" 
          color="textSecondary" 
          style={{ fontWeight: theme.typography.fontWeightBold }}
        >
          Financial Health
        </Typography>
      </Box>
      <InsightContainer 
        insights={financialHealthInsights?.insights}
        isLoading={isFinancialHealthLoading}
        isSuccess={isFinancialHealthSuccess}
        isFetched={isFinancialHealthFetched}
      />

      <Box mb={1}>
        <Typography 
          variant="subtitle1" 
          color="textSecondary"
          style={{ fontWeight: theme.typography.fontWeightBold }}
        >
          Valuations
        </Typography>
      </Box>
      <InsightContainer 
        insights={valuationInsights?.insights}
        isLoading={isValuationLoading}
        isSuccess={isValuationSuccess}
        isFetched={isValuationFetched}
      />

      <Box mb={1}>
        <Typography 
          variant="subtitle1" 
          color="textSecondary"
          style={{ fontWeight: theme.typography.fontWeightBold }}
        >
          Shareholder & Ownership
        </Typography>
      </Box>
      <InsightContainer 
        insights={ownershipInsights?.insights}
        isLoading={isOwnershipLoading}
        isSuccess={isOwnershipSuccess}
        isFetched={isOwnershipFetched}
      />
    </BoxPrimary>    
  )
}

export default RisksAndHighlights;