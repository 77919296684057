import React from "react";
import { useAuthenticatedUser } from "src/hooks";
import { UserProvider } from 'src/contexts';

function UserAuthenticated({
  children,
  noRenderer,
  loadingRenderer = null
} : {
  children: React.ReactNode;
  noRenderer?: React.ReactNode;
  loadingRenderer?: React.ReactNode;
}) {
  const { data: authenticatedUser, isLoading, isFetched, isError } = useAuthenticatedUser();

  const isFetchingUser = isLoading && !isFetched;

  if(isFetchingUser) return <>{loadingRenderer}</>;

  if(isError) {
    return (
      <UserProvider value={{ authenticatedUser: null }}>
        {children}
      </UserProvider>
    );
  }

  if(!authenticatedUser) {

    if(noRenderer === undefined) {
      return (
        <UserProvider value={{ authenticatedUser: null }}>
          {children}
        </UserProvider>
      );
    }

    return (
      <UserProvider value={{ authenticatedUser: null }}>
        {noRenderer}
      </UserProvider>
    );
  }

  return (
    <UserProvider value={{ authenticatedUser }}>
      {children}
    </UserProvider>
  );
}

export default UserAuthenticated;