import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { Box } from 'src/components/mui';
import { SkeletonLoader } from 'src/components';
import {
  StyledTableRow,
  StyledTableCell
} from './styled';
import { displayNumber, displayPercentage } from 'src/utils/numberUtils';

type StockTableData = {
  ticker: string;
  companyName: string;
  price: string;
  changes: number;
  changesPercentage: string;
}

type StockTableProps = {
  data: StockTableData[] | undefined,
  isLoading: boolean,
  isFetched: boolean,
  isError: boolean,
}

const StockTable = ({
  data,
  isLoading,
  isFetched,
  isError,
}: StockTableProps) => {

  const theme = useTheme();

  if(isLoading || !isFetched) {
    return (
      <SkeletonLoader noOfLoader={5} isColumn={true} height={30} />
    )
  }

  if(isError) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight={50}>
        <Typography variant="body1" color="textSecondary" fontWeight={600}>No Data</Typography>
      </Box>
    );
  }

  return (
    <Table aria-label="stock performance table">
      <TableHead>
        <TableRow>
          <StyledTableCell variant="head">
            <Typography variant="body2" sx={{ color: theme.palette.text.disabled }}>Stock</Typography>
          </StyledTableCell>
          <StyledTableCell variant="head">
            <Typography variant="body2" sx={{ color: theme.palette.text.disabled }}>Last Price&nbsp;($)</Typography>
          </StyledTableCell>
          <StyledTableCell variant="head">
            <Typography variant="body2" sx={{ color: theme.palette.text.disabled }}>Changes</Typography>
          </StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map(({ ticker, price, companyName, changes, changesPercentage }) => {
          const changeColor = changes > 0 
            ? theme.palette.success.main
            : changes < 0 
            ? theme.palette.error.main
            : theme.palette.text.secondary;

          return (
            <StyledTableRow key={ticker} hover>
              <StyledTableCell align="left">
                <Typography variant="body2" color="textPrimary" fontWeight={600}>{ticker}</Typography>
                <Typography variant="body2" color="textSecondary">{companyName}</Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography variant="body2" color="textSecondary">
                  {displayNumber({ input: price })}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography variant="body2" sx={{ color: changeColor }}>
                  {displayNumber({ input: changes })}
                </Typography>
                <Typography variant="body2" sx={{ color: changeColor }}>
                  {displayPercentage({ input: changesPercentage, isMultiple: false})}
                </Typography>
              </StyledTableCell>
            </StyledTableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default StockTable;