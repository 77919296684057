import Search from "src/parts/Header/Search";
import { Box } from "src/components/mui";

function SearchMobile() {

  return (
    <Box p={2}>
      <Search />
    </Box>
  )
}

export default SearchMobile;