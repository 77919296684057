import Skeleton from '@mui/material/Skeleton';

import Insight from 'src/components/insight';
import {
  Box
} from 'src/components/mui';
import { InsightPoint } from 'src/types';
import { InsightContainerProps } from './types';

function InsightContainer({
  insights,
  isLoading,
  isSuccess,
  isFetched,
}: InsightContainerProps) {
  if(isLoading || !isFetched) {
    return (
      <Box display="flex" flexWrap="wrap">
        <Skeleton variant="text" style={{ width: '100%'}} />
        <Skeleton variant="text" style={{ width: '100%'}} />
      </Box>
    );
  }

  if(isSuccess && insights) {

    return (
      <>
        <Box display="flex" flexWrap="wrap" mb={0.5}>
          {
            insights
            .sort(sortImportancePredicate)
            .map(({ type, value, importance, id, sidePanel }) => {
              if(type === 'Highlight') {
                return (
                  <Insight key={value} id={id} value={value} importance={importance} sidePanel={sidePanel} />
                );
              }

              return null;
            })
          }
        </Box>
        <Box display="flex" flexWrap="wrap">
          {
            insights
            .sort(sortImportancePredicate)
            .map(({ type, value, importance, id, sidePanel }) => {
              if(type === 'Risk') {
                return (
                  <Insight key={id} id={id} isRisk={true} value={value} importance={importance} sidePanel={sidePanel} />
                );
              }

              return null;
            })
          }
        </Box>
      </>
    );
  }

  return null;
}

function sortImportancePredicate(a: InsightPoint, b: InsightPoint) {
  if(
    (a.importance === 'High' && b.importance !== 'High')
    || (a.importance === 'Medium' && b.importance === 'Low')
  ) {
    return -1;
  }

  if(
    (b.importance === 'High' && a.importance !== 'High')
    || (b.importance === 'Medium' && a.importance === 'Low')
  ) {
    return 1;
  }

  return 0;
}

export default InsightContainer;