import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';

import * as queries from 'src/graphql/queries';

type InsiderTransactionUi = {
  date: string
  name: string
  price: string
  sharesChange: string
  title: string
  type: string
}
type Response = {
  data: {
    getOwnershipInsiderTransactionUi: InsiderTransactionUi
  }
}

async function getOwnershipInsiderTransactionUi(symbol: string | undefined): Promise<InsiderTransactionUi> {
  const { data: { getOwnershipInsiderTransactionUi }} = 
    await (API.graphql(graphqlOperation(
      queries.getOwnershipInsiderTransactionUi, 
      { symbol: symbol || '' }
    )) as Promise<Response>);

  return getOwnershipInsiderTransactionUi;
}

function useOwnershipInsiderTransactionUi(symbol: string | undefined): UseQueryResult<InsiderTransactionUi> {
  const options: UseQueryOptions<InsiderTransactionUi> = { 
    enabled: !!symbol,
  };

  return useQuery(
    ['getOwnershipInsiderTransactionUi', symbol], 
    () => getOwnershipInsiderTransactionUi(symbol), 
    options
  );
}

export default useOwnershipInsiderTransactionUi;