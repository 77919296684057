import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';

import * as queries from 'src/graphql/queries';
import { FmpTicker } from 'src/types';

type GetMostLoserResponse = {
  data: {
    getMostLoser: FmpTicker[]
  }
}

async function getMostLoser(): Promise<FmpTicker[]> {
  const { data: { getMostLoser }} = 
    await (API.graphql(graphqlOperation(queries.getMostLoser)) as Promise<GetMostLoserResponse>);

  return getMostLoser;
}

function useTopLosers(): UseQueryResult<FmpTicker[]> {
  const options: UseQueryOptions<FmpTicker[]> = {
    staleTime: 60 * 1000 * 60 * 12
  };

  return useQuery(
    ['topLosers'],
    () => getMostLoser(),
    options
  );
}

export default useTopLosers;