import React, { useState, useRef, useEffect } from 'react';
import useAutocomplete, { AutocompleteChangeReason } from '@mui/material/useAutocomplete';
import SearchIcon from '@mui/icons-material/Search';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';
import { useHistory } from "react-router";
import { useTheme } from '@mui/material/styles';

import Option, { IOption } from './Option';

import { useSearch, useUser, useUserResearch, useNavigation } from 'src/hooks';
import { useCognitoUser } from 'src/contexts';
import {
  StyledAutocomplete,
  StyledListBox,
  StyledSearch, 
  StyledSearchInput,
  StyledRecentSearches,
} from './styled';
import { Box, Button } from 'src/components/mui';

import { SearchUi, User } from 'src/types';

const Search = () => {
  const inputRef = useRef<HTMLElement>();
  const [searchInput, setSearchInput] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [showRecentSearch, setShowRecentSearch] = useState<boolean>(true);

  const { data: symbols } = useSearch(searchInput);

  const { authenticatedUser } = useCognitoUser();
  const { data: user } = useUser(authenticatedUser?.username);
  const { mutate: mutateUserResearch } = useUserResearch();

  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down('md'));

  const { navigateToStock } = useNavigation();
  const { goBack } = useHistory();

  useEffect(() => {
    if(inputRef.current) {
      if(matchesDownSm) {
        inputRef.current.focus();
      }
    }
  }, [inputRef, matchesDownSm])

  const options = getOptions({ showRecentSearch, user, symbols });

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
  } = useAutocomplete({
    id: "symbol-search",
    openOnFocus: true,
    freeSolo: true,
    blurOnSelect: true,
    options,
    open: matchesDownSm || open, // mandatory to avoid undefined selected option
    inputValue: searchInput,
    onInputChange: handleInputChange,
    onChange: handleChange,
    filterOptions: (option) => option,
    getOptionLabel: (option) => {
      const { symbol } = option;

      return symbol?.toUpperCase();
    },
  });

  function handleChange(
    event: React.ChangeEvent<{}>, 
    value: string | IOption | null, 
    reason: AutocompleteChangeReason,  
  ) {
    if(reason === "selectOption") {
      const { id } = value as IOption;
      setOpen(false);

      if(inputRef.current) {
        inputRef.current.blur();
      }
      mutateUserResearch({ userID: user?.id, symbolID: id });
      navigateToStock(id);
    }
  }

  function handleInputChange(event: React.ChangeEvent<{}>, value: string) {
    if(!open) {
      setOpen(true);
    }

    if(value) {
      setShowRecentSearch(false);
    } else {
      setShowRecentSearch(true);
    }

    setSearchInput(value);
  }

  function handleInputKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Enter') {
      // Prevent's default 'Enter' behavior.
      event.stopPropagation();
      return;
    }
  }

  function handleOnFocus() {
    if(options.length > 0) {
      setOpen(true);
    }
  }

  function handleOnBlur() {
    setOpen(false);
  }

  return (
    <StyledAutocomplete>
      <Box {...getRootProps()} display="flex" alignItems="center">
        <Box flexGrow={1}>
          <StyledSearch aria-describedby={'symbol-search'}>
            <StyledSearchInput
              inputRef={inputRef}
              placeholder="Search stock"
              inputProps={{
                ...getInputProps(),
                onKeyDown: handleInputKeyDown,
                onFocus: handleOnFocus,
                onBlur: handleOnBlur, 
                'aria-label': 'search stock' 
              }}
            />
            <SearchIcon color="action" />
          </StyledSearch>
        </Box>
        <Hidden mdUp>
          <Box ml={2}>
            <Button
              onClick={goBack}
            >
              <Typography variant="body1" color="textSecondary">cancel</Typography>
            </Button>
          </Box>
        </Hidden>
      </Box>
        {
          (open || matchesDownSm) && options.length > 0 ? (
            <StyledListBox {...getListboxProps()}>
              {
                renderRecentSearches(showRecentSearch, options)
              }
              {options.map((option, index) => {
                const optionProps = {
                  ...getOptionProps({ option, index }),
                  key: option.id
                }

                return (
                    <Option 
                      {...optionProps} 
                      option={option} 
                      userid={user?.id}
                    />
                )
              })}
            </StyledListBox>
          ) : null
        }
    </StyledAutocomplete>
  );
}

function renderRecentSearches(showRecentSearch: boolean, options: IOption[]) {
  return (showRecentSearch && options.length > 0) ? (
    <StyledRecentSearches>Recent Searches</StyledRecentSearches>
  ) : null
}

function getOptions(
  { showRecentSearch, user, symbols }: { showRecentSearch: boolean, user: User | undefined, symbols: SearchUi[] | undefined }
): IOption[] {
  if(showRecentSearch) {
    return user?.recentSearches.items.map(({ symbol: { id, symbol, name, exchange } }) => {
      let userFollow = null;

      if(user.follows) {
        userFollow = user.follows.items.find((eachUserFollow) => eachUserFollow.symbol.symbol === symbol);
      }

      return { id, exchange, symbol, name, followed: !!userFollow, userFollowID: userFollow?.id }
    }) || [];
  }
  
  return symbols?.map(({ symbolId, exchange, symbolName, name }) => {
    let userFollow = null;

    if(user?.follows) {
      userFollow = user.follows.items.find((eachUserFollow) => eachUserFollow.symbol.symbol === symbolName);
    }
  
    return { id: symbolId, exchange, symbol: symbolName, name, followed: !!userFollow, userFollowID: userFollow?.id }
  }) || [];
}

export default Search;