import { useState, useEffect } from 'react';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import { ChipProps } from '@mui/material/Chip';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link as RouterLink } from 'react-router-dom';

import { Paths } from 'src/routes/paths';
import { useUserFollow, useWatchlistSymbols } from 'src/hooks';
import { useCognitoUser, useModal } from 'src/contexts';
import {
  CardHeaderDefault,
  Grid,
	BoxPrimary,
  Box,
  ChipPrimary,
} from 'src/components/mui';
import SymbolSummary from 'src/components/watchlist/SymbolSummary';
import Insights from 'src/components/watchlist/Insights';
import PressRelease from 'src/components/watchlist/PressRelease';
import { ModalType } from 'src/enums';
import { Symbol } from 'src/types';

const WatchList = () => {
  const [selectedSymbolName, setSelectedSymbolName] = useState<string | null>(null);

  const theme = useTheme();

  const { dispatch: dispatchModal } = useModal();
  const { authenticatedUser } = useCognitoUser();
  const { symbols, user, isRecommended } = useWatchlistSymbols(authenticatedUser?.username)
  const { follow } = useUserFollow(user?.id);

  const isSymbolsExisted = symbols && symbols.length > 0;

  const symbolsToShow = isSymbolsExisted ? symbols.slice(0, Math.min(symbols.length, 5)) : [];

  const selectedSymbol = symbolsToShow.find(({ symbol }) => symbol === selectedSymbolName);

  useEffect(() => {

    if(!isSymbolsExisted) {
      return;
    }
    
    if(!selectedSymbolName || !selectedSymbol?.symbol) {
      setSelectedSymbolName(symbols[0].symbol);
    }

  }, [symbols, isSymbolsExisted, selectedSymbolName, selectedSymbol?.symbol]);

  function handleSymbolSelect(symbol: Symbol) {
    return function() {
      setSelectedSymbolName(symbol.symbol);
    }
  }

  function handleOnDelete(symbol: Symbol) {
    return function() {
      if(!authenticatedUser) {
        dispatchModal({ type: ModalType.SignIn });
        return;
      }
  
      follow.mutate({
        userID: user?.id, 
        symbolID: symbol.id
      });
    }
  }

  return (
    <BoxPrimary mb={2}>
      <CardHeaderDefault title="Watchlist" sx={{ mb: 1 }} />
      <Box display="flex" alignItems="center" mb={2}>
        <Grid container spacing={1}>
          {
            symbolsToShow.map((symbol) => {
              const recommendChipProps = isRecommended ? {
                deleteIcon: <AddCircleIcon />,
                // utilise mui prop to ask user for sign in if not
                onDelete: handleOnDelete(symbol),
                size: 'small',
                variant: 'outlined',
              } as Partial<ChipProps> : {};
              
              return (
                <Grid item key={symbol.symbol}>
                  {
                    symbol.symbol === selectedSymbolName 
                      ? <ChipPrimary label={symbol.symbol} color="primary" clickable {...recommendChipProps} />
                      : <ChipPrimary label={symbol.symbol} clickable onClick={handleSymbolSelect(symbol)} {...recommendChipProps} />
                  }
                </Grid>
              )}
            )
          }
        </Grid>
        {
          !isRecommended ? (
            <Box sx={{ whiteSpace: 'nowrap' }}>
              <Link 
                component={RouterLink} 
                to={Paths.WATCHLIST} 
                variant="body2" 
                color={theme.palette.text.disabled} 
                sx={{ '&:hover': { color: theme.palette.text.disabled }}}
              >
                Full Watchlist
              </Link>
            </Box>
          ) : null
        }
      </Box>
      <SymbolSummary symbol={selectedSymbol} isRecommended={isRecommended} />
      <Box mb={2} p={1}>
        <PressRelease symbol={selectedSymbolName} />
      </Box>
      <Insights symbolName={selectedSymbol?.symbol} />
    </BoxPrimary>
  )
}

export default WatchList;