import {
  AreaChart,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";

type Gradient = {
  id: string,
  startColor: string,
  stopColor: string ,
}

type AreaChartRcProps = {
  data: any,
  xKey: string,
  axisStroke?: string,
  gradient?: Gradient[],
  children: React.ReactNode,
  height: number,
}

function AreaChartRc({
  data,
  xKey,
  axisStroke = "#D9DBE9",
  gradient,
  height,
  children
} : AreaChartRcProps) {
  return (
    <ResponsiveContainer width="100%" height={height}>
      <AreaChart
        height={height}
        data={data}
        margin={{
          top: 20,
          right: 20,
          left: 20,
          bottom: 0
        }}
      >
        {
          gradient ? (
            <defs>
              {
                gradient.map(({ id, startColor, stopColor}) => (
                  <linearGradient id={id} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={startColor} stopOpacity={0.8} />
                    <stop offset="100%" stopColor={stopColor} stopOpacity={0} />
                  </linearGradient>
                ))
              }
            </defs>
          ) : null
        }
        
        <XAxis dataKey={xKey} tickLine={false} axisLine={{ stroke: axisStroke }} tick={{ fill: axisStroke }} interval="preserveStartEnd" />
        <YAxis hide domain={[(dataMin: any) => Math.floor(dataMin), (dataMax: any) => Math.ceil(dataMax)]} />
        { children }
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default AreaChartRc;