import { useTheme } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import Tooltip from '@mui/material/Tooltip';

import { Box } from "src/components/mui";
import { InsightImportance } from "src/enums";

function InsightDefinition({
  importance
} : {
  importance: InsightImportance
}) {
	const theme = useTheme();

	const insightBgOpacity = importance === InsightImportance.High ? 0.35 : importance === InsightImportance.Medium ? 0.15 : 0.05;

	const definition = importance === InsightImportance.High 
		? `The highlights or risks having high impact on stock price` 
		: importance === InsightImportance.Medium 
		? `The highlights or risks having medium impact on stock price` 
		: `The highlights or risks having low impact on stock price`;

	return (
		<Box display="flex" alignItems="center" mr={1}>
			<Box display="flex" flexDirection="column" mr={0.5}>
				<Box mb={0.1} sx={{ backgroundColor: theme.palette.success.main, opacity: insightBgOpacity, width: 10, height: 6 }} />
				<Box sx={{ backgroundColor: theme.palette.error.main, opacity: insightBgOpacity, width: 10, height: 6 }} />
			</Box>
			<Tooltip title={definition} enterTouchDelay={100}>
				<Typography variant="body2" color="textSecondary" >{ importance }</Typography>
			</Tooltip>
		</Box>
	);
}

export default InsightDefinition;