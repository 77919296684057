import {
  Box,
  BoxPrimary,
  CardHeaderDefault
} from 'src/components/mui';
import ExpandableText from "src/components/expandableText";
import MarketInformation from "src/pages/stock/overview/marketInformation";
import RisksAndHighlights from "src/pages/stock/overview/risksAndHighlights";

import { useSymbol } from "src/hooks";

type OverviewProps = {
  symbolId: string;
}

function Overview({
  symbolId
}: OverviewProps) {

  const { data: symbol } = useSymbol(symbolId);

  return (
    <Box style={{ width: '100%' }} >
      <BoxPrimary mb={2}>
        <CardHeaderDefault title="Business Profile" sx={{ mb: 1 }} />
        <ExpandableText text={symbol?.company.description} />
      </BoxPrimary>
      <MarketInformation symbol={symbol?.symbol} />
      <RisksAndHighlights symbol={symbol?.symbol} />
    </Box>
  )
}

export default Overview;