import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import katex from 'katex';

import { StyledTitle } from './styled';
import ChartContainer from './ChartContainer';
import { BoxPrimary, Box } from "src/components/mui";
import Insight from 'src/components/insight';
import { useLearning } from 'src/contexts';
import { LearningType } from 'src/enums';

const LABEL_COMING_SOON = "Coming soon...";

function InsightDetails() {
  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down('md'));

  const { state } = useLearning();

  return (
    <BoxPrimary
      display="flex" 
      flexDirection="column"
      flexGrow={1} 
      style={{ 
        borderRadius: matchesDownSm ? '0' : theme.app.borderRadius.paper,
        border: matchesDownSm ? 'none' : `1px solid ${theme.app.learning.popper.box.default.borderColor}`,
        padding: theme.spacing(2), 
        overflowY: 'auto',
        background: matchesDownSm ? theme.app.learning.popper.background : theme.app.learning.popper.box.default.background
      }}
    >
      {
        state.type === LearningType.Insight ? (
          <>
            <Insight 
              key={state.data.id} 
              id={state.data.id} 
              isRisk={state.data.isRisk} 
              value={state.data.value} 
              importance={state.data.importance} 
              disableBorder
              dark
            />
            <Box p={1}>
              <ChartContainer chartType={state.data.sidePanel?.chartType} visualization={state.data.sidePanel?.visualization} />
              <Box mb={1} mt={1}>
                <Typography variant="body1" color="white" component="p" mb={1}>
                  {
                    state.data.sidePanel?.explanation || LABEL_COMING_SOON
                  }
                </Typography>
              </Box>

              {
                state.data.sidePanel?.concept?.map((eachConcept) => (
                  eachConcept ? (
                    <Box mt={2} p={1} sx={{
                        background: theme.app.learning.popper.box.secondary.background,
                        borderRadius: `${theme.app.borderRadius.paper}px`,
                      }}
                    >
                      <StyledTitle variant="body1" fontWeight={500}>
                        {eachConcept.conceptName}
                      </StyledTitle>
                      <Box mb={1}>
                        <Typography variant="body1" color="white" component="p" mb={1}>
                          {eachConcept.definition || LABEL_COMING_SOON}
                        </Typography>
                      </Box>
      
                      {
                        eachConcept?.equationLaTeX.length > 0 ? 
                        (
                            <>
                              <StyledTitle variant="body1" fontWeight={500}>
                                Equation:
                              </StyledTitle>
                              {
                                eachConcept.equationLaTeX.map((eachEquation) => (
                                  <Box p={0.5} mb={1} sx={{ borderLeft: "2px solid #5AC8FB" }}>
                                    <Typography variant="body2" color="white" component="p" sx={{ wordBreak: "break-all" }}>
                                      {
                                        eachEquation ? (
                                          <div dangerouslySetInnerHTML={{ __html: katex.renderToString(eachEquation, { output: 'html' }) }} />
                                        ) : LABEL_COMING_SOON
                                      }
                                    </Typography>
                                  </Box>
                                ))
                              }
                            </>
                          ) : null
                      }
      
                      <StyledTitle variant="body1" fontWeight={500}>
                        Why does it matter?
                      </StyledTitle>
                      <Typography variant="body1" color="white" component="p">
                        {eachConcept.importance || LABEL_COMING_SOON}
                      </Typography>
                    </Box>
                  ) : null
                ))
              }
            </Box>
          </>
        ) : null
      }
    </BoxPrimary>
  );
}

export default InsightDetails;