import React from 'react';
import Typography from '@mui/material/Typography';

import { Grid, Button, ChipPrimary } from 'src/components/mui';
import {
  StyledOption,
  StyledExchange,
} from './styled';

import { useFollow } from 'src/hooks';

export interface IOption {
  id: string;
  exchange: string;
  symbol: string;
  name: string;
  followed: boolean;
  userFollowID: string | undefined;
}

interface OptionProps {
  option: unknown;
  userid: string | undefined;
}

const Option: React.FC<OptionProps> = (props) => {

  const { option, userid } = props;

  const {
    id,
    exchange,
    symbol,
    name,
    followed,
    userFollowID
  } = option as IOption;

  const { handleUnfollow, handleFollow } = useFollow({
    userid,
    userFollowId: userFollowID,
    symbolId: id,
  });

  return (
    <StyledOption {...props}>
      <Grid container>
        <Grid container item xs={10} wrap="nowrap" spacing={1} justifyContent="flex-start" alignItems="center">
          <Grid item>
            <StyledExchange variant="body2">{exchange}</StyledExchange>
          </Grid>
          <Grid item>
            <ChipPrimary textTransform="uppercase" label={symbol} sx={{ height: 30 }} />
          </Grid>
          <Grid item zeroMinWidth>
            <Typography  
              variant={'body2'} 
              noWrap={true} 
              color="textSecondary"
            >{name}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          {
            followed ? (
              <Button variant="text" color="primary" onClick={handleUnfollow}>
                Followed
              </Button>
            ) : (
              <Button variant="text" color="primary" onClick={handleFollow}>
                Follow
              </Button>
            )
          }
        </Grid>
      </Grid>
    </StyledOption>
  );
}

export default Option;