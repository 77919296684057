import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const AxisTick = (props: any) => {

  const { 
    x, y, payload: { value, index }, range, dataLength, 
    isTradingHour // only required to define ticks when range is '1d'
  } = props;
  
  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down('md'));

  if(!dataLength || dataLength === 0) {
    return null;
  }

  const tickBase = range === '1d' ? Math.floor(dataLength / 60) : Math.floor(dataLength / 6);
  const displayYear = range !== '1d' && range !== '5d' && range !=='1m';

  if(range === '1d') {
    const minuteTicks = getMinuteTicks({ dataLength, isTradingHour });

    const isTickIndex = minuteTicks.find((tickIndex) => tickIndex === index);

    if(isTickIndex) {
      const lineXMinute = '20';

      return (
        <g transform={`translate(${x},${y})`}>
          <line 
            orientation="bottom"
            stroke={theme.app.border.color.default} 
            fill="none" 
            x1={lineXMinute} 
            y1="-8" 
            x2={lineXMinute} 
            y2="0"
          />
          <text
            x={0}
            y={0}
            dy={16}
            fill={theme.palette.text.secondary}
            fontSize={theme.typography.body2.fontSize}
            fontFamily={theme.app.chart.font.family}
          >
            {value}
          </text>
        </g>
      );
    }

    return null;
  }

  const dayTicks = matchesDownSm 
    ? index === tickBase || index === 3 * tickBase || index === 5 * tickBase
    : index === tickBase || index === 2 * tickBase || index === 3 * tickBase || index === 4 * tickBase || index === 5 * tickBase;

  if(dayTicks) {
    try {
      const lineXDay = displayYear ? '32' : '25';
      const date = new Date(value);
      const tickLable = displayYear 
        ? `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()} `
        : `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;

      return (
        <g transform={`translate(${x},${y})`}>
          <line 
            orientation="bottom"
            stroke="#666" 
            fill="none" 
            x1={lineXDay} 
            y1="-8" 
            x2={lineXDay}
            y2="0"
          />
          <text
            x={0}
            y={0}
            dy={16}
            fill={theme.palette.text.secondary}
            fontSize={theme.typography.body2.fontSize}
            fontFamily={theme.app.chart.font.family}
          >
            {tickLable}
          </text>
        </g>
      );
    } catch (e) {
      console.error(e);
    }
    
  }
  
  return null;
};

function getMinuteTicks({ dataLength, isTradingHour } : {
  dataLength: any; 
  isTradingHour: any
}) {
  // hourly base
  const tickBase = Math.floor(dataLength / 60);

  if(isTradingHour) {
    if(tickBase === 0) {
      return [Math.floor(dataLength / 2)];
    }

    if(tickBase < 3) {
      return [30, 90];
    }

    if(tickBase < 4) {
      return [30, Math.floor(dataLength / 2), dataLength - 30];
    }

    return [60, Math.floor(dataLength / 2), dataLength - 60];
  }

  return [
    30, 180, 330
  ]
}

export default AxisTick;