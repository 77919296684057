import Typography from '@mui/material/Typography';

import {
  Box,
  CardHeaderDefault
} from 'src/components/mui';

interface TitleWithCurrencyProps {
  title: string;
  currency?: string;
  color?: string;
  hideCurrency?: boolean
}

function TitleWithCurrency({
  title,
  currency,
  color,
  hideCurrency = false,
} : TitleWithCurrencyProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        mb: 1,
        alignItems: 'baseline'
      }}
    >
      <CardHeaderDefault title={title} color={color} sx={{ mr: 2 }} />
      {
        !hideCurrency ? (
          <Typography variant="body2" color="textSecondary" sx={{ fontStyle: "italic" }}>
            in <span style={{ textTransform: "uppercase" }}>{currency}</span>
          </Typography>
        ) : null
      }
    </Box>
  );
}

export default TitleWithCurrency;