import { useUser, useRecommendedSymbols } from 'src/hooks';
import { Symbol, User, UserFollow } from 'src/types';

type ReturnType = {
  symbols: Symbol[] | undefined,
  user: User | undefined,
  userFollows: UserFollow[] | undefined,
  isRecommended: boolean
}

function useWatchlistSymbols(authenticatedUsername: string | undefined): ReturnType {
  const { data: user } = useUser(authenticatedUsername);
  const { data: recommendedSymbols } = useRecommendedSymbols();

  const userFollows = user?.follows?.items;

  const shouldRecommend = (userFollows && userFollows.length === 0) || !authenticatedUsername;

  const symbols = !shouldRecommend 
    ? userFollows?.map(({ symbol }) => symbol)
    : recommendedSymbols?.map((symbol) => symbol)

  return {
    symbols,
    user,
    userFollows,
    isRecommended: shouldRecommend,
  };
}

export default useWatchlistSymbols;