import React, { useState } from 'react';
import Hidden from '@mui/material/Hidden';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import { Hub } from 'aws-amplify';
import { NavLink } from "react-router-dom";

import { Button } from 'src/components/mui';
import { 
  StyledMenu,
  StyledMenuItem,
} from './styled';
import { useSignOut } from 'src/hooks';
import { useCognitoUser, useModal } from 'src/contexts';
import { Paths } from 'src/routes/paths';
import { ModalType } from 'src/enums';

const UserProfile: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { authenticatedUser } = useCognitoUser();  
  const { dispatch: dispatchModal } = useModal();
  const signOutMutation = useSignOut();


  if(signOutMutation.isSuccess) {
    window.location.reload();
  }

  const handleClick = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Mimic amplify sign out component behavior
  const handleSignOutClick = async () => {
    try {
        // @ts-ignore
        await signOutMutation.mutate();
        Hub.dispatch('UI Auth', {   // channel must be 'UI Auth'
            event: 'AuthStateChange',    // event must be 'AuthStateChange'
            message: 'signedout'    // message must be 'signedout'
        });
    } catch (error) {
        console.log('error signing out: ', error);
    }
  }; 

  const handleOpenModal = (type: ModalType.SignIn | ModalType.SignUp) => () => {
    dispatchModal({ type });
  }; 

  if(!authenticatedUser) {
    return (
      <>
        <Button sx={{ ml: { xs: 1, md: 2 }, mr: { xs: 0, md: 1 }}} variant="outlined" onClick={handleOpenModal(ModalType.SignIn)}>
          Sign in
        </Button>
        <Hidden smDown>
          <Button variant="text" onClick={handleOpenModal(ModalType.SignUp)}>
            Sign up
          </Button>
        </Hidden>
      </>
    );
  }

  return (
    <>
      <Avatar
        aria-controls="profile-menu"
        aria-haspopup="true"
        onClick={handleClick}
        {...stringAvatar(authenticatedUser.attributes.email)}
        sx={{
          backgroundColor: stringToColor(authenticatedUser.attributes.email),
          ml: 1,
          textTransform: 'uppercase',
          cursor: "pointer"
        }}
      />
      <StyledMenu
        id="profile-menu"
        anchorEl={anchorEl}
        autoFocus={false}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        variant='menu'
      >
        <StyledMenuItem>
          <NavLink
            to={Paths.SETTINGS}
            style={{ textDecoration: "none", width: "100%" }}
          >
            <ListItemText primary="Settings" primaryTypographyProps={{ variant: "body1" }} />
          </NavLink>
        </StyledMenuItem>
        <StyledMenuItem disableRipple onClick={handleSignOutClick}>
          <ListItemText primary="Logout" primaryTypographyProps={{ variant: "body1" }} />
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    children: name.substr(0, 1),
  };
}

export default UserProfile
