import { displayNumber, displayPercentage } from "src/utils/numberUtils";

export const InstitutionalInvestorsConfigs = [
  { id: 'name', label: 'Investor', minWidth: 150 },
  {
    id: 'date',
    label: 'Date',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'changePercentage',
    label: 'Changes%',
    minWidth: 100,
    align: 'left',
    format: (value: string) => displayPercentage({ input: value, isMultiple: false })
  },
  {
    id: 'sharesChange',
    label: 'Shares Changed',
    minWidth: 100,
    align: 'left',
    format: (value: number) => displayNumber({ input: value, showUnit: true })
  },
  {
    id: 'numberOfShares',
    label: 'Shares Held',
    minWidth: 170,
    align: 'left',
    format: (value: number) => displayNumber({ input: value, showUnit: true })
  },
];

export const FundConfigs = [
  { id: 'name', label: 'Fund', minWidth: 150 },
  {
    id: 'type',
    label: 'Type',
    minWidth: 170,
    align: 'left',
  },
  {
    id: 'weight',
    label: 'Weight (%)',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'marketValue',
    label: 'Market Value ($)',
    minWidth: 100,
    align: 'left',
    format: (value: number) => displayNumber({ input: value, showUnit: true })
  },
  {
    id: 'numberOfShares',
    label: 'Shares Held',
    minWidth: 170,
    align: 'left',
    format: (value: number) => displayNumber({ input: value, showUnit: true })
  }
];

export const InsiderTransactionConfigs = [
  { id: 'name', label: 'Investor', minWidth: 150 },
  {
    id: 'type',
    label: 'Type',
    minWidth: 170,
    align: 'left',
  },
  {
    id: 'date',
    label: 'Date',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'sharesChange',
    label: 'Shares Change',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'price',
    label: 'Price',
    minWidth: 170,
    align: 'left',
  }
];