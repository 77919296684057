import { useMutation, UseMutationResult } from 'react-query';
import { Auth } from 'aws-amplify';

type ForgotPasswordInput = {
  username: string; 
}

type Error = {
  message: string;
  __type: string;
}

// Send verification code to verified username (email | sms)
function forgotPassword(username: string): Promise<any> {
  return Auth.forgotPassword(username);
}

function useForgotPassword(): UseMutationResult<unknown, Error, ForgotPasswordInput, unknown> {
  return useMutation<unknown, Error, ForgotPasswordInput, unknown>(
    ({ username }) => forgotPassword(username));
}

export default useForgotPassword;