import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Line, LabelList } from "recharts";
import { useTheme } from '@mui/material/styles';

import {
  Grid,
  Box,
  ChipPrimary,
  PeriodChip
} from 'src/components/mui';
import { LineChart, CustomizedLabel } from "src/components/charts";
import TitleWithCurrency from "src/components/TitleWithCurrency";
import { useEstimatesUi } from "src/hooks";
import * as queries from 'src/graphql/queries';
import { Period } from 'src/enums';

type DataPoint = {
  title: string
  high: number | null
  avg: number | null
  low: number | null
}

function HistoricalEstimate({
  symbol
} : {
  symbol: string | undefined
}) {
  const symbols = symbol ? [symbol] : [];

  const theme = useTheme();

  const [period, setPeriod] = useState<Period>(Period.Quarterly);
  const [selectedGroupName, setSelectedGroupName] = useState<string>('');

  const { data } = useEstimatesUi(period, symbols, queries.getEstimatesHistoricalUi);
  const historicalEstimatesUi = data?.data.getEstimatesHistoricalUi;

  const estimatesOfSymbol = historicalEstimatesUi?.find((estimates) => symbol === estimates.symbol);

  const groupNames = estimatesOfSymbol?.historicalEstimates.map(({ groupName }) => groupName);
  const groupNamesStr = `${groupNames}`;
  
  const selectedGroupData = estimatesOfSymbol?.historicalEstimates.find((groupData) => groupData.groupName === selectedGroupName)?.groupData;

  const lineChartData = estimatesOfSymbol?.titleList.reduce((result, title, index) => {
    const highValue = selectedGroupData?.find((dataPoint) => dataPoint.dataKey === `${selectedGroupName} High`)?.dataList[index];
    const avgValue = selectedGroupData?.find((dataPoint) => dataPoint.dataKey === `${selectedGroupName} Avg`)?.dataList[index];
    const lowValue = selectedGroupData?.find((dataPoint) => dataPoint.dataKey === `${selectedGroupName} Low`)?.dataList[index];

    const dataPoint = {
      title,
      high: highValue ? +highValue : null,
      avg: avgValue ? +avgValue : null,
      low: lowValue ? +lowValue : null,
    }

    result.push(dataPoint);

    return result;
  }, [] as DataPoint[]);

  const noData = lineChartData && lineChartData.length === 0;

  useEffect(() => {
    if(groupNames && groupNames.length > 0) {
      setSelectedGroupName(groupNames[0]);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupNamesStr]);

  function handleChangePeriod(period: Period) {
    return function() {
      setPeriod(period);
    }
  }

  function handleGroupNameChange(groupName: string) {
    return function() {
      setSelectedGroupName(groupName);
    }
  }

  return (      
    <Box 
      width="100%" 
      mt={1}
      mb={2}
      p={2}
      sx={{ 
          background: theme.palette.background.default,
          borderRadius: `${theme.shape.borderRadius}px`,
      }}
    >
      <TitleWithCurrency title="Historical Consensus Estimates" currency="USD" />
      <Grid container display="flex" alignItems='baseline'>
        <Grid display="flex" item xs={12} sm={9}  mb={1}>
          {
            groupNames?.map((groupName) => (
              <ChipPrimary 
                key={groupName}
                label={groupName} 
                textTransform="uppercase"
                onClick={handleGroupNameChange(groupName)}
                clickable
                color={ groupName === selectedGroupName ? 'primary' : undefined }
                sx={{ mr: 1 }}
              />
            ))
          }
        </Grid>
        <Grid item xs={12} sm={3} mb={1} display="flex" justifyContent="flex-end">
          <PeriodChip 
            label={Period.Quarterly}
            isSelected={period === Period.Quarterly}
            sx={{ mr: 0.5, alignSelf: "right" }}
            clickable
            onClick={handleChangePeriod(Period.Quarterly)}
          />
          <PeriodChip 
            label={Period.Annual}
            isSelected={period === Period.Annual}
            clickable
            sx={{ alignSelf: "right" }}
            onClick={handleChangePeriod(Period.Annual)}
          />
        </Grid>
      </Grid>
      {
        noData ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="body1" color="textSecondary" fontWeight={600} >No Data</Typography>
          </Box>
        ) : null
      }
      <LineChart data={lineChartData} >
        {
          <>
            <Line type="monotone" isAnimationActive={false} connectNulls dataKey='avg' stroke="#3FD4EA" strokeWidth={2}>
              <LabelList dataKey="avg" content={<CustomizedLabel />} />
            </Line>
          </>
        }
      </LineChart>
    </Box>
  )
}

export default HistoricalEstimate;