import { useState, useRef, useEffect } from 'react';

import {
  Box,
  Button
} from 'src/components/mui';
import { StyledParagraph } from './styled';

function ExpandableText({
  text,
  height = 100
} : {
  text: string | undefined,
  height?: number
}) {

  const [showToggle, setShowToggle] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(true);
  const paragraphRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if(paragraphRef.current) {
      if(!isShowAll(height, paragraphRef.current.clientHeight)) {
        setOpen(false);
        setShowToggle(true);

        return;
      }
    }
  }, [height, text]);

  function handleToggleText() {
    if(!paragraphRef.current) {
      return;
    }

    setOpen(!open);
  }

  return (
    text ? <>
      <Box 
        ref={paragraphRef} 
        sx={{ 
          display: "-webkit-box", 
          WebkitBoxOrient: "vertical", 
          overflow: open ? 'visible' : 'hidden', 
          WebkitLineClamp: open ? 'inherit' : 5,
        }}
      >
        <StyledParagraph variant="body1" color="textSecondary" align="left">{ text }</StyledParagraph>
      </Box>
      {
        showToggle ? (
          <Box display="flex" justifyContent="flex-end">
            <Button variant="text" onClick={handleToggleText}>
              {
                open ? 'Show less' : 'Read more'
              }
            </Button>
          </Box>
        ) : null
      }
    </> : null
  )
}

function isShowAll(height: number, clientHeight: number) {
  return Math.abs(height - clientHeight) < 30;
}

export default ExpandableText;