import React from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

type CustomIconButtonProps = IconButtonProps<"button", {
  variant?: 'contained' | 'outlined' | 'text'
}>

const StyledIconButton = styled(
  // Deconstruct to avoid the DOM unknown props warning
  ({ variant, ...otherProps }: CustomIconButtonProps) => (<IconButton {...otherProps} size="large" />)
)(({ theme, variant }) => ({
  '&&': {
    background: variant === 'contained' ? theme.palette.primary.main : 'none',
    border: variant === 'text' ? 'none' : `2px solid ${theme.palette?.primary.main}`,
  },

  '&:hover': {
    background: variant === 'contained' ? theme.palette.primary.dark : 'none',
    border: variant === 'text' ? 'none' : `2px solid ${theme.palette?.primary.dark}`,

    '& .MuiSvgIcon-root': {
      color: variant === 'contained' ? 'white' : theme.palette.primary.dark,
    }
  },

  '& .MuiSvgIcon-root': {
    color: variant === 'contained' ? 'white' : theme.palette.primary.main
  },
}));

const MuiIconButton: React.FC<CustomIconButtonProps> = (props) => {

  return (
    <StyledIconButton {...props} />
  );
}

export default MuiIconButton;