import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';

import { SymbolInsight } from 'src/types';
import { InsightQueries } from 'src/enums';

export type GetInsightsResponse = {
  data: {
    [key in keyof typeof InsightQueries]: SymbolInsight[]
  }
}

async function getInsights(symbols: string[] | undefined, query: string): Promise<GetInsightsResponse> {
  return (API.graphql(graphqlOperation(query,
      { symbols }
    )) as Promise<GetInsightsResponse>);
}

/* get symbol from Symbol table */
const useInsights = (symbols: string[] | undefined, query: string): UseQueryResult<GetInsightsResponse> => {
  const options: UseQueryOptions<GetInsightsResponse> = { 
    enabled: !!symbols && symbols.length > 0,
  };

  return useQuery(
    [query, symbols], 
    () => getInsights(symbols, query), 
    options
  );
}

export default useInsights;