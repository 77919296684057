import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

import * as mutations from 'src/graphql/mutations';

import { UserSearch } from 'src/types';

function createUserSearchApi(userSearch: Partial<UserSearch>): Promise<UserSearch> {
  
  return (API.graphql(graphqlOperation(mutations.createUserSearch, { input: { ...userSearch, id: uuidv4() }}))) as Promise<UserSearch>;
}

function useUserResearch(): UseMutationResult<UserSearch, unknown, Partial<UserSearch>, unknown> {
  const queryClient = useQueryClient();

  return useMutation<UserSearch, unknown, Partial<UserSearch>, unknown>(
    (userResarch) => createUserSearchApi(userResarch), 
    {
      onSuccess: (data, variables) => {
        const { userID } = variables;
        queryClient.invalidateQueries(['user', userID]);
      }
    }
  );
}

export default useUserResearch;

