import { useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from 'src/components/mui';
import Alert from 'src/parts/Notification/Alert';

import { useNotification } from 'src/contexts';
import { ActionType } from 'src/parts/Notification/types.d';

function Notification() {

  const { state: { open, messageInfo, queue, origin }, dispatch: dispatchNotification } = useNotification();

  // Only show one notification at a time, regardless of position of notificaiton in the page
  useEffect(() => {
    if (queue.length && !messageInfo) {
      // Set a new notification when we don't have an active one
      dispatchNotification({ type: ActionType.UpdateMessageInfo, data: { message: { ...queue[0] } } });
      dispatchNotification({ type: ActionType.DeleteHead });
      dispatchNotification({ type: ActionType.SetOpen, data: { open: true }});
    } else if (queue.length && messageInfo && open) {
      // Close an active notification when a new one is added
      dispatchNotification({ type: ActionType.SetOpen, data: { open: false }});
    }
  }, [queue, messageInfo, open, dispatchNotification]);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatchNotification({ type: ActionType.SetOpen, data: { open: false }});
  };

  const handleExited = () => {
    dispatchNotification({ type: ActionType.UpdateMessageInfo, data: { message: undefined } });
  };

  return (
    <Snackbar
      anchorOrigin={origin}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          sx={{ p: 0.5 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      }
    >
      <Alert onClose={handleClose} severity={messageInfo?.severity} sx={{ width: '100%' }}>
          {messageInfo?.message}
      </Alert>  
    </Snackbar>
  );
}

export default Notification;