import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';

import * as queries from 'src/graphql/queries';
import { FinancialUIResponse } from 'src/types';
import { Period } from 'src/enums';

type GetBalanceSheetUiResponse = {
  data: {
    getBalanceSheetUi: FinancialUIResponse[]
  }
}

async function getBalanceSheetUi(period: Period, symbols: string[]): Promise<FinancialUIResponse[]> {
  const { data: { getBalanceSheetUi }} = 
    await (API.graphql(graphqlOperation(
      queries.getBalanceSheetUi, 
      { period, symbols }
    )) as Promise<GetBalanceSheetUiResponse>);

  return getBalanceSheetUi;
}

function useBalanceSheetUi(period: Period, symbols: string[]): UseQueryResult<FinancialUIResponse[]> {
  const options: UseQueryOptions<FinancialUIResponse[]> = { 
    enabled: !!period && symbols.length > 0,
  };

  return useQuery(
    ['balanceSheetUi', period, symbols],
    () => getBalanceSheetUi(period, symbols), 
    options
  );
}

export default useBalanceSheetUi;