import { styled } from '@mui/material/styles';
import { keyframes } from "@mui/styled-engine";

const scroll = keyframes`
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 4))
  }
`;

export const StyledSlider = styled('div')(() => ({
  overflow: 'hidden',
  position: 'relative',
  '--animps': 'paused',

  '&::after, &::before': {
    position: 'absolute'
  },
  '&::after': {
    right: 0,
    top: 0,
    transform: 'rotateZ(180deg)'
  },
  '&::before': {
    left: 0,
    right: 0
  },

  '.slide-track': {
    animation: `${scroll} 20s linear infinite`,
		display: 'flex',
		width: 'calc(250px * 8)',

    '&:hover': {
      'animation-play-state': 'paused'
    },
  },
}))
