import { Area, Bar, LabelList, Tooltip } from "recharts";
import Typography from '@mui/material/Typography';

import { SidePanelChart } from "src/types";
import { AreaChartRc, BarChart, CustomizedLabel, CustomTooltip } from "src/components/charts";
import { displayString } from "src/utils/stringUtils";
import { displayNumber } from "src/utils/numberUtils";

function ChartContainer({
  chartType,
  visualization,
} : {
  chartType: SidePanelChart | undefined,
  visualization: string | undefined
}) {

  if(!visualization) {
    return null;
  }

  const visualizationJson = JSON.parse(visualization);

  const [xKey, dataKey] = Object.keys(visualizationJson[0]);

  if(chartType === 'Line') {
    const showLabel = visualizationJson.length < 6;

    return (
      <AreaChartRc 
        height={150} 
        data={visualizationJson} 
        axisStroke="#BDBDBD" 
        xKey={xKey}
        gradient={[{ id: 'colorArea', startColor: 'rgba(0, 24, 242, 0.3)', stopColor: 'rgba(218, 219, 255, 0)' }]}
      >
        {
          <Tooltip content={<CustomTooltip render={renderAreaChartTooltip(dataKey)} />} />
        }
        <Area
          type="linear"
          dataKey={dataKey}
          stroke="#3E60FF"
          strokeWidth={2}
          fillOpacity={1}
          fill="url(#colorArea)"
          label={showLabel && <CustomizedLabel textColor="white" />}
        />
      </AreaChartRc>
    )
  }

  if(chartType === 'Bar') {
    return (
      <BarChart data={visualizationJson} height={150}>
        {
          Object.keys(visualizationJson[0]).map((key) => {
            if(key !== 'name') {
              return (
                <Bar key={key} isAnimationActive={false} dataKey={key} fill="#DADBFF" barSize={10}>
                  <LabelList dataKey={key} content={<CustomizedLabel textColor="white" />} />
                </Bar>
              );
            }

            return null;
          })
        }
      </BarChart>
    )
  }

  return null;
}

function renderAreaChartTooltip(datakey: string) {

  return function(payloads: any) {
    const payload = payloads[0].payload;

    return isNaN(payload[datakey]) ? (
        <Typography>{displayString(payload[datakey])}</Typography>
    ) : (
      <Typography>{`${datakey}: ${displayNumber({ input: payload[datakey], showUnit: true })}`}</Typography>
    )
  }
}

export default ChartContainer;