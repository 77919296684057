import { styled } from '@mui/material/styles';
import {
  Container,
} from 'src/components/mui';

const StyledPageContainer = styled(Container)((props) => ({
  background: props.theme.palette.background.default,
  [props.theme.breakpoints.down('md')]: {
    padding: `${props.theme.app.fixedTop.mdDown}px 0 0 0`,
  },

  [props.theme.breakpoints.up("md")]: {
    paddingTop: `${props.theme.app.fixedTop.mdUp}px`,
  },
}));

export {
  StyledPageContainer,
}