import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';

import * as queries from 'src/graphql/queries';
import { ValuationUIResponse } from 'src/types';

export type GetValuationUiResponse = {
  data: {
    getValuationUi: ValuationUIResponse
  }
}

async function getValuationUi(symbol: string | undefined): Promise<ValuationUIResponse> {
  const { data: { getValuationUi }} = await (API.graphql(graphqlOperation(
      queries.getValuationUi, 
      { symbol }
    )) as Promise<GetValuationUiResponse>);

  return getValuationUi;
}

function useValuationUi(symbol: string | undefined): UseQueryResult<ValuationUIResponse> {
  const options: UseQueryOptions<ValuationUIResponse> = { 
    enabled: !!symbol,
  };

  return useQuery(
    ['getValuationUi', symbol],
    () => getValuationUi(symbol), 
    options
  );
}

export default useValuationUi;
