import { useMutation, UseMutationResult } from 'react-query';
import { Auth } from 'aws-amplify';

type ConfirmSignUpInput = {
  username: string; 
  code: string
}

type Error = {
  message: string;
  __type: string;
}

function confirmSignUp(username: string, code: string): Promise<any> {
  return Auth.confirmSignUp(
    username, 
    code
  );
}

/* get the cognito authenticated user */
function useConfirmSignUp(): UseMutationResult<unknown, Error, ConfirmSignUpInput, unknown> {
  return useMutation<unknown, Error, ConfirmSignUpInput, unknown>(
    ({ username, code }) => confirmSignUp(username, code));
}

export default useConfirmSignUp;