import { styled } from '@mui/material';
import Dialog from "@mui/material/Dialog";


const StyledDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    backgroundColor: 'white'
  }
});

export {
  StyledDialog
}