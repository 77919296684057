import { useMutation, UseMutationResult } from 'react-query';
import { Auth } from 'aws-amplify';

type VerifyAttr = 'email' | 'phone_number';

type Error = {
  message: string;
  __type: string;
}

function verifyCurrentUserAttribute(attr: VerifyAttr): Promise<void> {
  return Auth.verifyCurrentUserAttribute(attr);
}

function useVerifyCurrentUserAttribute(): UseMutationResult<void, Error, VerifyAttr, unknown> {
  return useMutation<void, Error, VerifyAttr, unknown>(
    (attr) => verifyCurrentUserAttribute(attr)
  );
}

export default useVerifyCurrentUserAttribute;