import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

import * as mutations from 'src/graphql/mutations';

import { UserFollow } from 'src/types';

function createUserSearchApi(userSearch: Partial<UserFollow>): Promise<UserFollow> {
  
  return (API.graphql(graphqlOperation(mutations.createUserFollow, { input: { ...userSearch, id: uuidv4() }}))) as Promise<UserFollow>;
}

function deleteUserFollowApi(id: string): Promise<UserFollow> {
  return (API.graphql(graphqlOperation(mutations.deleteUserFollow, { input: { id }}))) as Promise<UserFollow>;
}

function useUserFollow(userID: string | undefined): {
  follow: UseMutationResult<UserFollow, unknown, Partial<UserFollow>, unknown>,
  unfollow: UseMutationResult<UserFollow, unknown, string, unknown>,
} {
  const queryClient = useQueryClient();

  return {
    follow: useMutation<UserFollow, unknown, Partial<UserFollow>, unknown>(
      (userResarch) => createUserSearchApi(userResarch), {
        onSuccess: () => {
          queryClient.invalidateQueries(['user', userID]);
        }
      }
    ),
    unfollow: useMutation<UserFollow, unknown, string, unknown>(
      (id) => deleteUserFollowApi(id), {
        onSuccess: () => {
          queryClient.invalidateQueries(['user', userID]);
        }
      }
    ),
  }
}

export default useUserFollow;

