import TableRow from '@mui/material/TableRow';
import TableCell, { TableCellProps } from '@mui/material/TableCell';

import { styled } from '@mui/material/styles';

type CustomStyledTableCellProps = TableCellProps;

const StyledTableRow = styled(TableRow)((props) => ({
  "&:hover": {
    cursor: "pointer",
  },
  "&:nth-of-type(odd)": {
    backgroundColor: props.theme.palette.background.default
  }
}));

const StyledTableCell = styled(TableCell)((props: CustomStyledTableCellProps) => ({
  border: 'none',
  color: props.variant === 'head' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.5)',
  padding: '0.5rem'
}));

export {
  StyledTableRow,
  StyledTableCell
}