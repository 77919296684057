/* Keep render function used in multiple components */
import Typography from "@mui/material/Typography";

export function renderTags(tags: string) {

  const tagsStr = tags.substr(1).slice(0, -1).trim().replace(/\s*,\s*|\s+,/g, ' / ');

  return (
    <Typography variant="caption" color="textSecondary" align="center">{ tagsStr }</Typography>
  )
}