import { useTheme } from '@mui/material/styles'
import Typography from "@mui/material/Typography";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';

import { Box } from "src/components/mui";

import { useLearning } from 'src/contexts';
import { InsightProps } from 'src/components/insight/types';
import { InsightImportance, LearningType } from 'src/enums';

const Insight = ({
  id,
  isRisk = false,
  value,
  importance,
  sidePanel,
  disableBorder = false,
  dark = false,
}: InsightProps) => {

  const theme = useTheme();
  const { state, dispatch } = useLearning();

  const insightColor = isRisk ? theme.palette.error.main : theme.palette.success.main;

  const insightBackground = isRisk 
    ? importance === InsightImportance.High 
      ? theme.app.insight.color.risk.important 
      : importance === InsightImportance.Medium 
      ? theme.app.insight.color.risk.medium 
      : theme.app.insight.color.risk.low
    : importance === InsightImportance.High 
      ? theme.app.insight.color.highlight.important 
      : importance === InsightImportance.Medium 
      ? theme.app.insight.color.highlight.medium 
      : theme.app.insight.color.highlight.low;

  const isSelected = state.type === LearningType.Insight ? state.data?.id === id : false;

  function handleClick() {
    if(isSelected) {
      dispatch({
        type: LearningType.None
      });

      return;
    }

    dispatch({
      type: LearningType.Insight,
      data: {
        id,
        isRisk,
        value,
        importance,
        sidePanel
      }
    });
  }

  return (
    <Box 
      sx={{
        display: "flex",
        position: "relative",
        alignItems: "center",
        pr: 1, 
        pl: 1, 
        pt: 0.5, 
        pb: 0.5, 
        mb: 0.5, 
        mr: 0.5, 
        flexWrap: "nowrap", 
        borderRadius: 1,
        background: insightBackground,
        cursor: "pointer",
        border: !disableBorder && isSelected ? `2px solid ${theme.palette.primary.light}` : 'none',
      }}
      onClick={handleClick}
    >
      {
        importance === InsightImportance.Low ? (
          <CircleRoundedIcon style={{ color: insightColor, fontSize: '8px' }} />
        ) : isRisk ? (
          <CloseIcon fontSize="small" style={{ color: insightColor }} />
        ) : (
          <CheckIcon fontSize="small" style={{ color: insightColor }} />
        )
      }
      <Box ml={0.5}>
        <Typography variant="body2" color={dark ? "white" : "textPrimary"}>{ value }</Typography>
      </Box>
    </Box>
  );
}

export default Insight;