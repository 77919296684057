import { useMutation, UseMutationResult } from 'react-query';
import { Auth } from 'aws-amplify';
import { ISignUpResult } from 'amazon-cognito-identity-js';

type SignUpInput = {
  email: string; 
  password: string
}

type Error = {
  message: string;
  __type: string;
}

function signUp(email: string, password: string): Promise<ISignUpResult> {
  return Auth.signUp(email, password);
}

/* get the cognito authenticated user */
function useSignUp(): UseMutationResult<ISignUpResult, Error, SignUpInput, unknown> {

  return useMutation<ISignUpResult, Error, SignUpInput, unknown>(
    ({ email, password }) => signUp(email, password));
}

export default useSignUp;