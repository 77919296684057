import { Period, InsightImportance, EstimatesUiKeys, GroupDataCurrency, GroupDataType, RatingTypeEnum, StockIndexEnum } from 'src/enums';

declare module "react-katex" {}
export type StockMarket = 'US';

export interface SearchUi {
	exchange: string
	name: string
	search: string
	symbolId: string
	symbolName: string
}

export interface RatingType extends Record<RatingTypeEnum, string> {
	symbol: string
}
export interface Rating {
	id: string
	actionCompany: string
	actionPt: string
	analyst: string
	analystName: string
	currency: string
	datetime: string
	exchange: string
	importance: string
	name: string
	notes: string
	provider: string
	providerDate: string
	providerId: string
	providerTime: string
	providerUpdated: string
	ptCurrent: string
	ptPrior: string
	ratingCurrent: string
	ratingPrior: string
	symbol: string
	url: string
	urlCalendar: string
	urlNews: string
}

// amplify schema type
export type ModelStringKeyConditionInput = {
	eq: string
	le: string
	lt: string
	ge: string
	gt: string
	between: [string]
	beginsWith: string
}

export interface PressRelease {
	content: string
	datetime: string
	exchange: string
	id: string
	provider: string
	symbol: string
	title: string
}
export interface FmpNews {
	symbol: string
	publishedDate: string
	title: string
	image: string
	site: string
	text: string
	url: string
}
export interface FmpTicker {
	changes: number
	changesPercentage: string
	companyName: string
	price: string
	ticker: string
}

export interface ValuationUIResponse {
	symbol: string
	valuationAssumptions: {
		valuationAssumptions: GroupData[]
	},
	valuationBrief: {
		valuationBrief: GroupData[]
	},
	valuationFreeCashFlow: {
		titleList: string[],
		valuationFreeCashFlow: GroupData[]
	},
	valuationMetrics: {
		valuationMetrics: GroupData[]
	},
	valuationProjections: {
		titleList: string[],
		valuationProjections: GroupData[]
	},
	valuationSharePrice: {
		currency: GroupDataCurrency,
		valuationSharePrice: string
	}
}

export interface EstimatesUIResponse extends EstimatesUI {
	period: Period
	symbol: string
	titleList: string[]
}

/* TODO: Refactor to a generic type */
export type EstimatesUI = {
	[key in EstimatesUiKeys]: GroupData[]
}

export type News = {
	datetime: string
	hasPaywall: string
	headline: string
	image: string | null
	id: string
	lang: string
	related: string | null
	relatedSymbols: string[] | null
	summary: string
	source: string
	symbol: string
	url: string
}

export const ranges = ['6m', 'ytd', '1y', '5y'] as const;
export const minRanges = [] as const;
export const dayRanges = ['6m', 'ytd', '1y', '5y'] as const;

export type MinChartRange = typeof minRanges[number];
export type DayChartRange = typeof dayRanges[number];
export type ChartRange = MinChartRange | DayChartRange;

export type MarketIndex = {
	name: StockIndexEnum
	label: string
	symbol: string
	market: StockMarket
	etf: string
}
export type SymbolChart<T> = {
	symbol: string
	data: T[]
}
export type IndexChartOverview = {
	change: string
	changeOverTime: string
	changePercent: string
	close: string
	date: string
	high: string
	isDaily: string
	label: string
	low: string
	minute: string
	open: string
	provider: string
	symbol: string
	unadjustedVolume: string
	volume: string
	vwap: string
}
export interface ChartOverviewUi {
	average: string
	changeOverTime: string
	close: string
	date: string
	high: string
	label: string
	low: string
	notional: string | ""
	numberOfTrades: string
	open: string
	symbol: string
	volume: string | null
}
export interface ChartOverviewUiMinute extends ChartOverviewUi {
	minute: string | ""
}
export interface FinancialUIResponse extends FinancialUI {
	period: Period
	symbol: string
	titleList: string[]
}
export type FinancialUI = {
	[key in FinancialUIKey]: GroupData[]
}
export type FinancialUIKey = 'incomeStatement' | 'balanceSheet' | 'cashFlow'

export type GroupData = {
	groupData: DataPoint[]
	groupName: string
	currency?: GroupDataCurrency
}
type DataPoint = {
	isHighlighted: 'False' | 'True'
	dataList: string[]
	dataKey: string
	dataType?: GroupDataType
}

export type SidePanelChart = 'Bar' | 'Line';

type Concept = {
	conceptName: string
	definition: string
	equation: string[]
	equationLaTeX: string[]
	importance?: string
}
export type SidePanel = {
	chartType: SidePanelChart,
	equation: string;
	version: string;
	explanation: string;
	whyItMatters: string;
	definition: string;
	visualization?: any;
	concept?: Concept[]
}

export type InsightPoint = {
	type: 'Risk' | 'Highlight'
	importance: InsightImportance
	value: string
	id: string
	sidePanel?: SidePanel
}
export type SymbolInsight = {
	symbol: string
	insights: InsightPoint[]
}

export type TRealTimeQuoteFull = {
	avgTotalVolume: string
	calculationPrice: string
	change: string
	changePercent: string
	close: string
	closeSource: string
	closeTime: string
	companyName: string
	currency: string
	delayedPrice: string
	delayedPriceTime: string
	extendedChange: string
	extendedChangePercent: string
	extendedPrice: string
	extendedPriceTime: string
	high: string
	highSource: string
	highTime: string
	iexAskPrice: string
	iexAskSize: string
	iexBidPrice: string
	iexBidSize: string
	iexClose: string
	iexCloseTime: string
	iexLastUpdated: string
	iexMarketPercent: string
	iexOpen: string
	iexOpenTime: string
	iexRealtimePrice: string
	iexRealtimeSize: string
	iexVolume: string
	lastTradeTime: string
	latestPrice: string
	latestSource: string
	latestTime: string
	latestUpdate: string
	latestVolume: string
	low: string
	lowSource: string
	lowTime: string
	marketCap: string
	oddLotDelayedPrice: string
	oddLotDelayedPriceTime: string
	open: string
	openTime: string
	openSource: string
	peRatio: string
	previousClose: string
	previousVolume: string
	primaryExchange: string
	symbol: string
	volume: string
	week52High: string
	week52Low: string
	ytdChange: string
	isUSMarketOpen: string
}

export type UserSearch = {
	id: string
	userID: string
	symbolID: string
	user: User
	symbol: Symbol
}
export type UserFollow = {
	id: string
	userID: string
	symbolID: string
	user: User
	symbol: Symbol,
	createdAt: string,
}
type ModelUserSearchConnection = {
	items: UserSearch[]
	nextToken: string
}
export type ModelUserFollowConnection = {
	items: UserFollow[]
	nextToken: string
}

export type User = {
  id: string
  email: string
  emailVerified: string
  phoneNumber: string
  phoneNumberVerified: string
  recentSearches: ModelUserSearchConnection
  follows: ModelUserFollowConnection
}

export type CognitoUser = {
  attributes: {
    email: string
    email_verified: boolean
    phone_number: string
    phone_number_verified: boolean
    sub: string
  }
  username: string
}

type CognitoUserAttribute = {
  email?: string;
	email_verified?: boolean;
  phone_number?: string;
	phone_number_verified?: boolean
  sub?: string;
	nickname?: string;
}

export type Company = {
	id: string
	companyName: string
	exchange: string
	industry: string
	website: string
	description: string
	CEO: string
	securityName: string
	issueType: string
	sector: string
	primarySicCode: string
	tags: string
	address: string
	address2: string
	state: string
	city: string
	zip: string
	country: string
	phone: string
	CFO: string
	CTO: string
	reviewScore: number
	symbolId: string
	symbolName: string
	symbol: Symbol
}

export type Connection<T> = {
	items: T[]
	nextToken?: string
}

export type SearchableSymbolConnection = {
	items: Symbol[]
	nextToken: string
  total: number
}
export type Symbol = {
	id: string
	symbol: string
	exchange: string
	company: Company
	name: string
	date: string
	type: string
	egion: string
	figi: string
	cik: string
	isSandbox: string
}