import { styled } from '@mui/material/styles';
import CardHeader from '@mui/material/CardHeader';

import { Box } from 'src/components/mui';

const StyledFeedHighlightsContainer = styled('div')((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  '& > *': {
    margin: props.theme.spacing(0.5),
  },
  '& > a:first-of-type': {
    marginLeft: 0,
  },
}));

const StyledMeta = styled(Box)((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  color: props.theme.palette.text.disabled
}));

const StyledFeedTitle = styled(CardHeader)((props) => ({
  '&&': {
    padding: 0,
    paddingBottom: props.theme.spacing(0.5)
  },
  '& .MuiCardHeader-title': {
    fontWeight: 600,
    fontSize: '1.2rem',
    color: props.theme.palette.text.secondary
  }
}));

export {
  StyledFeedHighlightsContainer,
  StyledMeta,
  StyledFeedTitle,
}