import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { Box } from 'src/components/mui';
import { StyledStockIndexProps, StyledIndexNameProps } from './types';

const StyledStockIndex = styled(
  ({ name, realtimeQuote, ...otherProps }: StyledStockIndexProps) => (<Box {...otherProps} />)
)((props) => ({
  borderLeft: `3px solid ${props.theme.app.stockIndex.color[props.name]}`,
  padding: `${props.theme.spacing(0.5)} ${props.theme.spacing(1)}`,
  background: props.theme.palette.background.default,
  width: 220,
}));
const StyledName = styled(
  ({ name, ...otherProps }: StyledIndexNameProps) => <Typography {...otherProps} />
)(({ name, theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.app.stockIndex.color[name]
}));

export {
  StyledStockIndex,
  StyledName,
}