import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { Auth } from 'aws-amplify';
import { Link, useLocation } from 'react-router-dom';

import { CognitoUser } from 'src/types';
import { useNotification } from 'src/contexts';
import { ActionType } from 'src/parts/Notification/types.d';
import { Paths } from 'src/routes/paths';

function getCognitoUser(): Promise<CognitoUser> {
  return Auth.currentAuthenticatedUser({ bypassCache: true });
}

/* get the cognito authenticated user */
function useAuthenticatedUser(): UseQueryResult<CognitoUser> {
  const { dispatch } = useNotification();
  const { pathname } = useLocation();

  const options: UseQueryOptions<CognitoUser> = {
    staleTime: 60 * 1000 * 30,
    onSuccess: (user) => {
      const { attributes: { email, email_verified }} = user;

      if(pathname === Paths.SETTINGS) {
        return;
      }

      if(!email_verified) {
        dispatch({
          type: ActionType.PushToQueue,
          data: {
            message: {
              key: new Date().getTime(),
              message: <><span>{`Your email ${email} is not verified yet, please verify at`}</span> <Link to={Paths.SETTINGS}>settings</Link></>,
              severity: 'warning'
            }
          }
        });
      }
    },
    retry: (failureAccount: number, error) => {

      if(error === "The user is not authenticated" || failureAccount === 3) {
        return false;
      }
      
      return true;
    }
  };

  return useQuery(
    ['authenticatedUser'], 
    () => getCognitoUser(),
    options
  );
}

export default useAuthenticatedUser;