import React from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Theme } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';

import { Box } from 'src/components/mui';
import { GroupData } from 'src/types';
import { displayGroupData } from 'src/utils';

function FinancialTable({
  data,
  titleList,
  isLoading,
  isFetched,
  isError,
  groupBackground,
  showGroupName = false,
  loadingComponent
} : {
  data: GroupData[] | undefined;
  titleList?: string[];
  isLoading: boolean;
  isFetched: boolean;
  isError: boolean;
  groupBackground?: string;
  showGroupName?: boolean;
  loadingComponent?: React.ReactNode
}) {

  const theme = useTheme();

  if(isLoading || !isFetched) {
    return loadingComponent 
      ? (
        <> {loadingComponent} </>
      ) 
      : (
        <>
          <Skeleton variant="text" width="100%" sx={{ mb: 1 }} />
          <Skeleton variant="rectangular" height="100px" width="100%" sx={{ mb: 1 }} />
          <Skeleton variant="rectangular" height="100px" width="100%" sx={{ mb: 1 }} />
          <Skeleton variant="rectangular" height="100px" width="100%" sx={{ mb: 1 }} />
          <Skeleton variant="text" width="100%" />
        </>
      );
  }

  if(!data || (titleList && titleList.length === 0) || isError) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant="body1" color="textSecondary" fontWeight={600} >No Data</Typography>
      </Box>
    );
  }

  const headBgColor = groupBackground ? theme.palette.background.default : 'white';

  return (
    <Table>
      {
        titleList && titleList.length > 0 ? (
          <TableHead>
            <TableRow>
              <TableCell
                component='th'
                scope="row"
                sx={{
                  border: 'none',
                  position: "sticky",
                  left: 0,
                  background: headBgColor
                }}
                align="left"
              ></TableCell>
              {
                titleList?.map(title => (
                  <TableCell 
                    key={title}
                    sx={{
                      border: 'none',
                      padding: 1,
                      whiteSpace: 'nowrap'
                    }}
                    align="right"
                  >
                    <Typography variant="body2" color="textSecondary">{ title }</Typography>
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>
        ) : null
      }
      
      {
        data?.map(({ groupData, groupName }, dataIndex) => {
          const isBackground = groupData.length > 1;
          const backgroundColor = isBackground ? groupBackground ?? theme.palette.background.default : 'white';
          const borderColor = groupBackground ? theme.palette.background.default : 'white';

          return (
            <TableBody
              key={groupName}
              sx={{ 
                backgroundColor,
                mb: 1,
                padding: 1,
                pt: isBackground ? 1 : 0,
                borderRadius: `${theme.app.borderRadius.cardSecondary}px`,
                // Border is used for spacing between table bodies
                borderTop: `${theme.spacing(1)} solid ${borderColor}`
              }}
            >
              {
                showGroupName ? (
                  <TableRow key={groupName}>
                    <TableCell 
                      component='th'
                      scope="row"
                      sx={{
                        border: 'none',
                        padding: 1,
                        position: "sticky",
                        left: 0,
                      }}
                      align="left"
                    >
                      <Typography 
                        variant="body1" 
                        color="textSecondary" 
                        fontWeight={600}
                      >
                        { groupName }
                      </Typography>
                    </TableCell>
                    {
                      // Cell placeholders to apply the background color
                      titleList?.map((value, dataIndex) => (
                        <TableCell 
                          key={dataIndex}
                          sx={{
                            border: 'none',
                            padding: 1,
                          }}
                          align="right"
                        >
                        </TableCell>
                      ))
                    }
                  </TableRow>
                ) : null
              }
              {
                groupData.map(({ dataKey, dataList, isHighlighted, dataType }, groupIndex) => {
                  const showCurrency = dataIndex === 0 && groupIndex === 0;

                  return (
                    <TableRow key={`${dataKey}_${groupIndex}`}>
                      <TableCell 
                        key={dataKey}
                        component='th'
                        scope="row"
                        sx={{
                          border: 'none',
                          padding: 1,
                          color: getTextColor(isHighlighted),
                          position: "sticky",
                          left: 0,
                          background: backgroundColor
                        }}
                        align="left"
                      >
                        <Typography 
                          variant="body2" 
                          sx={{ fontWeight: isHighlighted === 'True' ? 600 : 500 }}
                        >
                          { dataKey }
                        </Typography>
                      </TableCell>
                      {
                        dataList.map((value, dataListIndex) => (
                          <TableCell 
                            key={`${dataKey}_${value}_${dataListIndex}`}
                            sx={{
                              border: 'none',
                              padding: 1,
                              color: getTextColor(isHighlighted),
                              whiteSpace: 'nowrap'
                            }}
                            align="right"
                          >
                            <Typography 
                              variant="body2"
                              sx={{ fontWeight: isHighlighted === 'True' ? 600 : 500 }}
                            >
                              {displayGroupData({ value, dataType, showCurrency })}
                            </Typography>
                          </TableCell>
                        ))
                      }
                    </TableRow>
                  )
                })
              }
            </TableBody>
          )
        })
      }
    </Table>
  )
}

function getTextColor(isHighlighted: 'True' | 'False') {
  return function(theme: Theme) {
    return isHighlighted === 'True' ? theme.palette.text.primary : theme.palette.text.secondary
  }
}

export default FinancialTable;