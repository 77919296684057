import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { Auth } from 'aws-amplify';
import { CognitoUser, CognitoUserAttribute } from 'src/types.d';

type UpdateAttributesInput = {
  user: CognitoUser,
  attributes: CognitoUserAttribute
}

type Error = {
  message: string;
  __type: string;
}

function updateUserAttributes(user: CognitoUser, attributes: CognitoUserAttribute): Promise<string> {
  return Auth.updateUserAttributes(user, attributes);
}

function useUpdateUserAttributes(): UseMutationResult<string, Error, UpdateAttributesInput, unknown> {
  const queryClient = useQueryClient();

  return useMutation<string, Error, UpdateAttributesInput, unknown>(
    ({ user, attributes }) => updateUserAttributes(user, attributes),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['authenticatedUser']);
      }
    }
  );
}

export default useUpdateUserAttributes;