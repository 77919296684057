import { useQueryClient, useMutation, UseMutationResult } from 'react-query';
import { Auth } from 'aws-amplify';

import { CognitoUser } from 'src/types';

type SignInInput = {
  email: string; 
  password: string
}

type Error = {
  message: string;
  __type: string;
}

function signIn(email: string, password: string): Promise<CognitoUser> {
  return Auth.signIn(email, password);
}

/* get the cognito authenticated user */
function useSignIn(): UseMutationResult<CognitoUser, Error, SignInInput, unknown> {
  const queryClient = useQueryClient();

  return useMutation<CognitoUser, Error, SignInInput, unknown>(
    ({ email, password }) => signIn(email, password), {
      onSuccess: () => {
        queryClient.invalidateQueries(['authenticatedUser']);
      }
    }
  );
}

export default useSignIn;