import { Bar, LabelList } from 'recharts';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import {
  Box,
  BoxPrimary,
  CardHeaderDefault
} from 'src/components/mui';
import InsightDefinitionContainer from "src/components/insight/InsightDefinitionContainer";
import InsightContainer from "src/components/insight/InsightContainer";
import { BarChart, CustomizedLabel } from "src/components/charts";
import { useInsights } from "src/hooks";
import * as queries from 'src/graphql/queries';
import { GroupData } from "src/types";
import { GroupDataType } from "src/enums";

function Metrics({
  symbol,
  valuationMetrics
} : {
  symbol: string | undefined,
  valuationMetrics: GroupData[] | undefined
}) {

  const symbols = symbol ? [symbol] : [];

  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down('md'));

  const barSize = matchesDownSm ? 10 : 20;

  const { 
    data: insightsData, 
    isFetched: isInsightsFetched, 
    isLoading: isInsightsLoading, 
    isSuccess: isInsightssuccess 
  } = useInsights(symbols, queries.getInsightMatrixValuation);
  const insights = insightsData?.data.getInsightMatrixValuation.find(
    ({ symbol: eachSymbol }) => eachSymbol === symbol
  );

  const barChartData = valuationMetrics?.reduce((result, metrics) => {
    const { groupData, groupName } = metrics;

    let initData = { name: groupName, target: 0, peers: 0, dataType: GroupDataType.Multiple };

    const data = groupData.reduce((barChartDataPoint, dataPoint) => {
      const { dataKey, dataList, dataType } = dataPoint;

      dataKey === 'Target'
        ? barChartDataPoint['target'] = +dataList[0]
        : barChartDataPoint['peers'] = +dataList[0]
      
      barChartDataPoint['dataType'] = dataType || GroupDataType.Multiple;

      return barChartDataPoint;
    }, initData);

    result.push(data);

    return result;
  }, [] as any);

  return (
    <BoxPrimary mb={2}>
      <CardHeaderDefault title="Metrics" />
      <Box mb={2}>
        <Box display="flex" justifyContent="flex-end" mb={1}>
          <InsightDefinitionContainer />
        </Box>
        <InsightContainer 
          insights={insights?.insights}
          isLoading={isInsightsLoading}
          isSuccess={isInsightssuccess}
          isFetched={isInsightsFetched}
        />
        <BarChart data={barChartData}>
          <Bar isAnimationActive={false} dataKey="target" fill="#DADBFF" barSize={barSize}>
            <LabelList dataKey="target" content={<CustomizedLabel />} />
          </Bar>
          <Bar isAnimationActive={false} dataKey="peers" fill="#6E7191" barSize={barSize}>
            <LabelList dataKey="peers" content={<CustomizedLabel />} />
          </Bar>
        </BarChart>
      </Box>
    </BoxPrimary>
  )
}

export default Metrics;