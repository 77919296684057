import { useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from '@mui/material/IconButton';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router';

import InsightDetails from "./InsightDetails";
import { useLearning } from "src/contexts";
import { LearningType } from "src/enums";

/* Insight container when matches downSm (Tablet and Mobile) */
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

function InsightDrawer() {
  const theme = useTheme();
  const location = useLocation();
  const { state, dispatch } = useLearning();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down('md'));

  function closeDrawer() {
    dispatch({
      type: LearningType.None
    });
  }

  useEffect(() => {
    dispatch({
      type: LearningType.None
    });
  }, [location, dispatch]);

  const isOpen = state.type === LearningType.Insight && matchesDownSm;

  return (
    <Drawer
      sx={{
        width: matchesDownSm ? "100%" : theme.app.drawer.insight.width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: matchesDownSm ? "100%" : theme.app.drawer.insight.width,
        },
        display: isOpen ? 'flex' : 'none',
        alignItems: "center",
      }}
      variant={matchesDownSm ? "temporary" : "persistent"}
      anchor={matchesDownSm ? "bottom" : "right"}
      open={isOpen}
    >
      <DrawerHeader sx={{ background: theme.app.background.cardSecondary}}>
        <IconButton onClick={closeDrawer}>
          {matchesDownSm ? <ArrowDownIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <InsightDetails />
    </Drawer>
  )
}

export default InsightDrawer;