import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';

import * as queries from 'src/graphql/queries';

type FundUi = {
  identifier: string
  marketValue: string
  name: string
  numberOfShares: string
  type: string
  weight: string
}
type Response = {
  data: {
    getOwnershipEtfStockUi: FundUi[]
  }
}

async function getOwnershipEtfStockUi(symbol: string | undefined): Promise<FundUi[]> {
  const { data: { getOwnershipEtfStockUi }} = 
    await (API.graphql(graphqlOperation(
      queries.getOwnershipEtfStockUi, 
      { symbol: symbol || '' }
    )) as Promise<Response>);

  return getOwnershipEtfStockUi;
}

function useOwnershipEtfStockUi(symbol: string | undefined): UseQueryResult<FundUi[]> {
  const options: UseQueryOptions<FundUi[]> = { 
    enabled: !!symbol,
    select: (data) => {
      return data.sort((a, b) => {
        return Number(b.numberOfShares) - Number(a.numberOfShares);
      })
    }
  };

  return useQuery(
    ['getOwnershipEtfStockUi', symbol], 
    () => getOwnershipEtfStockUi(symbol), 
    options
  );
}

export default useOwnershipEtfStockUi;