import React, { useReducer } from 'react';
import { LearningType } from 'src/enums';

import { InsightProps } from 'src/components/insight/types';

type Action = { 
  type: LearningType.Insight, 
  data: any 
} | {
  type: LearningType.None
};
type Dispatch = (action: Action) => void;

type State = {
  type: LearningType.Insight,
  data: InsightProps
} | {
  type: LearningType.None,
}

type LearningContextProviderProps = {
  children: React.ReactNode
}

const LearningContext = React.createContext<{ state: State, dispatch: Dispatch } | undefined>(undefined);

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case LearningType.Insight: {
      return {
        type: action.type,
        data: action.data
      };
    }
    case LearningType.None : {
      return {
        type: action.type
      };
    }
    default: {
      throw new Error(`Unhandled learning type`);
    }
  }
}

function LearningContextProvider({
  children
} : LearningContextProviderProps) {
  const [state, dispatch] = useReducer(reducer, { type: LearningType.None });

  const value = { state, dispatch };

  return (
    <LearningContext.Provider value={value}>
      { children }
    </LearningContext.Provider>
  )
}

function useLearning() {
  const value = React.useContext(LearningContext);

  if(value === undefined) {
    throw new Error('useModal must be used within a LearningProvider');
  }

  return value;
}

export {
  LearningContextProvider,
  useLearning
}