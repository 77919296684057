import { User } from 'src/types';

export function getUserFollowId(user: User | undefined, symbolId: string) {
  if(!user) {
    return;
  }

  const userFollow = user.follows.items.find((eachUserFollow) => eachUserFollow.symbol.id === symbolId);

  return userFollow?.id;
}