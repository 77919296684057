import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { styled } from '@mui/material/styles';

const StyledStripRow = styled(TableRow)((props) => ({
  background: 'white',

  "&:hover": {
    cursor: "pointer",
  },
  "&:nth-of-type(odd)": {
    background: props.theme.palette.background.default
  }
}));

const StyledTableCell = styled(TableCell)({
  border: 'none',
  padding: '1rem'
});

export {
  StyledStripRow,
  StyledTableCell
}