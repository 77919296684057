import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from 'src/graphql/queries';
import { SearchUi } from 'src/types';

type ListSymbolsResponse = {
  data: {
    getSymbolSearchUi: SearchUi[]
  }
}

const getSymbolSearchUi = async (symbol: string): Promise<SearchUi[]> => {
  const { data: { getSymbolSearchUi }} = 
    await (API.graphql(graphqlOperation(queries.getSymbolSearchUi, { search: symbol })) as Promise<ListSymbolsResponse>);

  return getSymbolSearchUi;
}

function useSearch(input: string): UseQueryResult<SearchUi[]> {
  const options: UseQueryOptions<SearchUi[]> = { 
    enabled: !!input, 
    refetchOnWindowFocus: false 
  };

  return useQuery(
    ['serach', 'symbols', input], 
    () => getSymbolSearchUi(input), 
    options
  );
}

export default useSearch;
 