import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { capitalize, startCase, toLower } from 'lodash';

import { Box } from 'src/components/mui';
import { displayDate } from 'src/utils/dateUtils';
import { displayString } from 'src/utils/stringUtils';

function PressReleaseDetail(pressReleaseData: any) {
  const theme = useTheme();

  const { data } = pressReleaseData;

  if(!data) return null;

  return (
    <>
      <DialogTitle sx={{ pt: 0, pb: 0 }}>
        <Box display="flex" flexDirection="column">
          <Box mb={1}>
            {startCase(toLower(displayString(data.title)))}
          </Box>
          <Box>
            <Typography variant="body2" color={theme.palette.text.disabled}>{displayDate(data.datetime)}</Typography>  
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {capitalize(data.content)}
        </DialogContentText>
      </DialogContent>
    </>
  );
}

export default PressReleaseDetail;