import { useState, MouseEvent } from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import AlertTitle from '@mui/material/AlertTitle';
import CloseIcon from '@mui/icons-material/Close';

import { useModal } from 'src/contexts';
import { useNavigation } from 'src/hooks';
import { Box, Button } from 'src/components/mui';
import { ModalType } from 'src/enums';

interface State {
  code: string;
}

const MFA = ({
  data,
  hook
}: {
  data: any,
  hook: (data: any) => ({
    handleConfirm: (code: string) => void;
    handleResend: (event: MouseEvent<HTMLButtonElement>) => void;
    handleErrorClose: (event: MouseEvent<HTMLButtonElement>) => void;
    isConfirmLoading: boolean;
    isResendLoading: boolean;
    isError: boolean;
    shouldClose: boolean;
    hint: string;
    errorMsg?: string;
    reloadOnClose?: boolean
  })
}) => {
  const [values, setValues] = useState<State>({
    code: '',
  });

  const { dispatch } = useModal();
  const { navigateToHome } = useNavigation();
  const {
    handleConfirm,
    handleResend,
    handleErrorClose,
    isConfirmLoading,
    isResendLoading,
    isError,
    shouldClose,
    hint,
    errorMsg,
    reloadOnClose,
  } = hook({
    ...data,
    code: values.code
  });

  if(shouldClose) {
    dispatch({
      type: ModalType.None,
    });

    if(reloadOnClose !== false) {
      window.location.reload();
    }

    return null;
  }

  function handleCodeChange(prop: keyof State) {
    return function(event: React.ChangeEvent<HTMLInputElement>) {
      setValues({ ...values, [prop]: event.target.value });
      if(event.target.value.length === 6) {
        handleConfirm(event.target.value);
      }
    }
  }

  function handleConfirmClick() {
    handleConfirm(values.code);
  }

  function handleGoHome() {
    dispatch({ type: ModalType.None });
    navigateToHome();
  }

  return (
    <Box sx={{ p: '20px 24px' }} display="flex" justifyContent="center" alignItems="center">
      <Box
        component="form"
        autoComplete="off"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '50ch',
          padding: 1
        }}
      >
        <Box mb={2}>
          <Typography variant="h5" color="textPrimary" fontWeight={700} onClick={handleGoHome}>Poodle Finance</Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="h6" color="textPrimary" fontWeight={500}>Two-factor authentication</Typography>
        </Box>
        {
          isError ? (
            <Box mb={3} sx={{ width: "100%" }}>
              <Alert 
                icon={false} 
                variant="outlined"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleErrorClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                } 
                sx={{ 
                  width: "100%", 
                  color: (theme) => theme.palette.error.main,
                  borderColor: (theme) => theme.palette.error.main,
                }}
              >
                <AlertTitle>Error</AlertTitle>
                {errorMsg}
              </Alert>
            </Box>
          ) : null
        }
        
        <Box mb={3} sx={{ width: "100%" }}>
          <TextField
            autoFocus
            label="Authentication code"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleCodeChange('code')}
            sx={{ 
              width: "100%",
            }}
          />
        </Box>
        <Button 
          type="submit"
          variant="contained" 
          fullWidth 
          size="large" 
          sx={{ color: 'white' }}
          onClick={handleConfirmClick}
          disabled={isConfirmLoading}
        >
          {
            isConfirmLoading ? "Verifying" : "Verify"
          }
        </Button>
        <Box mt={2} alignSelf="flex-start">
          <Typography variant="body1" color="textPrimary" sx={{ whiteSpace: 'pre-line' }}>
            { hint }
          </Typography>
        </Box>
        <Button 
          variant="text" 
          fullWidth 
          size="large" 
          sx={{
            '&:hover': {
              backgroundColor: 'inherit'
            }
          }}
          onClick={handleResend}
          disabled={isResendLoading}
        >
          {
            isResendLoading ? "Sending the code" : "Resend the code"
          }
        </Button>
      </Box>
    </Box>
  );
}

export default MFA;