import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery, useTheme } from '@mui/material';

import { Box } from "src/components/mui";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import MFA from "./MFA";
import PasswordForget from "./PasswordForget";
import NewPassword from "./NewPassword";
import PressReleaseDetail from './PressReleaseDetail';
import { StyledDialog } from './styled';
import { StyledCloseButton } from 'src/components/mui/Button/styled';
import { useModal } from "src/contexts";
import { useSignUpMfa, useChangePasswordMfa, useUpdateEmailMfa } from 'src/hooks';
import { ModalType } from "src/enums";

function Account() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { state: { type: modalType, data: modalData }, dispatch: dispatchModal } = useModal();

  function handleClose() {
    dispatchModal({ type: ModalType.None });
  }

  return (
    <StyledDialog
      onClose={handleClose}
      open={
        modalType === ModalType.SignIn 
        || modalType === ModalType.SignUp 
        || modalType === ModalType.SignUpMFA
        || modalType === ModalType.ChangePasswordMFA
        || modalType === ModalType.UpdateEmailMFA
        || modalType === ModalType.ForgotPassword
        || modalType === ModalType.ForgotPasswordSubmit
        || modalType === ModalType.PressRelease
      }
      scroll={modalType === ModalType.PressRelease ? 'body' : undefined}
      fullScreen={fullScreen}
    >
      <Box mb={1} sx={{ textAlign: "right" }}>
        <StyledCloseButton
          aria-label="close"
          onClick={handleClose}
          variant="text"
        >
          <CloseIcon onClick={handleClose}/>
        </StyledCloseButton>
      </Box>
      {
        modalType === ModalType.SignIn ? (
          <SignIn />
        ) : modalType === ModalType.SignUp ? (
          <SignUp />
        ) : modalType === ModalType.SignUpMFA ? (
          <MFA data={modalData} hook={useSignUpMfa} />
        ) : modalType === ModalType.ChangePasswordMFA ? (
          <MFA data={modalData} hook={useChangePasswordMfa} />
        ) : modalType === ModalType.UpdateEmailMFA ? (
          <MFA data={modalData} hook={useUpdateEmailMfa} />
        ) : modalType === ModalType.ForgotPassword ? (
          <PasswordForget />
        ) : modalType === ModalType.ForgotPasswordSubmit ? (
          <NewPassword data={modalData} />
        ) : modalType === ModalType.PressRelease ? (
          <PressReleaseDetail data={modalData} />
        ) : null
      }
    </StyledDialog>
  )
}

export default Account;