import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { Box } from 'src/components/mui';
import {
  StyledStockIndex,
  StyledName,
} from './styled';
import { displayNumber, displayPercentage } from 'src/utils/numberUtils';
import { displayString } from 'src/utils/stringUtils';
import { getChangeColor } from 'src/utils';

import {
  StockIndexProps
} from './types';

function StockIndex(props: StockIndexProps) {
  const { name, realtimeQuote } = props;

  const theme = useTheme();

  const styledStockIndexProps = {
    ...props,
  }

  const changeColor = getChangeColor({ isCompare: false, isBg: true,  isBgMain: true , change: realtimeQuote?.change, isMarketOpen: true, theme });

  return (
    <Box p={1} sx={{ background: theme.palette.background.default, borderRadius: '5px' }} >
      <StyledStockIndex 
        {...styledStockIndexProps}
      >
          <Box display="flex" alignItems="flex-end">
            <StyledName variant="body1" mr={1} name={name}>{realtimeQuote.symbol}</StyledName>
            <Typography variant="subtitle2" color="textSecondary">
              { name }
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box mr={1} sx={{ whiteSpace: 'nowrap' }}>
              <Typography variant="h6" color="textSecondary">
                { `${displayNumber({ input: realtimeQuote?.latestPrice })} ${displayString(realtimeQuote?.currency)}` }
              </Typography>
            </Box>
            <Typography variant="subtitle2">
              <Box color='white' sx={{ background: changeColor, p: 0.5, borderRadius: '4px', whiteSpace: 'nowrap' }}>
                {`${Number(realtimeQuote?.change) > 0 ? '+' : ''}${displayNumber({ input: realtimeQuote?.change })} (${displayPercentage({ input: realtimeQuote?.changePercent })})` }
              </Box>
            </Typography>
          </Box>
      </StyledStockIndex>
    </Box>
  );
}

export default StockIndex;