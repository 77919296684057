import { useState } from 'react';

import {
  Box,
  BoxPrimary,
  PeriodChip,
} from 'src/components/mui';
import { FinancialTable } from 'src/components/tables';
import InsightDefinitionContainer from "src/components/insight/InsightDefinitionContainer";
import InsightContainer from "src/components/insight/InsightContainer";
import TitleWithCurrency from 'src/components/TitleWithCurrency';
import { useIncomeStatementUi, useInsights } from 'src/hooks';
import * as queries from 'src/graphql/queries';
import { Period } from 'src/enums';

function IncomeStatement({
  symbols
} : {
  symbols: string[]
}) {

  const [period, setPeriod] = useState<Period>(Period.Quarterly);

  const { 
    data: insightsData, 
    isFetched: isInsightsFetched, 
    isLoading: isInsightsLoading, 
    isSuccess: isInsightssuccess 
  } = useInsights(symbols, queries.getInsightFinancialIncomeStatement);
  const insights = insightsData?.data.getInsightFinancialIncomeStatement.find(
    ({ symbol: eachSymbol }) => eachSymbol === symbols[0]
  );

  const { 
    data: incomeStatementData, 
    isLoading: isIncomeLoading, 
    isFetched: isIncomeFetched,
    isError: isIncomeError
  } = useIncomeStatementUi(period, symbols);

  const incomeStatement = incomeStatementData?.find((is) => is.symbol === symbols[0]);

  function handleChangePeriod(period: Period) {
    return function() {
      setPeriod(period);
    }
  }

  return (
    <BoxPrimary sx={{ mb: 2 }}>
      <TitleWithCurrency title="Income Statement" currency="USD" />
      <Box mb={2}>
        <Box display="flex" justifyContent="flex-end" mb={1}>
          <InsightDefinitionContainer />
        </Box>
        <InsightContainer 
          insights={insights?.insights}
          isLoading={isInsightsLoading}
          isSuccess={isInsightssuccess}
          isFetched={isInsightsFetched}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" mb={1} >
        <PeriodChip 
          label={Period.Quarterly}
          isSelected={period === Period.Quarterly}
          sx={{ mr: 0.5 }}
          onClick={handleChangePeriod(Period.Quarterly)}
        />
        <PeriodChip 
          label={Period.Annual}
          isSelected={period === Period.Annual}
          onClick={handleChangePeriod(Period.Annual)}
        />
      </Box>
      <Box overflow="auto">
        <FinancialTable 
          data={incomeStatement?.incomeStatement} 
          titleList={incomeStatement?.titleList} 
          isLoading={isIncomeLoading} 
          isFetched={isIncomeFetched}
          isError={isIncomeError}
        />
      </Box>
    </BoxPrimary>
  )
}

export default IncomeStatement;