import { MouseEvent } from 'react';
import { useConfirmSignUp, useResendSignUp, useSignIn } from 'src/hooks';

function useSignUpMfa({
  username, 
  password,
  code
}: {
  username: string;
  password: string;
  code: string;
}) : {
  handleConfirm: (code: string) => void;
  handleResend: (event: MouseEvent<HTMLButtonElement>) => void;
  handleErrorClose: (event: MouseEvent<HTMLButtonElement>) => void;
  isConfirmLoading: boolean;
  isResendLoading: boolean;
  isError: boolean;
  shouldClose: boolean;
  hint: string;
  errorMsg?: string;
} {

  const confirmSignUpMutation = useConfirmSignUp();
  const resendSignUpMutation = useResendSignUp();
  const signInMutation = useSignIn();

  const hint = `You're almost done! We just sent the code to ${username}`;

  if(confirmSignUpMutation.isSuccess) {
    if(signInMutation.isIdle) {
      signInMutation.mutate({
        email: username,
        password
      });
    }
  }

  function handleErrorClose() {
    confirmSignUpMutation.reset();
    resendSignUpMutation.reset();
  }

  function handleResendSignUp() {
    resendSignUpMutation.mutate({ username });
  }

  function handleConfirmSignUp(code: string) {
    confirmSignUpMutation.mutate({
      username,
      code
    });
  }

  return {
    handleConfirm: handleConfirmSignUp,
    handleResend: handleResendSignUp,
    handleErrorClose,
    isConfirmLoading: confirmSignUpMutation.isLoading,
    isResendLoading: resendSignUpMutation.isLoading,
    isError: confirmSignUpMutation.isError || resendSignUpMutation.isError,
    shouldClose: signInMutation.isSuccess,
    hint,
    errorMsg: confirmSignUpMutation.error?.message || resendSignUpMutation.error?.message
  }
}

export default useSignUpMfa;