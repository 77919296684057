import { styled } from '@mui/material/styles';

import { Box } from "../mui";

const StyledPressReleaseContainer = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.app.border.color.default}`,
}));

export {
  StyledPressReleaseContainer
}