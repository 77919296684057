import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';

import * as queries from 'src/graphql/queries';
import { FinancialUIResponse } from 'src/types';
import { Period } from 'src/enums';

type GetCashFlowUiResponse = {
  data: {
    getCashFlowUi: FinancialUIResponse[]
  }
}

async function getCashFlowUi(period: Period, symbols: string[]): Promise<FinancialUIResponse[]> {
  const { data: { getCashFlowUi }} = 
    await (API.graphql(graphqlOperation(
      queries.getCashFlowUi, 
      { period, symbols }
    )) as Promise<GetCashFlowUiResponse>);

  return getCashFlowUi;
}

function useCashFlowUi(period: Period, symbols: string[]): UseQueryResult<FinancialUIResponse[]> {
  const options: UseQueryOptions<FinancialUIResponse[]> = { 
    enabled: !!period && symbols.length > 0,
  };

  return useQuery(
    ['cashFlowUi', period, symbols],
    () => getCashFlowUi(period, symbols), 
    options
  );
}

export default useCashFlowUi;