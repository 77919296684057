import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import Popper from "@mui/core/Popper";
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router';
import InsightDetails from "./InsightDetails";

import { Box } from "src/components/mui";
import { StyledCloseButton } from 'src/components/mui/Button/styled';
import { useLearning } from "src/contexts";
import { LearningType } from "src/enums";
import { useEffect } from 'react';

/* Insight container when matches mdUp (Desktop) */
function InsightPopper() {
  const location = useLocation();
  const anchorEl = document.getElementById('learning-popper');

  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down('md'));

  const { state, dispatch } = useLearning();
  const open = !matchesDownSm && state.type === LearningType.Insight;

  const height = anchorEl ? window.innerHeight - anchorEl.getBoundingClientRect().top - 8 : window.innerHeight - theme.app.fixedTop.mdUp - 8;

  function handleClose() {
    dispatch({
      type: LearningType.None
    });
  }

  useEffect(() => {
    dispatch({
      type: LearningType.None
    });
  }, [location, dispatch]);

  if(!anchorEl) {
    return null;
  }

  return (
    <Popper 
      open={open} 
      anchorEl={anchorEl}
      placement="bottom-start"
    >
      <Box 
        display="flex"
        flexDirection="column"
        sx={{
          borderRadius: `${theme.app.borderRadius.paper}px`,
          width: 460,
          p: theme.spacing(3),
          pt: 0,
          pb: theme.spacing(3),
          backgroundColor: theme.app.learning.popper.background,
          height
        }}
      >
        <Box sx={{ textAlign: "right" }}>
          <StyledCloseButton
            aria-label="close"
            onClick={handleClose}
            variant="text"
          >
            <CloseIcon onClick={handleClose}/>
          </StyledCloseButton>
        </Box>
        <InsightDetails />
      </Box>
    </Popper>
  );
}

export default InsightPopper;