import * as React from 'react';

import { ActionType, NotificationMessage, TNotification } from 'src/parts/Notification/types.d';

export type Action = { type: ActionType, data?: { open?: boolean, message?: NotificationMessage } };

export type Dispatch = (action: Action) => void;

type NotificationProviderProps = { children: React.ReactNode };

const NotificationStateContext = React.createContext<
  {state: TNotification; dispatch: Dispatch} | undefined
>(undefined);

function notificationReducer(state: TNotification, action: Action) {
  const { data } = action;

  switch (action.type) {
    case ActionType.SetOpen: {
      return {
        ...state,
        open: data?.open ?? state.open
      }
    }
    case ActionType.UpdateMessageInfo: {
      return {
        ...state,
        messageInfo: data?.message
      }
    }
    case ActionType.DeleteHead: {
      return {
        ...state,
        queue: state.queue.slice(1)
      }
    }
    case ActionType.PushToQueue: {
      if(!data?.message) {
        return state
      }

      return {
        ...state,
        queue: [...state.queue, data.message]
      }
    }
    default: {
      throw new Error(`Unhandled notification action type: ${action.type}`);
    }
  }
}

function NotificationProvider({ children }: NotificationProviderProps) {
  const [state, dispatch] = React.useReducer(
    notificationReducer, 
    { open: false, queue: [], messageInfo: undefined, origin: { vertical: 'top', horizontal: 'center' } }
  );
  
  const value = { state, dispatch };

  return (
    <NotificationStateContext.Provider value={value}>
      {children}
    </NotificationStateContext.Provider>
  );
}

function useNotification() {
  const context = React.useContext(NotificationStateContext);

  if (context === undefined) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }

  return context;
}
export { NotificationProvider, useNotification }