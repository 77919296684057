import { useTheme } from '@mui/material/styles';

import {
  Box,
	BoxPrimary,
} from 'src/components/mui';
import StockTable from '../stockTable';
import TitleWithCurrency from 'src/components/TitleWithCurrency';
import { useTopLosers } from 'src/hooks';

const TopLosers = () => {
  const theme = useTheme();

  const { data, isLoading, isFetched, isError } = useTopLosers();
  const tableData = data ? data.slice(0, 5) : [];

  return (
    <BoxPrimary mb={1}>
      <TitleWithCurrency title="Top Losers" currency="USD" color={theme.palette.error.main} />
      <Box overflow="auto">
        <StockTable data={tableData} isLoading={isLoading} isFetched={isFetched} isError={isError} />
      </Box>
    </BoxPrimary>
  )
}

export default TopLosers;