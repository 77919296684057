import { styled } from '@mui/material/styles';

import {
  Grid,
} from 'src/components/mui';

const StyledContent = styled(Grid)(({ theme }) => ({
  '&&': {
    [theme.breakpoints.down('md')]: {
      paddingTop: 0
    }
  }
}));

export {
  StyledContent
}