import { useHistory } from "react-router-dom";

import { Paths, StockPathEntity } from 'src/routes/paths';

function useNavigation() {
  let history = useHistory();

  function navigateToHome() {
    history.push(Paths.HOME);
  }

  function navigateToNews(symbolId: string) {
    history.push(`${Paths.NEWS}/${symbolId}`);
  }

  function navigateToStock(symbolId: string) {
    history.push(`${Paths.STOCK}/${StockPathEntity.Overview}/${symbolId}`);
  }

  return {
    navigateToHome,
    navigateToStock,
    navigateToNews,
  }
}

export default useNavigation;