/* tslint:disable */
/* eslint-disable */

/* Market Performance */
export const getMostActiveStocks = `
  query GetMostActiveStocks {
    getMostActiveStocks {
      changes
      changesPercentage
      companyName
      price
      ticker
    }
  }
`;
export const getMostGainer = `
  query GetMostGainer {
    getMostGainer {
      changes
      changesPercentage
      companyName
      price
      ticker
    }
  }
`;
export const getMostLoser = `
  query GetMostLoser {
    getMostLoser {
      changes
      changesPercentage
      companyName
      price
      ticker
    }
  }
`;

/* Recommendation */
export const getRecommendedFeeds = `
  query GetRecommendedFeeds($page: Int!, $symbols: [String!]!) {
    getRecommendedFeeds(page: $page, symbols: $symbols) {
      image
      publishedDate
      site
      symbol
      text
      title
      url
    }
  }
`;
export const getRecommendedSymbols = `
  query GetRecommendedSymbols {
    getRecommendedSymbols {
      id
      symbol
      company {
        id
        companyName
        exchange
        industry
        description
        sector
        tags
      }
      exchange
      name
      date
      type
      region
      figi
      cik
    }
  }
`;

/* Ownership UI */
export const getOwnershipInstitutionalSummaryUi = `
  query getOwnershipInstitutionalSummaryUi(
    $symbol: String!
  ) {
    getOwnershipInstitutionalSummaryUi(symbol: $symbol) {
      date
      investorsHolding
      investorsHoldingChange
      symbol
      totalInvested
      totalInvestedChangePercentage
    }
  }
`;
export const getOwnershipEtfStockUi = `
  query getOwnershipEtfStockUi(
    $symbol: String!
  ) {
    getOwnershipEtfStockUi(symbol: $symbol) {
      identifier
      marketValue
      name
      numberOfShares
      type
      weight
    }
  }
`;
export const getOwnershipFundUi = `
  query GetOwnershipFundUi(
    $symbol: String!
  ) {
    getOwnershipFundUi(symbol: $symbol) {
      identifier
      marketValue
      name
      numberOfShares
      type
      weight
    }
  }
`;

export const getOwnershipInsiderUi = `
  query GetOwnershipInsiderUi(
    $symbol: String!
  ) {
    getOwnershipInsiderUi(symbol: $symbol) {
      date
      name
      price
      sharesChange
      title
      type
    }
  }
`;

export const getOwnershipInstitutionalHolderUi = `
  query getOwnershipInstitutionalHolderUi(
    $symbol: String!
  ) {
    getOwnershipInstitutionalHolderUi(symbol: $symbol) {
      changePercentage
      date
      name
      numberOfShares
      sharesChange
    }
  }
`;

export const getOwnershipInsiderTransactionUi = `
  query getOwnershipInsiderTransactionUi(
    $symbol: String!
  ) {
    getOwnershipInsiderTransactionUi(symbol: $symbol) {
      date
      name
      price
      sharesChange
      title
      type
    }
  }
`;

/* Valuation Queries */
export const getValuationUi = 
`query getValuationUi($symbol: String!) {
  getValuationUi(symbol: $symbol) {
    symbol
    valuationAssumptions {
      valuationAssumptions {
        groupData {
          dataKey
          dataList
          isHighlighted
          dataType
        }
        currency
        groupName
      }
    }
    valuationBrief {
      valuationBrief {
        currency
        groupData {
          dataKey
          dataList
          dataType
          isHighlighted
        }
        groupName
      }
    }
    valuationFreeCashFlow {
      valuationFreeCashFlow {
        currency
        groupData {
          dataKey
          dataList
          dataType
          isHighlighted
        }
        groupName
      }
      titleList
    }
    valuationMetrics {
      valuationMetrics {
        currency
        groupData {
          dataKey
          dataList
          dataType
          isHighlighted
        }
        groupName
      }
    }
    valuationProjections {
      titleList
      valuationProjections {
        currency
        groupName
        groupData {
          dataKey
          dataList
          dataType
          isHighlighted
        }
      }
    }
    valuationSharePrice {
      currency
      valuationSharePrice
    }
  }
}`;

/* Estimates Queries*/
export const getEstimatesRatingsSummaryUi = 
`query getEstimatesRatingsSummaryUi($symbols: [String!]!) {
  getEstimatesRatingsSummaryUi(symbols: $symbols) {
    buy
    strongBuy
    neutral
    sell
    strongSell
    symbol
  }
}`;
export const getRatingsBySymbolByDatetime = 
`query getRatingsBySymbolByDatetime($symbol: String!, $datetime: ModelStringKeyConditionInput, $filter: ModelRatingsFilterInput, $sortDirection: ModelSortDirection) {
  getRatingsBySymbolByDatetime(symbol: $symbol, datetime: $datetime, filter: $filter, sortDirection: $sortDirection ) {
    items {
      id
      actionCompany
      actionPt
      datetime
      exchange
      provider
      ratingCurrent
      ratingPrior
      symbol
    }
  }
}`;
export const getEstimatesCurrentUi = /* GraphQL */ 
`query GetEstimatesCurrentUi($period: String!, $symbols: [String!]!) {
  getEstimatesCurrentUi(period: $period, symbols: $symbols, ) {
    period
    symbol
    titleList
    currentEstimates {
      groupData {
        isHighlighted
        dataList
        dataKey
      }
      groupName
    }
  }
}`;
export const getEstimatesHistoricalUi = /* GraphQL */ 
`query GetEstimatesHistoricalUi($period: String!, $symbols: [String!]!) {
  getEstimatesHistoricalUi(period: $period, symbols: $symbols, ) {
    period
    symbol
    titleList
    historicalEstimates {
      groupData {
        isHighlighted
        dataList
        dataKey
      }
      groupName
    }
  }
}`;

/* News Queries */
export const getPressBySymbolByDatetime = `
  query getPressBySymbolByDatetime(
    $symbol: String!
    $limit: Int
    $sortDirection: ModelSortDirection
    $nextToken: String
    $datetime: ModelStringKeyConditionInput
  ) {
    getPressBySymbolByDatetime(symbol: $symbol, limit: $limit, sortDirection: $sortDirection, nextToken: $nextToken, datetime: $datetime) {
      items {
        content
        datetime
        exchange
        id
        provider
        symbol
        title
      }
      nextToken
    }
  }
`;
export const getNews = `
  query getNews(
    $id: ID!
  ) {
    getNews(id: $id) {
      datetime
      hasPaywall
      headline
      image
      id
      lang
      related
      relatedSymbols
      summary
      source
      symbol
      url
    }
  }
`;
export const getNewsBySymbolByDatetime = `
  query getNewsBySymbolByDatetime(
    $symbol: String!
    $limit: Int
    $sortDirection: ModelSortDirection
    $nextToken: String
  ) {
    getNewsBySymbolByDatetime(symbol: $symbol, limit: $limit, sortDirection: $sortDirection, nextToken: $nextToken) {
      items {
        datetime
        hasPaywall
        headline
        image
        id
        lang
        related
        relatedSymbols
        summary
        source
        symbol
        url
      }
      nextToken
    }
  }
`;

/* Charts Queries */
export const getIndexChartOverview = `
  query getIndexChartOverview(
    $range: String!,
    $symbols: [String!]!
  ) {
    getIndexChartOverview(range: $range, symbols: $symbols) {
      symbol
      data {  
        change
        changeOverTime
        changePercent
        close
        date
        high
        isDaily
        label
        low
        minute
        open
        provider
        symbol
        unadjustedVolume
        volume
        vwap
      }
    }
  }
`;
export const getChartOverviewUi = `
  query getChartOverviewUi(
    $range: String!,
    $symbols: [String!]!
  ) {
    getChartOverviewUi(range: $range, symbols: $symbols) {
      symbol
      data {
        average
        changeOverTime
        close
        date
        high
        label
        low
        notional
        numberOfTrades
        open
        symbol
        volume
      }
    }
  }
`;
export const getChartOverviewUiMinute = `
  query getChartOverviewUi(
    $range: String!,
    $symbols: [String!]!
  ) {
    getChartOverviewUi(range: $range, symbols: $symbols) {
      symbol
      data {
        average
        changeOverTime
        close
        date
        high
        label
        low
        minute
        notional
        numberOfTrades
        open
        symbol
        volume
      }
    }
  }
`;

/* Insight Queries */
const insightsQuery = `
  insights {
    type
    importance
    value
    id
    sidePanel {
      chartType
      equation
      explanation
      whyItMatters
      definition
      visualization
      concept {
        conceptName
        definition
        equation
        equationLaTeX
        importance
      }
    }
  }
`;
export const getInsightOwnershipInsider = `
  query getInsightOwnershipInsider(
    $symbols: [String!]!
  ) {
    getInsightOwnershipInsider(symbols: $symbols) {
      ${insightsQuery}
      symbol
    }
  }
`;
export const getInsightOwnershipInstitution = `
  query getInsightOwnershipInstitution(
    $symbols: [String!]!
  ) {
    getInsightOwnershipInstitution(symbols: $symbols) {
      ${insightsQuery}
      symbol
    }
  }
`;
export const getInsightFinancialIncomeStatement = `
  query getInsightFinancialIncomeStatement(
    $symbols: [String!]!
  ) {
    getInsightFinancialIncomeStatement(symbols: $symbols) {
      ${insightsQuery}
      symbol
    }
  }
`;
export const getInsightFinancialBalanceSheet = `
  query getInsightFinancialBalanceSheet(
    $symbols: [String!]!
  ) {
    getInsightFinancialBalanceSheet(symbols: $symbols) {
      ${insightsQuery}
      symbol
    }
  }
`;
export const getInsightFinancialCashFlow = `
  query getInsightFinancialCashFlow(
    $symbols: [String!]!
  ) {
    getInsightFinancialCashFlow(symbols: $symbols) {
      ${insightsQuery}
      symbol
    }
  }
`;
export const getInsightOverviewMarket = `
  query getInsightOverviewMarket(
    $symbols: [String!]!
  ) {
    getInsightOverviewMarket(symbols: $symbols) {
      ${insightsQuery}
      symbol
    }
  }
`;
export const getInsightValuations = `
  query getInsightValuations(
    $symbols: [String!]!
  ) {
    getInsightValuations(symbols: $symbols) {
      ${insightsQuery}
      symbol
    }
  }
`;
export const getInsightMatrixValuation = `
  query getInsightMatrixValuation(
    $symbols: [String!]!
  ) {
    getInsightMatrixValuation(symbols: $symbols) {
      ${insightsQuery}
      symbol
    }
  }
`;
export const getInsightMatrixFinancialHealth = `
  query getInsightMatrixFinancialHealth(
    $symbols: [String!]!
  ) {
    getInsightMatrixFinancialHealth(symbols: $symbols) {
      ${insightsQuery}
      symbol
    }
  }
`;
export const getInsightMatrixManagement = `
  query getInsightMatrixManagement(
    $symbols: [String!]!
  ) {
    getInsightMatrixManagement(symbols: $symbols) {
      ${insightsQuery}
      symbol
    }
  }
`;
export const getInsightMatrixGrowth = `
  query getInsightMatrixGrowth(
    $symbols: [String!]!
  ) {
    getInsightMatrixGrowth(symbols: $symbols) {
      ${insightsQuery}
      symbol
    }
  }
`;
export const getInsightMatrixShareholderOwnership = `
  query getInsightMatrixShareholderOwnership(
    $symbols: [String!]!
  ) {
    getInsightMatrixShareholderOwnership(symbols: $symbols) {
      ${insightsQuery}
      symbol
    }
  }
`;

export const getRealTimeQuoteFull = `
  query getRealTimeQuoteFull(
    $symbols: [String!]!
  ) {
    getRealTimeQuoteFull(symbols: $symbols) {
      change
      changePercent
      avgTotalVolume
      calculationPrice
      companyName
      currency
      week52Low
      week52High
      volume
      symbol
      primaryExchange
      isUSMarketOpen
      latestPrice
      latestTime
      latestUpdate
      latestVolume
      extendedChange
      extendedChangePercent
      extendedPrice
      extendedPriceTime
      close
      closeSource
      closeTime
      high
      highSource
      highTime
      lastTradeTime
      latestSource
      low
      lowSource
      lowTime
      marketCap
      open
      openSource
      openTime
      peRatio
      previousClose
      previousVolume
      ytdChange
    }
  }
`;

export const getUser = `
  query GetUser(
    $id: ID!
    $recentSearchLimit: Int
    $recentSearchSortDirection: ModelSortDirection
  ) {
    getUser(id: $id) {
      id
      email
      recentSearches(limit: $recentSearchLimit, sortDirection: $recentSearchSortDirection) {
        items {
          id
          createdAt
          symbol {
            id
            symbol
            company {
              id
              companyName
              exchange
              industry
              description
              sector
              tags
            }
            exchange
            name
            date
            type
            region
            figi
            cik
          }
        }
      }
      follows {
        items {
          id
          createdAt
          symbol {
            id
            symbol
            company {
              id
              companyName
              exchange
              industry
              description
              sector
              tags
            }
            exchange
            name
            date
            type
            region
            figi
            cik
          }
        }
      }
    }
  }
`;

export const getSymbol = `
  query GetSymbol(
    $id: ID!
  ) {
    getSymbol(id: $id) {
      id
      symbol
      company {
        id
        companyName
        exchange
        industry
        description
        sector
        tags
      }
      exchange
      name
      date
      type
      region
      figi
      cik
    }
  }
`;

export const getSymbolSearchUi =
  `query getSymbolSearchUi(
    $search: String!
  ) {
    getSymbolSearchUi(search: $search) {
      exchange
      name
      search
      symbolId
      symbolName
    }
  }`;

export const searchSymbols = /* GraphQL */ 
  `query SearchSymbols(
    $filter: SearchableSymbolFilterInput
    $sort: SearchableSymbolSortInput,
    $limit: Int
    $nextToken: String
  ) {
    searchSymbols(filter: $filter, limit: $limit, sort: $sort, nextToken: $nextToken) {
      items {
        id
        symbol
        name
        date
        type
        region
        figi
        cik
        isSandbox
      }
      nextToken
    }
  }`;

export const listSymbols = /* GraphQL */ 
  `query ListSymbols(
    $filter: ModelSymbolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSymbols(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        symbol
        name
        date
        type
        region
        figi
        cik
      }
      nextToken
    }
  }`;

export const getValuation = /* GraphQL */ `
  query GetValuation($input: FairValueMethod!) {
    getValuation(input: $input) {
      symbol
      peRatio
      pbRatio
      fairValue
      method
    }
  }
`;
export const getIncomeStatementUi = /* GraphQL */ 
  `query GetIncomeStatementUi($period: String!, $symbols: [String!]!) {
    getIncomeStatementUi(period: $period, symbols: $symbols, ) {
      period
      symbol
      titleList
      incomeStatement {
        groupData {
          isHighlighted
          dataList
          dataKey
        }
        groupName
      }
    }
  }`;
export const getBalanceSheetUi = /* GraphQL */ 
`query GetBalanceSheetUi($period: String!, $symbols: [String!]!) {
  getBalanceSheetUi(period: $period, symbols: $symbols, ) {
    period
    symbol
    titleList
    balanceSheet {
      groupData {
        isHighlighted
        dataList
        dataKey
      }
      groupName
    }
  }
}`;
export const getCashFlowUi = /* GraphQL */ 
`query GetCashFlowUi($period: String!, $symbols: [String!]!) {
  getCashFlowUi(period: $period, symbols: $symbols, ) {
    period
    symbol
    titleList
    cashFlow {
      groupData {
        isHighlighted
        dataList
        dataKey
      }
      groupName
    }
  }
}`;

export const listIncomeStatements = /* GraphQL */ `
  query ListIncomeStatements(
    $filter: ModelIncomeStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIncomeStatements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        costOfRevenue
        currency
        ebit
        fillingType
        fiscalDate
        fiscalQuater
        fiscalYear
        grossProfit
        incomeTax
        interestIncome
        isSandbox
        minorityInterest
        netIncome
        netIncomeBasic
        operatingExpense
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBalanceSheet = /* GraphQL */ `
  query GetBalanceSheet($id: ID!) {
    getBalanceSheet(id: $id) {
      id
      accountsPayable
      commonStock
      companyId
      currency
      currentAssets
      currentCash
      fillingType
      fiscalDate
      fiscalQuater
      fiscalYear
      goodwill
      intangiableAssets
      inventory
      isSandbox
      longTermDebt
      longTermInvestments
      minorityInterest
      createdAt
      updatedAt
    }
  }
`;
export const listBalanceSheets = /* GraphQL */ `
  query ListBalanceSheets(
    $filter: ModelBalanceSheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBalanceSheets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountsPayable
        commonStock
        companyId
        currency
        currentAssets
        currentCash
        fillingType
        fiscalDate
        fiscalQuater
        fiscalYear
        goodwill
        intangiableAssets
        inventory
        isSandbox
        longTermDebt
        longTermInvestments
        minorityInterest
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
