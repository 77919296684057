import { displayNumber } from "src/utils/numberUtils";

function CustomizedLabel (props: any) {
  const { x, y, width, textColor = "#000", value, dy = -6 } = props;
  // Bar chart has width
  const updatedX = width ? x + width / 2 : x;

  return (
    <text x={updatedX} y={y} dy={dy} fill={textColor} fontSize={10} textAnchor="middle">
      {displayNumber({ input: value, showUnit: true })}
    </text>
  );
};

export default CustomizedLabel;