import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';

import * as queries from 'src/graphql/queries';

type InstitutionalUi = {
  changePercentage: string
  date: string
  name: string
  numberOfShares: string
  sharesChange: string
}
type Response = {
  data: {
    getOwnershipInstitutionalHolderUi: InstitutionalUi[]
  }
}

async function getOwnershipInstitutionalHolderUi(symbol: string | undefined): Promise<InstitutionalUi[]> {
  const { data: { getOwnershipInstitutionalHolderUi }} = 
    await (API.graphql(graphqlOperation(
      queries.getOwnershipInstitutionalHolderUi, 
      { symbol: symbol || '' }
    )) as Promise<Response>);

  return getOwnershipInstitutionalHolderUi;
}

function useOwnershipInstitutionalHolderUi(symbol: string | undefined): UseQueryResult<InstitutionalUi[]> {
  const options: UseQueryOptions<InstitutionalUi[]> = { 
    enabled: !!symbol,
    select: (data) => {
      return data.sort((a, b) => {
        return Number(b.numberOfShares) - Number(a.numberOfShares);
      })
    }
  };

  return useQuery(
    ['getOwnershipInstitutionalHolderUi', symbol], 
    () => getOwnershipInstitutionalHolderUi(symbol), 
    options
  );
}

export default useOwnershipInstitutionalHolderUi;