import { Skeleton } from "@mui/material";
import {
  Bar
} from "recharts";

import {
  BoxPrimary,
  BoxSecondary,
  CardHeaderDefault
} from 'src/components/mui';
import HistoricalEstimate from "./HistoricalEstimate";
import CurrentEstimates from "./CurrentEstimates";
import AnalystActions from './sidebar/AnalystActions';
import HorizontalStackBarChart from 'src/components/charts/barChart/HorizontalStackBarChart';
import NoData from "src/components/NoData";
import { useEstimatesRatingsSummaryUi } from 'src/hooks';
import { Payload } from "recharts/types/component/DefaultLegendContent";
import { RatingType } from "src/types";

const analystRecommendationColor = {
  strongBuy: '#0018F2',
  buy: '#4823AB',
  neutral: '#79297A',
  sell: '#AC3147',
  strongSell: '#F13A03',
} as RatingType;

function Research({
  symbol
} : {
  symbol: string | undefined
}) {
  const sixMonthAgo = new Date();
  sixMonthAgo.setMonth(sixMonthAgo.getMonth() - 6);
  const months = sixMonthAgo.getMonth() < 10 ? `0${sixMonthAgo.getMonth()}` : sixMonthAgo.getMonth();

  const datetime = { gt: `${sixMonthAgo.getFullYear()}-${months}` };

  const symbols = symbol ? [symbol] : [];

  const {
    data: analystRecommendations,
    isLoading,
    isFetched,
  } = useEstimatesRatingsSummaryUi(symbols);

  const analystRecommendation = analystRecommendations?.find((recommendation) => recommendation.symbol === symbol);

  const legendPayload = analystRecommendation 
    ? (Object.keys(analystRecommendation) as Array<keyof typeof analystRecommendation>).map((ratingType) => {
      if(ratingType === 'symbol') {
        return {
          id: ratingType,
          inactive: true
        } as Payload
      }

      return {
        id: ratingType,
        value: `${ratingType}: ${analystRecommendation[ratingType]}`, 
        type: 'square', 
        color: analystRecommendationColor[ratingType]
      } as Payload
    }).filter((payload) => !payload.inactive)
    : undefined;

  const data = analystRecommendation ? [analystRecommendation] : []

  return (
    <>
      <BoxPrimary mb={2}>
        <CardHeaderDefault title="Company Estimates" />
        <HistoricalEstimate symbol={symbol} />
        <CurrentEstimates symbol={symbol} />
      </BoxPrimary>
      <BoxPrimary mb={2}>
        <CardHeaderDefault title="Analyst Recommendations" sx={{ mb: 1 }} />
        <BoxSecondary mb={2}>
          {
            isLoading || !isFetched 
            ? (
              <Skeleton variant="rectangular" height="100px" width={'100%'} />
            ) : analystRecommendation && data.length > 0 
            ? (
              <HorizontalStackBarChart data={data} legendPayload={legendPayload} chartHeight={100}>
                {
                  (Object.keys(analystRecommendation) as Array<keyof typeof analystRecommendation>).map((ratingType) => {
                    if(ratingType === 'symbol') {
                      return null;
                    }
                    
                    return (
                      <Bar dataKey={ratingType} stackId="a" fill={analystRecommendationColor[ratingType]} />
                    )
                  })
                }
              </HorizontalStackBarChart>
            ) : <NoData />
          }
        </BoxSecondary>
        <CardHeaderDefault title="Analyst & Company Actions" sx={{ mb: 1 }} />
        <AnalystActions symbol={symbol} datetime={datetime} />
      </BoxPrimary>
    </>
  )
}

export default Research;