interface IframeProps {
  src: string;
  width: string;
  height?: string;
}

function Iframe({
  src,
  width,
  height
} : IframeProps) {
  return (
    <iframe title={src} src={src} frameBorder={0} style={{ width, height, display: "block" }} loading="lazy" />
  )
}

export default Iframe;