import { styled } from '@mui/material';

import { IconButton } from "src/components/mui";

export const StyledCloseButton = styled(IconButton)((props) => ({
  color: props.theme.palette.grey[500],

  '& .MuiSvgIcon-root': {
    color: props.theme.palette.grey[500],
  },

  '&:hover': {
    '& .MuiSvgIcon-root': {
      color: props.theme.palette.grey[500],
    },
  },
}));