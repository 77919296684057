import { useMutation, UseMutationResult } from 'react-query';
import { Auth } from 'aws-amplify';

type ForgotPasswordSubmitInput = {
  username: string;
  code: string; 
  newPassword: string
}

type Error = {
  message: string;
  __type: string;
}

function forgotPasswordSubmit(username: string, code: string, newPassword: string): Promise<string> {
  return Auth.forgotPasswordSubmit(username, code, newPassword);
}

/* get the cognito authenticated user */
function useForgotPasswordSubmit(): UseMutationResult<string, Error, ForgotPasswordSubmitInput, unknown> {
  return useMutation<string, Error, ForgotPasswordSubmitInput, unknown>(
    ({ username, code, newPassword }) => forgotPasswordSubmit(username, code, newPassword));
}

export default useForgotPasswordSubmit;