import React from "react";
import { SnackbarOrigin, AlertColor } from "@mui/material";

export type NotificationMessage = {
  message: string | React.ReactNode;
  key: number;
  severity: AlertColor;
}

export type TNotification = {
  open: boolean;
  queue: NotificationMessage[];
  messageInfo: NotificationMessage | undefined;
  origin: SnackbarOrigin
}

export enum ActionType {
  SetOpen = 'setOpen',
  DeleteHead = 'deleteHead',
  PushToQueue = 'pushToQueue',
  UpdateMessageInfo = 'updateMessageInfo'
}
