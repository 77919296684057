import BottomNavigationMui from '@mui/material/BottomNavigation';
import HomeIcon from '@mui/icons-material/Home';
import GradeIcon from '@mui/icons-material/Grade';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';

import { NavLink } from "react-router-dom";
import { Paths } from 'src/routes/paths';
import { BottomNavigationActionLink } from 'src/components/mui';

function BottomNavigation() {
  const theme = useTheme();

  return (
    <Paper sx={{ 
        position: 'fixed', 
        bottom: 0, 
        left: 0, 
        right: 0,
        backgroundColor: (theme) => theme.palette.background.default
      }} 
      elevation={3}
    >
      <BottomNavigationMui
        showLabels
      >
        <BottomNavigationActionLink 
          label="Home" 
          icon={<HomeIcon />} 
          component={NavLink} 
          to={Paths.HOME}
          exact
          activeStyle={{ color: theme.palette.primary.main }} 
        />
        <BottomNavigationActionLink 
          label="Watchlist" 
          icon={<GradeIcon />} 
          component={NavLink} 
          to={Paths.WATCHLIST}
          exact
          activeStyle={{ color: theme.palette.primary.main }} 
        />
      </BottomNavigationMui>
    </Paper>
  );
}

export default BottomNavigation;