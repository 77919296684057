import InsightDefinition from "./InsightDefinition";
import { InsightImportance } from "src/enums";

function InsightDefinitionContainer() {

  return (
    <>
      <InsightDefinition importance={InsightImportance.High} />
      <InsightDefinition importance={InsightImportance.Medium} />
      <InsightDefinition importance={InsightImportance.Low} />
    </>
  );
}

export default InsightDefinitionContainer;