import awsExports from './aws-exports'; 

const amplifyConfig = {
  Analytics: {
    AWSKinesisFirehose: {
      region: awsExports.aws_project_region
    }
  },
  aws_firehose_name: 'poodle-app-firehose'
}

export default amplifyConfig;