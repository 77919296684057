import { useState } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Box,
  PeriodChip
} from 'src/components/mui';
import { FinancialTable } from 'src/components/tables';
import TitleWithCurrency from 'src/components/TitleWithCurrency';
import { useEstimatesUi } from 'src/hooks';
import { Period } from 'src/enums';
import * as queries from 'src/graphql/queries';

function CurrentEstimates({
  symbol
} : {
  symbol: string | undefined
}) {

  const symbols = symbol ? [symbol] : [];

  const theme = useTheme();

  const [period, setPeriod] = useState<Period>(Period.Quarterly);

  const { data, isLoading, isFetched, isError } = useEstimatesUi(period, symbols, queries.getEstimatesCurrentUi);
  const currentEstimatesUi = data?.data.getEstimatesCurrentUi;
  const estimatesOfSymbol = currentEstimatesUi?.find((estimates) => symbol === estimates.symbol);

  function handleChangePeriod(period: Period) {
    return function() {
      setPeriod(period);
    }
  }

  return (
    <Box
      p={2}
      sx={{ 
        background: theme.palette.background.default,
        borderRadius: `${theme.shape.borderRadius}px`,
      }}
    >
      <TitleWithCurrency title="Current Consensus Estimates" currency="USD" />
      <Box display="flex" justifyContent="flex-end" mb={1} >
        <PeriodChip 
          label={Period.Quarterly}
          isSelected={period === Period.Quarterly}
          sx={{ mr: 0.5 }}
          onClick={handleChangePeriod(Period.Quarterly)}
        />
        <PeriodChip 
          label={Period.Annual}
          isSelected={period === Period.Annual}
          onClick={handleChangePeriod(Period.Annual)}
        />
      </Box>
      <Box overflow="auto">
        <FinancialTable 
          data={estimatesOfSymbol?.currentEstimates}
          titleList={estimatesOfSymbol?.titleList}
          isLoading={isLoading} 
          isFetched={isFetched}
          isError={isError}
          groupBackground='white'
          showGroupName
        />
      </Box>
    </Box>
  )
}

export default CurrentEstimates;