import React from "react";
import {
  BarChart,
  ResponsiveContainer,
  XAxis,
  Legend,
} from "recharts";
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import ResponsiveTick from "src/components/charts/ResponsiveTick";

function CustomBarChart({
  data,
  height,
  xAxisTickRenderer,
  children
} : {
  data: any,
  height?: number,
  xAxisTickRenderer?: Function,
  children: React.ReactNode,
}) {
  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down('md'));

  const chartHeight = height || (matchesDownSm ? 200 : 300);
  const barGap = matchesDownSm ? 15 : 20;

  return (
    <ResponsiveContainer width="100%" height={chartHeight}>
      <BarChart
        height={chartHeight}
        data={data}
        barGap={barGap}
        margin={{
          top: 20,
          bottom: 5
        }}
      >
        <XAxis dataKey="name" interval={0} tick={<ResponsiveTick renderer={xAxisTickRenderer} />} />
        <Legend iconSize={10} iconType="square" fontFamily={theme.app.chart.font.family} />
        {
          children
        }
      </BarChart>
    </ResponsiveContainer>
  );
}

export default CustomBarChart;