import { styled, alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';

const StyledAutocomplete = styled('div')((props) => ({
  position: 'relative',
  transition: props.theme.transitions.create('width'),
  [props.theme.breakpoints.only('md')]: {
    flexGrow: 1,
  },
  [props.theme.breakpoints.up('lg')]: {
    flexGrow: 0.5,
  },
}));

const StyledSearch = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 16,
  backgroundColor: theme.app.background.textInput,
  marginLeft: 0,
  width: '100%',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),

  '&:hover': {
    backgroundColor: alpha('rgba(236, 237, 246, 1)', 0.7),
  },

  [theme.breakpoints.up('md')]: {
    width: 'auto',
  },
}));

const StyledSearchInput = styled(InputBase)(({ theme }) => ({
  padding: theme.spacing(1, 1, 1, 0),
  // vertical padding + font size from searchIcon
  paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
  width: '100%',
}));

const StyledListBox = styled('ul')((props) => ({
  position: 'absolute',
  margin: 0,
  padding: props.theme.spacing(0.5),
  overflow: 'auto',
  listStyle: 'none',
  maxHeight: '40vh',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  background: props.theme.palette.background.default,
  borderRadius: props.theme.app.borderRadius.paper,
  zIndex: 1000,

  '& li:hover, & li[aria-selected="true"]': {
    background: '#ECEDF7',
    cursor: 'pointer',
  },

  '& li': {
    borderBottom: `1px solid ${props.theme.app.border.color.default}`,
  },

  '& li:first-of-type': {
    borderTop: `1px solid ${props.theme.app.border.color.default}`,
  },

  [props.theme.breakpoints.down('md')]: {
    position: 'relative',
    maxHeight: 'none',
    borderRadius: 0,
    marginTop: props.theme.spacing(2)
  },
}));
const StyledRecentSearches = styled('div')({
  color: '#A0A3BD',
  padding: '0.5rem',
});
const StyledNoData = styled('div')({
  color: '#A0A3BD',
  padding: '0.5rem',
  paddingBottom: 0,
  textAlign: 'center',
});
const StyledOption = styled('li')({
  padding: '0.5rem',
});
const StyledExchange = styled(Typography)({
  color: '#D9DBE9',
  textTransform: 'uppercase',
})

export {
  StyledAutocomplete,
  StyledSearch,
  StyledSearchInput,

  StyledListBox,
  StyledRecentSearches,
  StyledNoData,
  StyledOption,
  StyledExchange
}