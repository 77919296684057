import { useInfiniteQuery, UseInfiniteQueryResult, UseInfiniteQueryOptions } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';

import * as queries from 'src/graphql/queries';
import { News, Connection } from 'src/types';
import { ModelSortDirection } from 'src/enums';

type GetNewsBySymbolResponse = {
  data: {
    getNewsBySymbolByDatetime: Connection<News>
  }
}

async function getNewsBySymbol(symbol: string | undefined, pageParam: string | null): Promise<Connection<News>> {
  const { data: { getNewsBySymbolByDatetime }} = 
    await (API.graphql(graphqlOperation(
      queries.getNewsBySymbolByDatetime, 
      { symbol: symbol || '', limit: 20, sortDirection: ModelSortDirection.DESC, nextToken: pageParam }
    )) as Promise<GetNewsBySymbolResponse>);

  return getNewsBySymbolByDatetime;
}

function useNewsBySymbol(symbol: string | undefined): UseInfiniteQueryResult<Connection<News>> {
  const options: UseInfiniteQueryOptions<Connection<News>> = { 
    enabled: !!symbol,
    getNextPageParam: (lastPage) => lastPage.nextToken
  };

  return useInfiniteQuery(
    ['newsBySymbol', symbol], 
    ({ pageParam = null }) => getNewsBySymbol(symbol, pageParam), 
    options
  );
}

export default useNewsBySymbol;