import { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';

import { useQuotes, usePrevious } from "src/hooks";
import { getChangeColor } from "src/utils";

function useMarketQuotes(symbols: string[]) {
  const theme = useTheme();

  const realTimeQuoteRef = useRef<HTMLElement>(null);

  const { data: realtimeQuotes, isFetching, isLoading, isSuccess, isFetched } = useQuotes(symbols);
  
  const realtimeQuote = realtimeQuotes ? realtimeQuotes[0] : null;

  const isMarketOpen = realtimeQuote?.isUSMarketOpen !== 'False';

  const latestUpdateDate = isMarketOpen 
    ? new Date(new Date().toLocaleDateString("en-US", {
        timeZone: "America/New_York" 
      }))
    : realtimeQuote?.latestTime;

  const prevChange = usePrevious<string | undefined>(realtimeQuote?.change);

  const changeColor = getChangeColor({ isCompare: false, change: realtimeQuote?.change, isMarketOpen, theme });
  const changeColorBg = getChangeColor({ 
    prevChange: prevChange, 
    change: realtimeQuote?.change, 
    isBg: true, 
    isMarketOpen, 
    theme 
  });

  useEffect(() => {
    const shouldAlertPriceChange = !isFetching && isMarketOpen;
    
    if(realTimeQuoteRef.current && shouldAlertPriceChange) {
      realTimeQuoteRef.current.style.backgroundColor = changeColorBg;
    }

    const bgTimer = setTimeout(() => {
      if(realTimeQuoteRef.current && shouldAlertPriceChange) {
        realTimeQuoteRef.current.style.backgroundColor = theme.palette.background.default;
      }
    }, 1000);

    return () => {
      clearTimeout(bgTimer);
    };
  }, [isFetching, isMarketOpen, realtimeQuote, changeColorBg, theme]);

  return {
    realTimeQuoteRef,
    realtimeQuote,
    isMarketOpen,
    latestUpdateDate,
    isLoading, 
    isSuccess, 
    isFetched,
    changeColor,
    changeColorBg,
  }
  
}

export default useMarketQuotes;