import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';

import * as queries from 'src/graphql/queries';
import { FmpNews } from 'src/types';

type GetRecommendedFeedsResponse = {
  data: {
    getRecommendedFeeds: FmpNews[]
  }
}

async function getRecommendedFeeds(symbols: string[], page: number = 0): Promise<FmpNews[]> {
  const { data: { getRecommendedFeeds }} = 
    await (API.graphql(graphqlOperation(queries.getRecommendedFeeds, { page, symbols })) as Promise<GetRecommendedFeedsResponse>);
  return getRecommendedFeeds;
}

function useRecommendedFeeds(symbols: string[], page?: number, ): UseQueryResult<FmpNews[]> {
  const options: UseQueryOptions<FmpNews[]> = { 
    enabled: symbols && symbols.length > 0,
  };

  return useQuery(
    ['recommendedFeeds', symbols],
    () => getRecommendedFeeds(symbols, page),
    options
  );
}

export default useRecommendedFeeds;