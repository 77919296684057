import { StyledSlider } from "./styled";

function SlideTrack({
  children
} : {
  children: React.ReactNode
}) {

  return (
    <StyledSlider>
      <div className="slide-track">
        {children}
      </div>
    </StyledSlider>
  );
}

export default SlideTrack;