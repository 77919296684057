import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from "@mui/material/Typography";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CloseIcon from '@mui/icons-material/Close';

import { 
  Box,
  BoxPrimary,
  CardHeaderDefault,
  Button,
} from 'src/components/mui';
import { useModal, useCognitoUser } from 'src/contexts';
import { useForgotPassword, useUpdateUserAttributes, useVerifyCurrentUserAttribute } from 'src/hooks';
import { ModalType } from 'src/enums';

interface State {
  email: string;
  confirmedPassword: string;
  newPassword: string;
  showPassword: boolean;
}
interface Error {
  newPassword: string | null;
  confirmedPassword: string | null;
  email: string | null;
  generic: string | null;
}

function Account() {
  const { authenticatedUser } = useCognitoUser();
  const { dispatch } = useModal();

  const [values, setValues] = useState<State>({
    email: authenticatedUser?.attributes.email ?? '',
    newPassword: '',
    confirmedPassword: '',
    showPassword: false,
  });

  const [errors, setErrors] = useState<Error>({
    newPassword: null,
    confirmedPassword: null,
    email: null,
    generic: null
  });

  const forgotPasswordMutation = useForgotPassword();
  const updateUserAtrributesMutation =  useUpdateUserAttributes();
  const verifyCurrentUserAttributeMutation = useVerifyCurrentUserAttribute();

  const isEmailNotVerified = authenticatedUser?.attributes.email_verified === false;

  function resetMutation() {
    verifyCurrentUserAttributeMutation.reset();
    forgotPasswordMutation.reset();
    setErrors({ ...errors, generic: null });
  }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      resetMutation();
      setValues({ ...values, [prop]: event.target.value });
      setErrors({ ...errors, [prop]: null });
    };

  function validateInputs(password: string | null, confirmedPassword: string | null) {
    if(!password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newPassword: 'Required'
      }));

      return false;
    }

    if(!confirmedPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmedPassword: 'Required'
      }));

      return false;
    }

    if(password !== confirmedPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmedPassword: 'The password is not matched'
      }));

      return false;
    }

    return true;
  }

  function validateEmail(email: string) {
    if(!email) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Invalid email'
      }));
      return false;
    }

    if(email === authenticatedUser?.attributes.email) {
      return false;
    }

    return true;
  }

  async function handleUpdateEmail(event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLInputElement>) {
    event.preventDefault();

    if(!validateEmail(values.email)) {
      return;
    }

    if(authenticatedUser?.attributes) {
      try {
        await updateUserAtrributesMutation.mutateAsync({
          user: authenticatedUser,
          attributes: {
            email: values.email
          }
        });
  
        dispatch({ 
          type: ModalType.UpdateEmailMFA, 
          data: {
            user: authenticatedUser,
            email: values.email,
          }
        });
      } catch(e) {
        setErrors({
          ...errors,
          generic: updateUserAtrributesMutation.error?.message ?? "Sending verification code"
        });
      }
    }
  }

  async function handleVerifyEmail(event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLInputElement>) {
    event.preventDefault();

    if(values.email !== authenticatedUser?.attributes.email) {
      setErrors({
        ...errors,
        generic: "Your email is seems changed, please proceed to update email instead"
      });
      return;
    }

    if(authenticatedUser?.attributes) {
      try {
        await verifyCurrentUserAttributeMutation.mutateAsync('email');
  
        dispatch({ 
          type: ModalType.UpdateEmailMFA, 
          data: {
            user: authenticatedUser,
            email: values.email,
          }
        });
      } catch(e) {
        setErrors({
          ...errors,
          generic: verifyCurrentUserAttributeMutation.error?.message ?? "Error: sending verification code"
        });
      }
    }
  }
  
  async function handleSubmitNewPassword(event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLInputElement>) {
    event.preventDefault();

    const { newPassword, confirmedPassword } = values;

    const areInputsValid = validateInputs(newPassword, confirmedPassword);

    if(!areInputsValid) {
      return;
    }

    if(authenticatedUser?.username) {
      try {
        await forgotPasswordMutation.mutateAsync({
          username: authenticatedUser?.attributes.email,
        });
        
        dispatch({ 
          type: ModalType.ChangePasswordMFA, 
          data: {
            ...values,
            username: authenticatedUser?.attributes.email,
          }
        });
      } catch(e) {
        setErrors({
          ...errors,
          generic: forgotPasswordMutation.error?.message ?? "Error: sending verification code"
        });
      }
    }
  }
  
  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if(event.key === "Enter") {
      handleSubmitNewPassword(event);
    }
  }

  return (
    <BoxPrimary mb={2}>
      <CardHeaderDefault title="Account" />
      <Box
        component="form"
        autoComplete="off"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "100%",
          padding: 1,
        }}
      >
        <Box 
          mb={2}
          sx={{ width: '50ch' }}
        >
          {
            errors.generic ? (
              <Alert 
                icon={false} 
                variant="outlined"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={resetMutation}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ 
                  width: "100%", 
                  color: (theme) => theme.palette.error.main,
                  borderColor: (theme) => theme.palette.error.main,
                }}
              >
                <AlertTitle>Error</AlertTitle>
                {errors.generic}
              </Alert>
            ) : null
          }
        </Box>
        <Box mb={2} sx={{ width: '50ch' }}>
          {
            isEmailNotVerified ? (
              <Alert severity='warning' sx={{ width: '100%', mb: 1 }}>
                Please verify your email to complete your account setup.
              </Alert> 
            ) : null
          }
          <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
            <InputLabel htmlFor="standard-adornment-email">Email</InputLabel>
            <Input
              id="standard-adornment-email"
              type="email"
              value={values.email}
              onChange={handleChange('email')}
              error={errors.email !== null}
            />
            <FormHelperText error>{errors.email}</FormHelperText>
          </FormControl>
          <Box display="flex" justifyContent={isEmailNotVerified ? "space-between" : "flex-end"}>
            {
              isEmailNotVerified ? (
                <Button 
                  type="submit"
                  variant="contained" 
                  onClick={handleVerifyEmail}
                  sx={{ color: 'white' }}
                >
                  {
                    "Verify email"
                  }
                </Button>
              ) : null
            }
            
            <Button 
              type="submit"
              variant="contained" 
              onClick={handleUpdateEmail}
              sx={{ color: 'white' }}
            >
              {
                "Update email"
              }
            </Button>
          </Box>
        </Box>
        <Box 
          mb={2} 
          flexDirection="column"
          justifyContent="center"
          alignItems="center" 
          sx={{ width: '50ch' }}
        >
          <Typography variant="h6" color="textPrimary" mb={1} alignSelf="flex-start">Change Password</Typography>
          <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
            <InputLabel htmlFor="standard-adornment-new-password">New Password</InputLabel>
            <Input
              id="standard-adornment-new-password"
              type={values.showPassword ? 'text' : 'password'}
              value={values.newPassword}
              onChange={handleChange('newPassword')}
              error={errors.newPassword !== null}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle new password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText error>{errors.newPassword}</FormHelperText>
          </FormControl>
          <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
            <InputLabel htmlFor="standard-adornment-confirm-password">Confirm Password</InputLabel>
            <Input
              id="standard-adornment-confirm-password"
              type={values.showPassword ? 'text' : 'password'}
              value={values.confirmedPassword}
              onChange={handleChange('confirmedPassword')}
              onKeyPress={handleKeyPress}
              error={errors.confirmedPassword !== null}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText error>{errors.confirmedPassword}</FormHelperText>
          </FormControl>
          <Box display="flex" justifyContent="flex-end">
            <Button 
              type="submit"
              variant="contained" 
              onClick={handleSubmitNewPassword}
              sx={{ color: 'white' }}
            >
              {
                "Change password"
              }
            </Button>
          </Box>
        </Box>
      </Box>
    </BoxPrimary>
  );
}

export default Account;