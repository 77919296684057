import { useInfiniteQuery, UseInfiniteQueryResult, UseInfiniteQueryOptions } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';

import * as queries from 'src/graphql/queries';
import { PressRelease, Connection } from 'src/types';
import { ModelSortDirection } from 'src/enums';

type GetPressReleaseBySymbolResponse = {
  data: {
    getPressBySymbolByDatetime: Connection<PressRelease>
  }
}

function getYearToFilter() {
  const now = new Date();
  const month = now.getMonth() + 1;
  const year = now.getFullYear();

  if(month < 5) {
    return year - 1;
  }

  return year;
}

async function getPressReleaseBySymbol(symbol: string | null, pageParam: string | null, limit: number): Promise<Connection<PressRelease>> {
  const yearToFilter = getYearToFilter();

  const { data: { getPressBySymbolByDatetime }} = 
    await (API.graphql(graphqlOperation(
      queries.getPressBySymbolByDatetime, 
      { symbol: symbol || '', limit, sortDirection: ModelSortDirection.DESC, nextToken: pageParam , datetime: { gt: yearToFilter }}
    )) as Promise<GetPressReleaseBySymbolResponse>);

  return getPressBySymbolByDatetime;
}

function usePressReleaseBySymbol(symbol: string | null, limit: number = 10): UseInfiniteQueryResult<Connection<PressRelease>> {
  const options: UseInfiniteQueryOptions<Connection<PressRelease>> = { 
    enabled: !!symbol,
    getNextPageParam: (lastPage) => lastPage.nextToken
  };

  return useInfiniteQuery(
    ['pressReleaseBySymbol', symbol], 
    ({ pageParam = null }) => getPressReleaseBySymbol(symbol, pageParam, limit), 
    options
  );
}

export default usePressReleaseBySymbol;