import {
  Box,
  BoxPrimary,
} from 'src/components/mui';
import InsightContainer from "src/components/insight/InsightContainer";
import InsightDefinitionContainer from 'src/components/insight/InsightDefinitionContainer';
import TitleWithCurrency from 'src/components/TitleWithCurrency';
import Market from './Market';
import { useInsights } from "src/hooks";
import { MarketInformationProps } from './types';
import * as queries from 'src/graphql/queries';

const MarketInformation = ({
  symbol
}: MarketInformationProps) => {

  const symbols = symbol ? [symbol] : [];

  const { 
    data: insights, 
    isFetched: isInsightsFetched, 
    isLoading: isInsightsLoading, 
    isSuccess: isInsightsSuccess 
  } = useInsights(symbols, queries.getInsightOverviewMarket);

  const symbolInsights = insights?.data.getInsightOverviewMarket.find(({ symbol: eachSymbol }) => eachSymbol === symbol);

  return (
    <BoxPrimary mb={2}>
      <TitleWithCurrency title="Market Information" currency="USD" />
      <Box display="flex" justifyContent="flex-end" mb={1}>
        <InsightDefinitionContainer />
      </Box>
      <InsightContainer 
        insights={symbolInsights?.insights}
        isLoading={isInsightsLoading}
        isSuccess={isInsightsSuccess}
        isFetched={isInsightsFetched}
      />
      <Market symbols={symbols} />
    </BoxPrimary>
  )
}

export default MarketInformation;