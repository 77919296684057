import { generatePath } from "react-router";

import { Paths, StockPathEntity, stockPathPattern, newsPathPattern } from './paths';

// Header navigation
export const Navigations = [
  {
    label: 'Home',
    path: Paths.HOME
  },
  {
    label: 'Watchlist',
    path: Paths.WATCHLIST
  },
];

export const toStock = (entity: StockPathEntity, symbolId: string | undefined) => symbolId ? generatePath(stockPathPattern, {
  symbolId,
  entity
}) : "";

export const toNews = (newsId: string) => generatePath(newsPathPattern, {
  newsId,
});