import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';

import * as queries from 'src/graphql/queries';
import { RatingType } from 'src/types';

type Response = {
  data: {
    getEstimatesRatingsSummaryUi: RatingType[]
  }
}

async function getEstimatesRatingsSummaryUi(
  symbols: string[] | undefined, 
): Promise<RatingType[]> {
  const { data: { getEstimatesRatingsSummaryUi }} = 
    await (API.graphql(graphqlOperation(queries.getEstimatesRatingsSummaryUi,
      { symbols: symbols || '' }
    )) as Promise<Response>);

  return getEstimatesRatingsSummaryUi;
}

function useEstimatesRatingsSummaryUi(
  symbols: string[] | undefined, 
): UseQueryResult<RatingType[]> {

  const options: UseQueryOptions<RatingType[]> = { 
    enabled: symbols && symbols.length > 0,
  };

  return useQuery(
    ['getEstimatesRatingsSummaryUi', symbols], 
    () => getEstimatesRatingsSummaryUi(symbols), 
    options
  );
}

export default useEstimatesRatingsSummaryUi;