import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Typography from "@mui/material/Typography";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CloseIcon from '@mui/icons-material/Close';

import { useModal } from 'src/contexts';
import { useForgotPasswordSubmit } from 'src/hooks';
import { Box, Button } from 'src/components/mui';
import { ModalType } from 'src/enums';

interface State {
  code: string;
  newPassword: string;
  showPassword: boolean;
}

interface Error {
  newPassword: string | null;
}

function NewPassword({
  data: username
}: {
  data: string
}) {
  const [values, setValues] = useState<State>({
    code: '',
    newPassword: '',
    showPassword: false,
  });

  const [errors, setErrors] = useState<Error>({
    newPassword: null,
  });

  const { dispatch } = useModal();

  const forgotPasswordSubmitMutation = useForgotPasswordSubmit();

  if(forgotPasswordSubmitMutation.isSuccess) {
    setTimeout(() => {
      dispatch({ type: ModalType.SignIn });
    }, 2000);
  }

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
      setErrors({ ...errors, [prop]: null });

      if(forgotPasswordSubmitMutation.isError) {
        forgotPasswordSubmitMutation.reset();
      }
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  function validatePassword(password: string | null) {
    if(!password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newPassword: 'Required'
      }));

      return false;
    }

    return true;
  }

  function handleSubmitNewPassword() {
    const { code, newPassword } = values;

    const isPasswordValid = validatePassword(newPassword);

    if(!isPasswordValid) {
      return;
    }

    forgotPasswordSubmitMutation.mutate({
      username,
      code,
      newPassword
    });
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if(event.key === "Enter") {
      handleSubmitNewPassword()
    }
  }

  function handleErrorClose() {
    forgotPasswordSubmitMutation.reset();
  }

  return (
    <Box sx={{ p: '20px 24px' }} display="flex" justifyContent="center" alignItems="center">
      <Box
        component="form"
        autoComplete="off"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '50ch',
          padding: 1
        }}
      >
        <Box mb={2}>
          <Typography variant="h5" color="textPrimary" fontWeight={700}>Reset your password</Typography>
        </Box>
        {
          forgotPasswordSubmitMutation.isSuccess ? (
            <Box mb={3} sx={{ width: "100%" }}>
              <Alert 
                icon={false} 
                variant="outlined"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleErrorClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                } 
                sx={{ 
                  width: "100%", 
                  color: (theme) => theme.palette.success.main,
                  borderColor: (theme) => theme.palette.success.main,
                }}
              >
                <AlertTitle>Success</AlertTitle>
                Your password is reset successfully, you will be directed to login page
              </Alert>
            </Box>
          ) : null
        }
        {
          forgotPasswordSubmitMutation.isError ? (
            <Box mb={3} sx={{ width: "100%" }}>
              <Alert 
                icon={false} 
                variant="outlined"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleErrorClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                } 
                sx={{ 
                  width: "100%", 
                  color: (theme) => theme.palette.error.main,
                  borderColor: (theme) => theme.palette.error.main,
                }}
              >
                <AlertTitle>Error</AlertTitle>
                {forgotPasswordSubmitMutation.error?.message}
              </Alert>
            </Box>
          ) : null
        }
        
        <Box mb={3} sx={{ width: "100%" }}>
          <TextField
            autoFocus
            label="Authentication code"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange('code')}
            sx={{ 
              width: "100%",
            }}
          />
        </Box>
        <Box mb={3} sx={{ width: "100%" }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-new-password">New Password</InputLabel>
            <OutlinedInput
              id="outlined-adornment-new-password"
              type={values.showPassword ? 'text' : 'password'}
              label="Password"
              value={values.newPassword}
              onChange={handleChange('newPassword')}
              onKeyPress={handleKeyPress}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              error={errors.newPassword !== null}
              required
            />
            <FormHelperText error>{errors.newPassword}</FormHelperText>
          </FormControl>
        </Box>
        <Button 
          type="submit"
          variant="contained" 
          fullWidth 
          size="large" 
          sx={{ color: 'white' }}
          onClick={handleSubmitNewPassword}
          disabled={
            forgotPasswordSubmitMutation.isLoading
          }
        >
          {
            forgotPasswordSubmitMutation.isLoading ? "Submitting" : "Submit"
          }
        </Button>
      </Box>
    </Box>
  );
}

export default NewPassword;