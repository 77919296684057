import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CloseIcon from '@mui/icons-material/Close';

import { useModal } from 'src/contexts';
import { useForgotPassword } from 'src/hooks';
import { Box, Button } from 'src/components/mui';
import { ModalType } from 'src/enums';

interface State {
  email: string;
}

interface Error {
  email: string | null;
}

function PasswordForget() {
  const [values, setValues] = useState<State>({
    email: ''
  });

  const [errors, setErrors] = useState<Error>({
    email: null
  });

  const { dispatch } = useModal();

  const forgotPasswordMutation = useForgotPassword();

  if(forgotPasswordMutation.isSuccess) {
    dispatch({ type: ModalType.ForgotPasswordSubmit, data: values.email });

    return null;
  }

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
      setErrors({ ...errors, [prop]: null });

      if(forgotPasswordMutation.isError) {
        forgotPasswordMutation.reset();
      }
    };

  function validateEmail(email: string | null) {
    if(!email) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Required'
      }));

      return false;
    }

    return true;
  }

  function handleSendVerificationEmail() {
    const { email } = values;

    const isEmailValid = validateEmail(email);

    if(!isEmailValid) {
      return;
    }

    forgotPasswordMutation.mutate({
      username: email
    });
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if(event.key === "Enter") {
      event.preventDefault();
      handleSendVerificationEmail()
    }
  }

  function handleErrorClose() {
    forgotPasswordMutation.reset();
  }

  return (
    <Box sx={{ p: '20px 24px' }} display="flex" justifyContent="center" alignItems="center">
      <Box
        component="form"
        autoComplete="off"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '50ch',
          padding: 1
        }}
      >
        <Box mb={2}>
          <Typography variant="h5" color="textPrimary" fontWeight={700}>Reset your password</Typography>
        </Box>
        {
          forgotPasswordMutation.isError ? (
            <Box mb={3} sx={{ width: "100%" }}>
              <Alert 
                icon={false} 
                variant="outlined"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleErrorClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                } 
                sx={{ 
                  width: "100%", 
                  color: (theme) => theme.palette.error.main,
                  borderColor: (theme) => theme.palette.error.main,
                }}
              >
                <AlertTitle>Error</AlertTitle>
                {forgotPasswordMutation.error?.message}
              </Alert>
            </Box>
          ) : null
        }
        <Box mb={1}>
          <Typography variant="body1" color="textPrimary">
            Enter your user account's verified email and we will send you a verification code.
          </Typography>
        </Box>
        
        <Box mb={3} sx={{ width: "100%" }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
            <OutlinedInput
              id="outlined-adornment-email"
              type="email"
              label="Email"
              fullWidth
              onChange={handleChange('email')}
              onKeyPress={handleKeyPress}
              error={errors.email !== null}
              required
            />
            <FormHelperText error>{errors.email}</FormHelperText>
          </FormControl>
        </Box>
        <Button 
          type="submit"
          variant="contained" 
          fullWidth 
          size="large" 
          sx={{ color: 'white' }}
          onClick={handleSendVerificationEmail}
          disabled={
            forgotPasswordMutation.isLoading
          }
        >
          {
            forgotPasswordMutation.isLoading ? "Sending" : "Send"
          }
        </Button>
      </Box>
    </Box>
  );
}

export default PasswordForget;