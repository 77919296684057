import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Amplify, { Analytics, AWSKinesisFirehoseProvider, Auth } from 'aws-amplify';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";


import '@fontsource/poppins';

import './index.less';

import { theme } from 'src/theme';
import reportWebVitals from './reportWebVitals';
import awsExports from './aws-exports';
import amplifyConfig from './amplify-config';
import { Box } from './components/mui';
import App from './App';
import Modal from 'src/parts/Modal';
import { UserAuthenticated } from 'src/components';
import { ModalProvider, NotificationProvider, LearningContextProvider } from 'src/contexts';

const history = createBrowserHistory();

Sentry.init({
  dsn: "https://4447594569364e55a8219748f9cc0a0f@o1055962.ingest.sentry.io/6042115",
  integrations: [
    new Integrations.BrowserTracing({
      //@ts-ignore
      tracingOrigins: [process.env.REACT_APP_TRACING_ORIGIN],
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    })
  ],
  environment: process.env.REACT_APP_HOST_TYPE,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // TODO: change to lower rate for production
  tracesSampleRate: 1.0,
});

Amplify.configure({
  ...awsExports,
  ...amplifyConfig
});

function configureAmplify() {
  Auth.currentAuthenticatedUser()
  .then(() => {

    renderApp(false);

  }).catch(() => {

    renderApp(true);

  });
}

configureAmplify();

function renderApp(isGuestUser: boolean) {

  const authMode = isGuestUser ? 'AWS_IAM' : 'AMAZON_COGNITO_USER_POOLS';

  Amplify.configure({
    ...awsExports,
    ...amplifyConfig,
    aws_appsync_authenticationType: authMode
  });

  Analytics.addPluggable(new AWSKinesisFirehoseProvider());

  const queryClient = new QueryClient({
    defaultOptions: {
      mutations: {
        retry: 1
      },
      queries: {
        retry: 1
      }
    }
  });

  ReactDOM.render(
    <Router history={history}>
      <React.StrictMode>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              <NotificationProvider>
                <ModalProvider>
                  <LearningContextProvider>
                    <UserAuthenticated
                      loadingRenderer={
                        <Box display="flex" width="100vw" height="100vh" justifyContent="center" alignItems="center">
                          <CircularProgress />
                        </Box>
                      }
                    >
                      <Modal />
                      <App />
                    </UserAuthenticated>
                  </LearningContextProvider>
                </ModalProvider>
              </NotificationProvider>
              {/* Excluded from production build by default */}
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </React.StrictMode>
    </Router>,
    document.getElementById('root')
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
