import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Button } from 'src/components/mui';

const StyledProfileButton = styled(Button)({
  minWidth: 36,
  width: 36,
  height: 36,
  marginLeft: '1rem',
  borderRadius: '50%',
  color: 'white'
});

const StyledMenu = styled(Menu)((props) => ({
  '& .MuiList-root': {
    background: props.theme.palette.background.default,
    minWidth: 160,
    borderRadius: 10,
    paddingTop: '0.5rem',
  },
  // Logout button
  '& li:last-of-type': {
    borderBottom: 'none'
  }
}));

const StyledMenuItem = styled(MenuItem)((props) => ({
  borderBottom: `1px solid ${props.theme.app.border.color.default}`,
  justifyContent: 'center',
  padding: '8px 12px',
  '&&:hover': {
    background: props.theme.palette.primary.main,
    color: 'white',
    '& > a': {
      color: 'white'
    }
  },
  '&.MuiMenuItem-root > a': {
    color: props.theme.palette.text.primary
  },
  '&.MuiMenuItem-root > a:hover': {
    color: 'white'
  }
}));

export {
  StyledProfileButton,
  StyledMenu,
  StyledMenuItem,
}