import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';

import * as queries from 'src/graphql/queries';

type SummaryUi = {
  date: string
  investorsHolding: string
  investorsHoldingChange: string
  symbol: string
  totalInvested: string
  totalInvestedChangePercentage: string
}
type Response = {
  data: {
    getOwnershipInstitutionalSummaryUi: SummaryUi
  }
}

async function getOwnershipInstitutionalSummaryUi(symbol: string | undefined): Promise<SummaryUi> {
  const { data: { getOwnershipInstitutionalSummaryUi }} = 
    await (API.graphql(graphqlOperation(
      queries.getOwnershipInstitutionalSummaryUi, 
      { symbol: symbol || '' }
    )) as Promise<Response>);

  return getOwnershipInstitutionalSummaryUi;
}

function useOwnershipInstitutionalSummaryUi(symbol: string | undefined): UseQueryResult<SummaryUi> {
  const options: UseQueryOptions<SummaryUi> = { 
    enabled: !!symbol,
  };

  return useQuery(
    ['getOwnershipInstitutionalSummaryUi', symbol], 
    () => getOwnershipInstitutionalSummaryUi(symbol), 
    options
  );
}

export default useOwnershipInstitutionalSummaryUi;