import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';

import { EstimatesUIResponse } from 'src/types';
import { Period, EstimatesUiQueries } from 'src/enums';

export type GetEstimatesUiResponse = {
  data: {
    [key in keyof typeof EstimatesUiQueries]: EstimatesUIResponse[]
  }
}

async function getEstimatesUi(period: Period, symbols: string[], query: string): Promise<GetEstimatesUiResponse> {
  return (API.graphql(graphqlOperation(
      query, 
      { period, symbols }
    )) as Promise<GetEstimatesUiResponse>);
}

function useEstimatesUi(period: Period, symbols: string[], query: string): UseQueryResult<GetEstimatesUiResponse> {
  const options: UseQueryOptions<GetEstimatesUiResponse> = { 
    enabled: !!period && symbols.length > 0,
  };

  return useQuery(
    [period, symbols, query],
    () => getEstimatesUi(period, symbols, query), 
    options
  );
}

export default useEstimatesUi;
