/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:a2da0d96-6ca7-43a4-8e52-4388d5dd25bb",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_89i7YQux5",
    "aws_user_pools_web_client_id": "2r7hqkug6viskq41j6hapibr1g",
    "oauth": {},
    "federationTarget": "COGNITO_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_mobile_analytics_app_id": "826861fe62fe46a49eeadd8e4fe0020a",
    "aws_mobile_analytics_app_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://toazsdmsbzbqpibiwglorwoarq.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "poodlelearningmaterialupload101715-test",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
