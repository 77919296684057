import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Hidden from '@mui/material/Hidden';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';

import { Link as RouterLink, NavLink, useLocation } from "react-router-dom";
import { Paths } from 'src/routes/paths';

import { ButtonLink } from 'src/components/mui';
import { 
  StyledHeader, 
  StyledPages,
  StyledButtonLink,
  StyledTitle,
  StyledPageTitle,
} from './styled';
import UserProfile from './UserProfile';
import Search from './Search';
import { Navigations } from 'src/routes/navigation';
import { AppBarProps } from './types';

const AppHeader = ({
  open
} : AppBarProps) => {
  const theme = useTheme();
  const { pathname } = useLocation();

  const getPageButtons = () => {
    return Navigations.map(({ label, path }) => {
      const isActive = pathname === path;

      return (
        <NavLink
          key={label}
          to={path}
          activeStyle={{ borderRadius: "0", borderBottom: `2px solid ${theme.palette.primary.main}` }}
          style={{ textDecoration: "none" }}
        >
          <StyledPageTitle isActive={isActive} variant="h6">
            {label}
          </StyledPageTitle>
        </NavLink>
      );
    });
  }

  return (
    <StyledHeader position="fixed" open={open}>
      <Container maxWidth="xl" disableGutters>
        <Toolbar disableGutters>
          <StyledButtonLink
            key={'home'}
            to={Paths.LANDING}
            component={RouterLink}
          >
            <StyledTitle variant="h4" noWrap>
              Poodle  Finance
            </StyledTitle>
          </StyledButtonLink>
          <Hidden mdDown>
            <StyledPages>
              {getPageButtons()}
            </StyledPages>
          
            <Search />
          </Hidden>
          <UserProfile />
          <Hidden mdUp>
            <ButtonLink
              key={'search'}
              to={Paths.SEARCH}
              component={RouterLink}
              sx={{ minWidth: { xs: 32 }}}
            >
              <SearchIcon sx={{ color: theme.palette.text.primary }} />
            </ButtonLink>
          </Hidden>
        </Toolbar>
      </Container>
    </StyledHeader>
  );
}

export default AppHeader;