/* 
 * Amplify Schema types
*/
export enum ModelSortDirection {
	ASC = 'ASC',
	DESC = 'DESC'
}

/* 
 * Custom types
*/
export enum LearningType {
  None = 'none',
  Insight = 'insight',
}

export enum RatingTypeEnum {
	strongBuy = "strongBuy",
  buy = "buy",
  neutral = "neutral",
  sell = "sell",
  strongSell = "strongSell"
}

export enum GroupDataType {
  Absolute = 'Absolute',
  Percentage = 'Percentage',
  Multiple = 'Multiple',
  Date = 'Date'
}
export enum GroupDataCurrency {
  USD = 'USD',
  CNY = 'CNY'
}

export enum ValuationUiKeys {
  valuationAssumptions = 'valuationAssumptions',
  valuationBrief = 'valuationBrief',
  valuationFreeCashFlow = 'valuationFreeCashFlow',
  valuationMetrics = 'valuationMetrics',
  valuationProjections = 'valuationProjections',
  valuationSharePrice = 'valuationSharePrice'
}

export enum EstimateType {
  high = 'High',
  avg = 'Average',
  low = 'Low'
}
export enum EstimatesUiKeys {
  currentEstimates = 'currentEstimates',
  historicalEstimates = 'historicalEstimates'
}
export enum EstimatesUiQueries {
  getEstimatesHistoricalUi = 'getEstimatesHistoricalUi',
  getEstimatesCurrentUi = 'getEstimatesCurrentUi'
}

export enum InsightImportance {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}

export enum Period {
  Quarterly = 'quarterly',
  Annual = 'annual'
}

export enum StockIndexEnum {
	Dow= 'Dow',
	Nasdaq= 'Nasdaq',
	Sp= 'S&P',
  Russell= 'Rusell'
}

export enum QuoteChange {
  Up = 'up',
  Down = 'down',
  Flat = 'flat'
}

export enum NumberUnit {
  M = 'M', // in million
  B = "B", // in billion
}

export enum InsightQueries {
  getInsightFinancialIncomeStatement = 'getInsightFinancialIncomeStatement',
  getInsightFinancialBalanceSheet = 'getInsightFinancialBalanceSheet',
  getInsightFinancialCashFlow = 'getInsightFinancialCashFlow',
  getInsightOverviewMarket = 'getInsightOverviewMarket',
  getInsightMatrixValuation = 'getInsightMatrixValuation',
  getInsightMatrixFinancialHealth = 'getInsightMatrixFinancialHealth',
  getInsightValuations = 'getInsightValuations',
  getInsightMatrixManagement = 'getInsightMatrixManagement',
  getInsightMatrixGrowth = 'getInsightMatrixGrowth',
  getInsightMatrixShareholderOwnership = 'getInsightMatrixShareholderOwnership',
  getInsightOwnershipInstitution = 'getInsightOwnershipInstitution',
  getInsightOwnershipInsider = 'getInsightOwnershipInsider',
}

export enum ModalType {
  None = 'none',
  SignIn = 'signIn',
  SignUp = 'signUp',
  SignUpMFA = 'signUpMfa',
  ChangePasswordMFA = 'changePasswordMfa',
  UpdateEmailMFA = 'updateEmailMfa',
  ForgotPassword = 'forgotPassword',
  ForgotPasswordSubmit = 'forgotPasswordSubmit',
  PressRelease = 'pressRelease'
}