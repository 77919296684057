import Tab from "@mui/material/Tab";
import { Link, useRouteMatch } from "react-router-dom";
import { useTheme } from '@mui/material/styles';

import { toStock } from "src/routes/navigation";
import { SidebarConfig } from './config';
import { Symbol } from 'src/types';

import {
  StyledTabs
} from './styled';

type TabsProps = {
  symbol: Symbol | undefined;
}

function TabsRouter({
  symbol
}: TabsProps) {
  const theme = useTheme();

  const path = SidebarConfig.map(({ entity }) => {
    return toStock(entity, symbol?.id);
  });

  const routeMatch = useRouteMatch(path);
  const currentTab = routeMatch?.path;

  return (
    <StyledTabs
      value={currentTab}
      variant="scrollable"
      aria-label="stock navigation tabs"
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    >
      {
        SidebarConfig.map(({ title, entity }) => {
          return (
            <Tab 
              key={title} 
              label={title} 
              value={toStock(entity, symbol?.id)} 
              to={toStock(entity, symbol?.id)} 
              component={Link} 
              style={{
                minHeight: 36,
                paddingTop: 0,
                paddingBottom: 0,
                minWidth: 100
              }}
              sx={{
                fontWeight: 600,
                "&:hover": {
                  color: theme.palette.primary.main
                }
              }}
            />
          )
        })
      }
    </StyledTabs>
  )
}

export default TabsRouter;