import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  ResponsiveContainer,
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

import { displayNumber } from "src/utils/numberUtils";
import CustomTooltip from 'src/components/charts/Tooltip';
import ResponsiveTick from './ResponsiveTick';
import { EstimateType } from 'src/enums';

/* Line Chart used by Historical Estimates, needs to be refactored to support generic data types */
function LineChart({
  data,
  children
} : {
  data: any,
  children: React.ReactNode
}) {
  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down('md'));

  const chartHeight = matchesDownSm ? 200 : 300;

  return (
    <ResponsiveContainer width="100%" height={chartHeight}>
      <ComposedChart
        data={data}
        height={chartHeight}
        barGap={1}
        margin={{
          top: 20,
          bottom: 20,
          left: 20,
          right: 20
        }}
      >
        <CartesianGrid stroke={theme.app.chart.color.grid} strokeDasharray="4 4" />
        
        <XAxis 
          dataKey='title' 
          interval={0} 
          tickLine={false}
          padding={{
            left: 20,
            right: 20
          }}
          stroke={theme.app.border.color.default}
          tick={<ResponsiveTick />}
        />
        <YAxis 
          hide
          type="number" 
          domain={['auto', 'auto']} 
          orientation="right" 
          color={theme.app.border.color.default} 
          fontSize={`${theme.typography.body2.fontSize}`}
          axisLine={false} 
          tickLine={false}
        />
        <Tooltip content={<CustomTooltip render={(payloads: any) => renderTooltip(payloads, data)} />} />
        {
          children
        }
      </ComposedChart>
    </ResponsiveContainer>
  )
}

function renderTooltip(payloads: any, chartData: any) {
  const dataKey: keyof typeof EstimateType = payloads[0].dataKey
  
  const title = payloads[0].payload.title;
  const chartItem = chartData.find((item: any) => item.title === title);

  return (
    <>
      <Typography>{title}</Typography>
      {
        // payloads is line for avg data
        payloads.map((payload: any) => {
          // const data = payload.payload;
          const value = payload.value;
          // const key = Object.keys(data).find((key: any) => data[key] === value);
          
          // TODO: Make below data type agnostic
          return (
            <React.Fragment key={value}>
              <Typography variant="body2" color='#0018F2' sx={{ textTransform: 'capitalize' }}>
                {`high estimates: `} 
                <span style={{ fontWeight: 500 }}>
                  {displayNumber({ input: chartItem.high, showUnit: true })}
                </span>
              </Typography>
              <Typography variant="body2" color={payload.color} sx={{ textTransform: 'capitalize' }}>
                {`${EstimateType[dataKey]} estimates: `} 
                <span style={{ fontWeight: 500 }}>
                  {displayNumber({ input: value, showUnit: true })}
                </span>
              </Typography>
              <Typography variant="body2" color='#FFB93B' sx={{ textTransform: 'capitalize' }}>
                {`low estimates: `} 
                <span style={{ fontWeight: 500 }}>
                  {displayNumber({ input: chartItem.low, showUnit: true })}
                </span>
              </Typography>
            </React.Fragment>
          );
        })
      }
    </>
  );
}

export default LineChart;