import Chip, { ChipProps } from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

interface CustomChipProps extends ChipProps {
  textTransform?: 'capitalize' | 'uppercase' | 'lowercase',
  isSelected?: boolean,
}

export type StyledChipProps = CustomChipProps;

const StyledChip = styled(
  ({ textTransform, ...otherProps}: StyledChipProps) => (<Chip {...otherProps} />)
)(({ theme, textTransform }) => {
  
  return {
    textTransform: textTransform || 'none',
    borderRadius: theme.app.borderRadius.chipPrimary,
    fontSize: '1rem',

    '& .MuiChip-label': {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    }
  }
});

const StyledChipSecondary = styled(StyledChip)(({ theme, textTransform }) => ({
  borderRadius: theme.app.borderRadius.chipSecondary,
  textTransform: textTransform || 'none',
}));

const MuiChipPrimary = (props: CustomChipProps) => {

  return (
    <StyledChip size="small" {...props} />
  );
}

const MuiChipSecondary = (props: CustomChipProps) => {

  return (
    <StyledChipSecondary size="small" {...props} />
  );
}

const PeriodChip = (props: CustomChipProps) => {
  const { label, isSelected, ...restProps } = props;

  return (
    <MuiChipPrimary 
      size="small"
      {...restProps}
      label={label}
      textTransform='capitalize'
      sx={{ 
        ...props.sx,
        border: '1px solid',
        borderRadius: '2px', 
        borderColor: (theme) => isSelected ? theme.palette.primary.main : theme.palette.text.disabled,
        background: isSelected ? "#DADBFF" : "inherit",
        color: (theme) => isSelected ? theme.palette.primary.main : theme.palette.text.disabled,
        '&:hover': { cursor: 'pointer' }
      }} 
    />
  )
}

export {
  MuiChipPrimary,
  MuiChipSecondary,
  PeriodChip,
}
