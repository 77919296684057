import {
} from "recharts";

import {
  CardHeaderDefault,
  Box,
	BoxPrimary,
} from 'src/components/mui';

import {
	StyledCardContent,
} from 'src/components/mui/CardContent';
import StockIndex from './StockIndex';
import SlideTrack from 'src/components/slideTrack';
import { SkeletonLoader } from 'src/components';

import { useQuotes } from 'src/hooks';
import { StockIndices } from 'src/contants';

const Markets = () => {

  const etfSymbols = StockIndices.map(({ etf }) => etf);

  const { data: realtimeQuotes, isLoading, isSuccess, isFetched } = useQuotes(etfSymbols);

  if(isLoading || !isFetched) {
    return (
      <BoxPrimary mb={2}>
        <CardHeaderDefault title="Markets" />
        <StyledCardContent>
          <SkeletonLoader noOfLoader={3} height={70} />
        </StyledCardContent>
      </BoxPrimary>
    );
  }

  return isSuccess ? (
    <BoxPrimary mb={2}>
      <CardHeaderDefault title="Markets" />
      <StyledCardContent>
        <SlideTrack>
          {
            StockIndices.map(({ name, etf }) => {
              const realtimeQuote = realtimeQuotes?.find((quote) => quote.symbol === etf);

              return realtimeQuote ? (
                <Box mr={1} flexGrow={1} key={etf} sx={{ width: 250 }}>
                  <StockIndex name={name} realtimeQuote={realtimeQuote} />
                </Box>
              ) : null;
            })
          }
          {
            StockIndices.map(({ name, etf }) => {
              const realtimeQuote = realtimeQuotes?.find((quote) => quote.symbol === etf);

              return realtimeQuote ? (
                <Box mr={1} flexGrow={1} key={etf} sx={{ width: 250 }}>
                  <StockIndex name={name} realtimeQuote={realtimeQuote} />
                </Box>
              ) : null;
            })
          }
        </SlideTrack>
      </StyledCardContent>
    </BoxPrimary>
  ) : null
}

export default Markets;