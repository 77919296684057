import Tabs from "@mui/material/Tabs";
import { styled } from "@mui/styles";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '&&': {
    '& .MuiTabScrollButton-root': {
      width: 20
    },
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      bottom: 'auto'
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  }
}));

export {
  StyledTabs
}

