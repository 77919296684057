import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Legend
} from "recharts";
import { Payload } from "recharts/types/component/DefaultLegendContent";

export default function HorizontalStackBarChart({
  data,
  legendPayload,
  hideX = true,
  hideY = true,
  chartHeight,
  children,
} : {
  data: any,
  legendPayload?: Payload[]
  hideX?: boolean,
  hideY?: boolean,
  chartHeight: number,
  children: React.ReactNode
}) {
  const theme = useTheme();

  return (
    <ResponsiveContainer width="100%" height={chartHeight}>
      <BarChart
        layout="vertical"
        stackOffset="silhouette"
        height={chartHeight}
        data={data}
        margin={{
          top: 5,
        }}
      >
        <XAxis type="number" hide={hideX} />
        <YAxis type="category" hide={hideY} />
        <Legend verticalAlign="bottom" payload={legendPayload} fontFamily={theme.app.chart.font.family} />
        {
          children
        }
      </BarChart>
    </ResponsiveContainer>
    
  );
}
