import Typography from "@mui/material/Typography";

import {
  Grid,
  Box,
  Button
} from 'src/components/mui';
import TabsRouter from './navigation/Tabs';
import { SkeletonLoader } from "src/components";

import { useUser, useFollow, useMarketQuotes } from "src/hooks";
import { useCognitoUser } from 'src/contexts';
import { renderTags } from "./renders";
import { getUserFollowId } from "src/utils/userUtils";
import { displayNumber, displayPercentage } from "src/utils/numberUtils";
import { displayString } from "src/utils/stringUtils";
import { displayTime } from "src/utils/dateUtils";
import { Symbol } from "src/types";

function StockSummary({
  symbol,
  symbolId
} : {
  symbolId: string;
  symbol: Symbol | undefined
}) {
  const { authenticatedUser } = useCognitoUser();
  const { data: user } = useUser(authenticatedUser?.username);

  const userFollowId = getUserFollowId(user, symbolId);

  const { handleUnfollow, handleFollow } = useFollow({
    userid: user?.id,
    userFollowId,
    symbolId: symbolId,
  });

  const symbols = symbol?.symbol ? [symbol.symbol] : [];

  const {
    realTimeQuoteRef,
    realtimeQuote,
    latestUpdateDate,
    changeColor,
    changeColorBg,
    isFetched
   } = useMarketQuotes(symbols);

  const tags = symbol?.company.tags || "[]";

  return (
    <Grid container item>
      <Grid item container xs={12} sm={12} pb={0.5} justifyContent="space-between">
        <Grid item xs={7} sm={6}>
          <Box pl={2} pr={2}>
            <Typography variant="h5" color="textSecondary" align="left">
              { `${symbol?.symbol} - ${symbol?.company.companyName}` }
            </Typography>
            { renderTags(tags) }
          </Box>
        </Grid>
        <Grid item xs={4} sm={3}>
          <Box pl={2} pr={2} display="flex" justifyContent="flex-end">
            {
              userFollowId ? (
                <Button 
                  variant="contained" 
                  size="medium" 
                  fullWidth 
                  sx={(theme) => ({ color: 'white', maxWidth: theme.app.button.maxWidth })} 
                  onClick={handleUnfollow}
                >
                  Followed
                </Button>
              ) : (
                <Button
                  variant="contained" 
                  size="medium" 
                  fullWidth 
                  sx={(theme) => ({ color: 'white', maxWidth: theme.app.button.maxWidth })} 
                  onClick={handleFollow}
                >
                  Follow
                </Button>
              )
            }
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} pl={1}>
        {
          !isFetched ? (
            <SkeletonLoader variant="text" width="100px" noOfLoader={2} isColumn={true} />
          ) : (
            <>
              <Box 
                display="flex" 
                p={1} 
                alignItems="flex-end" 
                borderRadius={1}
                bgcolor={changeColorBg}
                style={{ width: "fit-content", transition: "all ease 0.5" }}
                ref={realTimeQuoteRef}
              >
                <Typography variant="h6" style={{ lineHeight: 1.3 }}>
                  <Box color={changeColor} mr={1}>
                    { `${displayNumber({ input: realtimeQuote?.latestPrice })} ${displayString(realtimeQuote?.currency)}` }
                  </Box>
                </Typography>
                <Typography variant="subtitle2">
                  <Box color={changeColor} mr={1}>
                    { `${Number(realtimeQuote?.change) > 0 ? '+ ' : ''}${displayNumber({ input: realtimeQuote?.change })} (${displayPercentage({ input: realtimeQuote?.changePercent })})` }
                  </Box>
                </Typography>
              </Box>
              <Box mb={1} pl={1}>
                <Typography variant="caption">
                  <Box color="text.secondary">
                    { `${displayTime(realtimeQuote?.latestUpdate)} ${Intl.DateTimeFormat().resolvedOptions().timeZone} ${latestUpdateDate} (15 minutes delayed)` }
                  </Box>
                </Typography>
              </Box>
            </>
          )
        }
        
      </Grid>

      <Grid item xs={12} sm={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
        <Box pr={1}>
          <TabsRouter symbol={symbol} />
        </Box>
      </Grid>
    </Grid>
  )
}

export default StockSummary;