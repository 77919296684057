import { useTheme  } from '@mui/material/styles';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  YAxis
} from "recharts";
import { ChartRange } from 'src/types';

function StockLineChart({
  data,
  range,
  chartHeight,
} : {
  data: any,
  range: ChartRange,
  chartHeight: number
}) {
  const theme = useTheme();

  const lineDataKey = 'close';

  return (
    <ResponsiveContainer width="100%" height={chartHeight}>
      <LineChart
        data={data}
        height={chartHeight}
        barGap={1}
        margin={{
          top: 10,
          left: 10,
          bottom: 10,
        }}
      >
        <YAxis hide={true} axisLine={false} tick={false} tickLine={false} domain={['dataMin', 'dataMax']} />
        <Line connectNulls dot={false} dataKey={getDataKey(lineDataKey)} stroke={theme.palette.primary.main} isAnimationActive={false} strokeWidth={1.5} />
      </LineChart>
    </ResponsiveContainer>
  )
}

// Convert string value to number so that min and max values can be calculated by rechart domain correctly
function getDataKey(lineDataKey: 'close') {
  return function(data: any) {
    return data[lineDataKey] ? +data[lineDataKey] : null;
  }
}

export default StockLineChart;