import { useMutation, UseMutationResult } from 'react-query';
import { Auth } from 'aws-amplify';

type ResendSignUp = {
  username: string; 
}

type Error = {
  message: string;
  __type: string;
}

function resendSignUp(username: string): Promise<any> {
  return Auth.resendSignUp(username);
}

/* get the cognito authenticated user */
function useResendSignUp(): UseMutationResult<unknown, Error, ResendSignUp, unknown> {
  return useMutation<unknown, Error, ResendSignUp, unknown>(
    ({ username }) => resendSignUp(username)
  );
}

export default useResendSignUp;