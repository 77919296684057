import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const ResponsiveTick = (props: any) => {
  const { 
    x, y, payload, renderer
  } = props;

  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down('md'));
  const fontSize = matchesDownSm ? 10 : 12;

  return (
      <text
        x={x}
        y={y}
        dy={8}
        textAnchor="middle"
        fill={theme.palette.text.secondary}
        fontSize={fontSize}
        fontWeight={500}
        fontFamily={theme.app.chart.font.family}
        letterSpacing={matchesDownSm ? '-1px' : 'normal'}
      >
        {renderer ? renderer(payload) : payload.value}
      </text>
  )
};

export default ResponsiveTick;