import Skeleton, { SkeletonProps } from '@mui/material/Skeleton';
import { BoxProps } from '@mui/material/Box';

import {
  Box,
} from 'src/components/mui';

type DefaultProps = BoxProps & SkeletonProps;

interface SkeletonLoaderProps extends DefaultProps {
  noOfLoader?: number,
  isColumn?: boolean,
  height?: number, // required if variant is 'rectangular'
}

function SkeletonLoader({
  variant = 'rectangular',
  width = "100%",
  height,
  isColumn = false,
  noOfLoader = 1
} : SkeletonLoaderProps) {

  const loaders = Array.from(Array(noOfLoader).keys());

  const itemProps: BoxProps = isColumn ? {
    mb: 1
  } : {
    mr: 1
  }

  return (
    <Box display="flex" flexDirection={isColumn ? 'column' : 'row'}>
      {
        loaders.map((index) => (
          <Box key={index} {...itemProps} flexGrow={1}>
            <Skeleton variant={variant} width={width} height={height} />
          </Box>
        ))
      }
    </Box>
  )
}

export default SkeletonLoader;