import * as React from 'react';
import { CognitoUser } from 'src/types';

type UserProviderProps = { children: React.ReactNode, value: { authenticatedUser: CognitoUser | null } };

const AuthenticatedUserContext = React.createContext<
  { authenticatedUser: CognitoUser | null } | undefined
>(undefined);


function UserProvider({
  children,
  value
}: UserProviderProps
) {
  
  return (
    <AuthenticatedUserContext.Provider value={value}>
      {children}
    </AuthenticatedUserContext.Provider>
  );
}

function useCognitoUser() {
  const context = React.useContext(AuthenticatedUserContext);

  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
}
export { UserProvider, useCognitoUser }