import { useTheme } from '@mui/material/styles';
import './index.less';

function LearningPopperAnchor() {
  const theme = useTheme();

  return (
    <div id="learning-popper" style={{ top: theme.app.fixedTop.mdUp }} />
  );
}

export default LearningPopperAnchor;