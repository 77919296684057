import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Hidden from '@mui/material/Hidden';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  CardSecondary,
  ChipPrimary
} from 'src/components/mui';
import { StyledCardContent } from 'src/components/mui/CardContent';
import {
  StyledFeedHighlightsContainer,
  StyledMeta,
  StyledFeedTitle
} from 'src/components/feed/styled';
import { StockPathEntity } from 'src/routes/paths';
import { toStock } from 'src/routes/navigation';
import { displayDateLocale } from 'src/utils/dateUtils';

function Feed({
  url,
  title,
  symbolId,
  symbol,
  text,
  site,
  publishedDate,
  image
} : {
  url: string;
  title: string;
  symbolId: string;
  symbol: string;
  text: string;
  site: string;
  publishedDate: string;
  image: string
}){
  return (
    <CardSecondary sx={{ height: '100%', display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flexDirection="column" pl={1} pr={1}>
          <Link href={url} target="_blank" underline="none" rel="noopener">
            <StyledFeedTitle title={title} />  
          </Link>
          <StyledFeedHighlightsContainer>
            <Link 
              component={RouterLink} 
              to={toStock(StockPathEntity.Overview, symbolId)}
              underline="none"
            >
              <ChipPrimary color="warning" label={symbol} variant="outlined" sx={{ cursor: "pointer" }} />
            </Link>
          </StyledFeedHighlightsContainer>
        </Box>
        <Hidden only="xs">
          <CardMedia
            component="img"
            sx={{ width: 186, height: 120 }}
            image={image}
          />
        </Hidden>
        <Hidden smUp>
          <CardMedia
            component="img"
            sx={{ width: 93, height: 60 }}
            image={image}
          />
        </Hidden>
      </Box>
      <Hidden only="xs">
        <StyledCardContent>
          <Typography variant="body1" color="textSecondary" component="p">
            {text}
          </Typography>
        </StyledCardContent>
      </Hidden>
      <Hidden smUp>
        <StyledCardContent sx={{ pl: 1, pr: 1 }}>
          <Typography variant="body1" color="textSecondary" component="p">
            {text}
          </Typography>
        </StyledCardContent>
      </Hidden>
      <StyledMeta pt={1} pl={1} pr={1}>
        <Typography variant="body2">{displayDateLocale(publishedDate)}</Typography>
        <Typography variant="body2">{site}</Typography>
      </StyledMeta>
    </CardSecondary>
  )
}

export default Feed;