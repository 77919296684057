import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import InputBase from '@mui/material/InputBase';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { ButtonLink } from 'src/components/mui';
import { AppBarProps } from './types';

type PageTitleProps = {
  isActive: boolean
} 

type StyledPageTitleProps = PageTitleProps & TypographyProps;

const StyledHeader = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  background: theme.app.background.cardPrimary,
  boxShadow: 'none',
}));

const StyledPages = styled('div')({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'row',
  marginLeft: 20,
  '& > a': {
    marginLeft: 20,
  }
});

const StyledButtonLink = styled(ButtonLink)((props) => ({
  "&&": {
    [props.theme.breakpoints.down('md')]: {
      flexGrow: 1,
      justifyContent: 'left',
    },
  }
}));

const StyledTitle = styled(Typography)((props) => ({
  "&&": {
    color: props.theme.palette.primary.main,
    textTransform: 'none',
    fontWeight: props.theme.typography.fontWeightBold,
  
    [props.theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem'
    },
  },

}));

const StyledPageTitle = styled(
  ({ isActive, ...otherProps }: StyledPageTitleProps) => (<Typography {...otherProps} />)
)(({ isActive, theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightBold,
  color: isActive ? theme.palette.primary.main : theme.palette.text.primary,
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const StyledSearch = styled('div')((props) => ({
  position: 'relative',
  borderRadius: 16,
  backgroundColor: 'rgba(236, 237, 246, 1)',
  '&:hover': {
    backgroundColor: alpha('rgba(236, 237, 246, 1)', 0.7),
  },
  marginLeft: 0,
  width: '100%',
  [props.theme.breakpoints.up('sm')]: {
    marginLeft: props.theme.spacing(1),
    width: 'auto',
  },
}));

const StyledSearchIcon = styled('div')((props) => ({
  padding: props.theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: props.theme.palette.text.primary,
}));

const StyledSearchInput = styled(InputBase)((props) => ({
  padding: props.theme.spacing(1, 1, 1, 0),
  // vertical padding + font size from searchIcon
  paddingLeft: `calc(1em + ${props.theme.spacing(4)}px)`,
  transition: props.theme.transitions.create('width'),
  width: '100%',
  [props.theme.breakpoints.up('sm')]: {
    width: '20ch',
    '&.Mui-focused': {
      width: '30ch',
    },
  },
}));


export {
  StyledHeader,
  StyledPages,
  StyledSearch,
  StyledButtonLink,
  StyledTitle,
  StyledPageTitle,
  StyledSearchIcon,
  StyledSearchInput,
}