import Typography from "@mui/material/Typography";

import InsightContainer from "src/components/insight/InsightContainer";
import { useInsights } from "src/hooks";
import * as queries from 'src/graphql/queries';
import { Box, BoxSecondary } from "src/components/mui";
import { InsightImportance } from "src/enums";

function Insights({
  symbolName
} : {
  symbolName: string | undefined
}) {
  const symbols = symbolName ? [symbolName] : [];

  const { 
    data: valuationData, 
    isFetched: isValuationFetched, 
    isLoading: isValuationLoading, 
    isSuccess: isValuationSuccess 
  } = useInsights(symbols, queries.getInsightMatrixValuation);
  const valuationImportantInsights = valuationData?.data?.getInsightMatrixValuation
    ?.find(({ symbol: eachSymbol }) => eachSymbol === symbolName)?.insights
    ?.filter(({ importance }) => importance === InsightImportance.High) || [];

  const { 
    data: financialHealthData, 
    isFetched: isFinancialHealthFetched, 
    isLoading: isFinancialHealthLoading, 
    isSuccess: isFinancialHealthSuccess 
  } = useInsights(symbols, queries.getInsightMatrixFinancialHealth);
  const financialHealthImportantInsights = financialHealthData?.data?.getInsightMatrixFinancialHealth
    ?.find(({ symbol: eachSymbol }) => eachSymbol === symbolName)?.insights
    ?.filter(({ importance }) => importance === InsightImportance.High) || [];

  const { 
    data: growthData, 
    isFetched: isGrowthFetched, 
    isLoading: isGrowthLoading, 
    isSuccess: isGrowthSuccess 
  } = useInsights(symbols, queries.getInsightMatrixGrowth);
  const growthImportantInsights = growthData?.data?.getInsightMatrixGrowth
    ?.find(({ symbol: eachSymbol }) => eachSymbol === symbolName)?.insights
    ?.filter(({ importance }) => importance === InsightImportance.High) || [];

  const { 
    data: ownershipData, 
    isFetched: isOwnershipFetched, 
    isLoading: isOwnershipLoading, 
    isSuccess: isOwnershipSuccess 
  } = useInsights(symbols, queries.getInsightMatrixShareholderOwnership);
  const ownershipImportantInsights = ownershipData?.data?.getInsightMatrixShareholderOwnership
    ?.find(({ symbol: eachSymbol }) => eachSymbol === symbolName)?.insights
    ?.filter(({ importance }) => importance === InsightImportance.High) || [];

  const isLoading = isValuationLoading || isFinancialHealthLoading || isGrowthLoading || isOwnershipLoading;
  const isSuccess = isValuationSuccess || isFinancialHealthSuccess || isGrowthSuccess || isOwnershipSuccess;
  const isFetched = isValuationFetched && isFinancialHealthFetched && isGrowthFetched && isOwnershipFetched;

  const keyInsights = [
    ...valuationImportantInsights,
    ...financialHealthImportantInsights,
    ...growthImportantInsights,
    ...ownershipImportantInsights
  ]

  return (
    <BoxSecondary>
      <Box mb={2}>
        <Typography variant="h6" color="textSecondary">Key Insights</Typography>
      </Box>
      <InsightContainer 
        insights={keyInsights}
        isLoading={isLoading}
        isSuccess={isSuccess}
        isFetched={isFetched}
      />
    </BoxSecondary>
  )
}

export default Insights;