import {
  Box,
} from 'src/components/mui';
import IncomeStatement from './IncomeStatement';
import BalanceSheet from './BalanceSheet';
import Cashflow from './Cashflow';

function Financials({
  symbol
} : {
  symbol: string | undefined
}) {

  const symbols = symbol ? [symbol] : [];

  return (
    <Box style={{ width: '100%' }} >
      <IncomeStatement symbols={symbols} />
      <BalanceSheet symbols={symbols} />
      <Cashflow symbols={symbols} />
    </Box>
  );
}

export default Financials;