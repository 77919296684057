import { MouseEvent } from 'react';

import { useVerifyCurrentUserAttributeSubmit, useVerifyCurrentUserAttribute } from 'src/hooks';

function useUpdateEmailMfa({
  email, 
}: {
  email: string;
}) : {
  handleConfirm: (code: string) => void;
  handleResend: (event: MouseEvent<HTMLButtonElement>) => void;
  handleErrorClose: (event: MouseEvent<HTMLButtonElement>) => void;
  isConfirmLoading: boolean;
  isResendLoading: boolean;
  isError: boolean;
  shouldClose: boolean;
  hint: string;
  errorMsg?: string;
  reloadOnClose?: boolean;
} {

  const verifyCurrentUserAttributeSubmitMutation = useVerifyCurrentUserAttributeSubmit();
  const verifyCurrentUserAttributeMutation =  useVerifyCurrentUserAttribute();
  
  const hint = `You're almost done! We just sent the code to ${email}`;

  function handleErrorClose() {
    verifyCurrentUserAttributeSubmitMutation.reset();
    verifyCurrentUserAttributeMutation.reset();
  }

  function handleResend() {
    verifyCurrentUserAttributeMutation.mutate('email');
  }

  function handleConfirm(code: string) {
    verifyCurrentUserAttributeSubmitMutation.mutate({
      attr: 'email',
      code
    });
  }

  return {
    handleConfirm,
    handleResend,
    handleErrorClose,
    isConfirmLoading: verifyCurrentUserAttributeSubmitMutation.isLoading,
    isResendLoading: verifyCurrentUserAttributeMutation.isLoading,
    isError: verifyCurrentUserAttributeSubmitMutation.isError || verifyCurrentUserAttributeMutation.isError,
    shouldClose: verifyCurrentUserAttributeSubmitMutation.isSuccess,
    hint,
    errorMsg: verifyCurrentUserAttributeSubmitMutation.error?.message || verifyCurrentUserAttributeMutation.error?.message,
    reloadOnClose: false
  }
}

export default useUpdateEmailMfa;