import React from 'react';

import { useTheme  } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import {
  ResponsiveContainer,
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import Typography from '@mui/material/Typography';

import AxisTick from './AxisTick';
import CustomTooltip from 'src/components/charts/Tooltip';
import { ChartRange } from 'src/types';
import { displayString } from 'src/utils/stringUtils';

function StockChart({
  data,
  range,
  children,
  xAxisDataKey
} : {
  data: any,
  range: ChartRange,
  children: React.ReactNode,
  xAxisDataKey?: string,
}) {
  let isTradingHour = true;
  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down('md'));

  const xAxisKey = xAxisDataKey ?? "date";
  
  const chartHeight = matchesDownSm ? 200 : 300;
  
  if(data?.length) {
    const dateFromData = new Date(data[data.length - 1].date).getDate();
    const todayEstDay = new Date(new Date().toLocaleDateString("en-US", {
      timeZone: "America/New_York" 
    })).getDate();
    
    isTradingHour = dateFromData === todayEstDay;
  }

  return (
    <ResponsiveContainer width="100%" height={chartHeight}>
      <ComposedChart
        data={data}
        height={chartHeight}
        barGap={1}
        margin={{
          top: 20,
          bottom: 20,
          left: 20
        }}
      >
        <CartesianGrid stroke={theme.app.chart.color.grid} strokeDasharray="3" vertical={false} />
        <XAxis 
          dataKey={xAxisKey} 
          interval={0} 
          tick={<AxisTick range={range} dataLength={data?.length} isTradingHour={isTradingHour} />} 
          stroke={theme.app.border.color.default}
          tickLine={false}
        />
        <YAxis 
          type="number"
          domain={[(dataMin: any) => Math.floor(dataMin), (dataMax: any) => Math.ceil(dataMax)]}  
          orientation="right" 
          color={theme.app.border.color.default} 
          fontSize={`${theme.typography.body2.fontSize}`}
          fontFamily={theme.app.chart.font.family}
          axisLine={false} 
          tickLine={false}
        />
        <Tooltip content={<CustomTooltip render={renderTooltip} />} />
        { children }
      </ComposedChart>
    </ResponsiveContainer>
  )
}

function renderTooltip(payloads: any) {
  const payload = payloads[0].payload;
  const dateTime = payload.minute ? `${payload.date} ${payload.minute}` : payload.date;

  return (
    <>
      <Typography>{dateTime}</Typography>
      {
        payloads.map((payload: any) => {
          const data = payload.payload;
          const value = payload.value;
          let symbol = data.symbol;
          
          if(payloads.length > 1) {
            // price key is formatted as `${symbol}Price`
            const priceKey = Object.keys(data).find(key => data[key] === value);
            symbol = priceKey?.slice(0, -5);
          }
          return (
            <React.Fragment key={`${symbol}${dateTime}`}>
              <Typography>{`${displayString(symbol)}: `}<span style={{ fontWeight: 600 }}>{value}</span></Typography>
            </React.Fragment>
          );
        })
      }
    </>
  );
}

export default StockChart;