import useUserFollow from './user/useUserFollow';
import { useModal } from "src/contexts";
import { ModalType } from 'src/enums';

type UseFollowArgs = {
  userid: string | undefined;
  userFollowId: string | undefined;
  symbolId: string | undefined;
}

function useFollow({
  userid, 
  userFollowId,
  symbolId,
} : UseFollowArgs) {
  const { follow, unfollow } = useUserFollow(userid);
  const { dispatch: dispatchModal } = useModal();

  function handleUnfollow(event: React.ChangeEvent<{}>) {
    if(userFollowId) {
      unfollow.mutate(userFollowId);
    }
    // required when follow in search option
    event.stopPropagation();
  }

  function handleFollow(event: React.ChangeEvent<{}>) {
    if(!userid) {
      dispatchModal({ type: ModalType.SignIn });
      return;
    }
  
    follow.mutate({
      userID: userid, 
      symbolID: symbolId
    });
    // required when follow in search option and user logged in
    event.stopPropagation();
  }

  return {
    handleUnfollow,
    handleFollow
  }
}

export default useFollow;