import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Typography from "@mui/material/Typography";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CloseIcon from '@mui/icons-material/Close';

import { useModal } from 'src/contexts';
import { useSignUp } from 'src/hooks';
import { Box, Button } from 'src/components/mui';
import { ModalType } from 'src/enums';

interface State {
  email: string;
  password: string;
  showPassword: boolean;
}

interface Error {
  email: string | null;
  password: string | null;
}

function SignUp() {
  const [values, setValues] = useState<State>({
    email: '',
    password: '',
    showPassword: false,
  });

  const [errors, setErrors] = useState<Error>({
    email: null,
    password: null,
  });

  const { dispatch } = useModal();

  const signUpMutation = useSignUp();

  if(signUpMutation.isSuccess) {
    dispatch({ 
      type: ModalType.SignUpMFA, 
      data: { 
        username: values.email,
        password: values.password,
      } 
    });
    return null;
  }

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
      setErrors({ ...errors, [prop]: null });

      if(signUpMutation.isError) {
        signUpMutation.reset();
      }
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickSignIn = () => {
    dispatch({ type: ModalType.SignIn });
  }

  function validateEmail(email: string | null) {
    if(!email) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Required'
      }));

      return false;
    }

    return true;
  }

  function validatePassword(password: string | null) {
    if(!password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: 'Required'
      }));

      return false;
    }

    return true;
  }

  function handleSignUp() {
    const { email, password } = values;

    const isEmailValid = validateEmail(email);
    const isPasswordValid = validatePassword(email);

    if(!isEmailValid || !isPasswordValid) {
      return;
    }

    signUpMutation.mutate({
      email,
      password
    });
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if(event.key === "Enter") {
      handleSignUp()
    }
  }

  function handleErrorClose() {
    signUpMutation.reset();
  }

  return (
    <Box sx={{ p: '20px 24px' }} display="flex" justifyContent="center" alignItems="center">
      <Box
        component="form"
        autoComplete="off"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '50ch',
          padding: 1
        }}
      >
        <Box>
          <Typography variant="h5" color="textPrimary" fontWeight={700}>Create Free Account</Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body2" color="textPrimary">
            Already have an account? 
            <Button 
              variant="text" 
              sx={{
                '&:hover': {
                  backgroundColor: 'inherit'
                }
              }}
              onClick={handleClickSignIn}
            >
              Sign in
            </Button>
          </Typography>
        </Box>

        {
          signUpMutation.isError ? (
            <Box mb={3} sx={{ width: "100%" }}>
              <Alert 
                icon={false} 
                variant="outlined"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleErrorClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                } 
                sx={{ 
                  width: "100%", 
                  color: (theme) => theme.palette.error.main,
                  borderColor: (theme) => theme.palette.error.main,
                }}
              >
                <AlertTitle>Error</AlertTitle>
                {signUpMutation.error?.message}
              </Alert>
            </Box>
          ) : null
        }
        
        <Box mb={3} sx={{ width: "100%" }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
            <OutlinedInput
              id="outlined-adornment-email"
              type="email"
              label="Email"
              fullWidth
              onChange={handleChange('email')}
              error={errors.email !== null}
              required
            />
            <FormHelperText error>{errors.email}</FormHelperText>
          </FormControl>
        </Box>
        <Box mb={3} sx={{ width: "100%" }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={values.showPassword ? 'text' : 'password'}
              label="Password"
              value={values.password}
              onChange={handleChange('password')}
              onKeyPress={handleKeyPress}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              error={errors.password !== null}
              required
            />
            <FormHelperText error>{errors.password}</FormHelperText>
          </FormControl>
        </Box>
        <Button
          type="submit"
          variant="contained" 
          fullWidth 
          size="large" 
          sx={{ color: 'white' }}
          onClick={handleSignUp}
          disabled={
            signUpMutation.isLoading
          }
        >
          {
            signUpMutation.isLoading ? "Signing up" : "Sign up"
          }
        </Button>
      </Box>
    </Box>
  );
}

export default SignUp;