/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUserSearch = `
  mutation CreateUserSearch(
    $input: CreateUserSearchInput!
  ) {
    createUserSearch(input: $input) {
      id
      userID
      symbolID
    }
  }
`;

export const createUserFollow = `
  mutation CreateUserFollow(
    $input: CreateUserFollowInput!
  ) {
    createUserFollow(input: $input) {
      id
      userID
      symbolID
    }
  }
`;

export const deleteUserFollow = `
  mutation DeleteUserFollow(
    $input: DeleteUserFollowInput!
  ) {
    deleteUserFollow(input: $input) {
      id
      userID
      symbolID
    }
  }
`;

export const createIncomeStatement = /* GraphQL */ `
  mutation CreateIncomeStatement(
    $input: CreateIncomeStatementInput!
    $condition: ModelIncomeStatementConditionInput
  ) {
    createIncomeStatement(input: $input, condition: $condition) {
      id
      companyId
      costOfRevenue
      currency
      ebit
      fillingType
      fiscalDate
      fiscalQuater
      fiscalYear
      grossProfit
      incomeTax
      interestIncome
      isSandbox
      minorityInterest
      netIncome
      netIncomeBasic
      operatingExpense
      createdAt
      updatedAt
    }
  }
`;
export const updateIncomeStatement = /* GraphQL */ `
  mutation UpdateIncomeStatement(
    $input: UpdateIncomeStatementInput!
    $condition: ModelIncomeStatementConditionInput
  ) {
    updateIncomeStatement(input: $input, condition: $condition) {
      id
      companyId
      costOfRevenue
      currency
      ebit
      fillingType
      fiscalDate
      fiscalQuater
      fiscalYear
      grossProfit
      incomeTax
      interestIncome
      isSandbox
      minorityInterest
      netIncome
      netIncomeBasic
      operatingExpense
      createdAt
      updatedAt
    }
  }
`;
export const deleteIncomeStatement = /* GraphQL */ `
  mutation DeleteIncomeStatement(
    $input: DeleteIncomeStatementInput!
    $condition: ModelIncomeStatementConditionInput
  ) {
    deleteIncomeStatement(input: $input, condition: $condition) {
      id
      companyId
      costOfRevenue
      currency
      ebit
      fillingType
      fiscalDate
      fiscalQuater
      fiscalYear
      grossProfit
      incomeTax
      interestIncome
      isSandbox
      minorityInterest
      netIncome
      netIncomeBasic
      operatingExpense
      createdAt
      updatedAt
    }
  }
`;
export const createBalanceSheet = /* GraphQL */ `
  mutation CreateBalanceSheet(
    $input: CreateBalanceSheetInput!
    $condition: ModelBalanceSheetConditionInput
  ) {
    createBalanceSheet(input: $input, condition: $condition) {
      id
      accountsPayable
      commonStock
      companyId
      currency
      currentAssets
      currentCash
      fillingType
      fiscalDate
      fiscalQuater
      fiscalYear
      goodwill
      intangiableAssets
      inventory
      isSandbox
      longTermDebt
      longTermInvestments
      minorityInterest
      createdAt
      updatedAt
    }
  }
`;
export const updateBalanceSheet = /* GraphQL */ `
  mutation UpdateBalanceSheet(
    $input: UpdateBalanceSheetInput!
    $condition: ModelBalanceSheetConditionInput
  ) {
    updateBalanceSheet(input: $input, condition: $condition) {
      id
      accountsPayable
      commonStock
      companyId
      currency
      currentAssets
      currentCash
      fillingType
      fiscalDate
      fiscalQuater
      fiscalYear
      goodwill
      intangiableAssets
      inventory
      isSandbox
      longTermDebt
      longTermInvestments
      minorityInterest
      createdAt
      updatedAt
    }
  }
`;
export const deleteBalanceSheet = /* GraphQL */ `
  mutation DeleteBalanceSheet(
    $input: DeleteBalanceSheetInput!
    $condition: ModelBalanceSheetConditionInput
  ) {
    deleteBalanceSheet(input: $input, condition: $condition) {
      id
      accountsPayable
      commonStock
      companyId
      currency
      currentAssets
      currentCash
      fillingType
      fiscalDate
      fiscalQuater
      fiscalYear
      goodwill
      intangiableAssets
      inventory
      isSandbox
      longTermDebt
      longTermInvestments
      minorityInterest
      createdAt
      updatedAt
    }
  }
`;
