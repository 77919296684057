import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';

import * as queries from 'src/graphql/queries';
import { TRealTimeQuoteFull } from 'src/types';

type GetSymbolsResponse = {
  data: {
    getRealTimeQuoteFull: TRealTimeQuoteFull[]
  }
}

async function getQuotesBySymbols(symbols: string[] | undefined): Promise<TRealTimeQuoteFull[]> {
  const { data: { getRealTimeQuoteFull }} = 
    await (API.graphql(graphqlOperation(queries.getRealTimeQuoteFull,
      { symbols }
    )) as Promise<GetSymbolsResponse>);

  return getRealTimeQuoteFull;
}

/* get symbol from Symbol table */
function useQuotes(symbols: string[] | undefined): UseQueryResult<TRealTimeQuoteFull[]> {
  const quotesOptions: UseQueryOptions<TRealTimeQuoteFull[]> = { 
    enabled: !!symbols && symbols.length > 0,
    refetchInterval: 15000,
  };

  return useQuery(
      ['quotes', symbols], 
      () => getQuotesBySymbols(symbols), 
      quotesOptions
    );
}

export default useQuotes;