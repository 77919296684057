import React from 'react';
import Card, { CardProps } from '@mui/material/Card';

import { styled } from '@mui/material/styles';

const StyledCardPrimary = styled(Card)(props => ({
  "&&": {
    borderRadius: props.theme.app.borderRadius.cardPrimary,
    backgroundColor: props.theme.app.background.cardPrimary,
    padding: '20px 30px',
    boxShadow: '0px 16px 48px rgba(0, 0, 0, 0.176)'
  }
}));

const StyledCardSecondary = styled(Card)(props => ({
  "&&": {
    borderRadius: props.theme.app.borderRadius.cardSecondary,
    backgroundColor: props.theme.app.background.cardSecondary,
    padding: 8,
    boxShadow: 'none'
  }
}));

const MuiCardPrimary = (props: CardProps) => {
  return (
    <StyledCardPrimary {...props} />
  );
}

const MuiCardSecondary = React.forwardRef<HTMLDivElement, CardProps>((props, ref) => {
  return (
    <StyledCardSecondary {...props} ref={ref} />
  );
});

export {
  MuiCardPrimary,
  MuiCardSecondary
};