import { StockPathEntity } from "src/routes/paths";

export const SidebarConfig = [
  { title: "Overview", entity: StockPathEntity.Overview },
  { title: "Estimates", entity: StockPathEntity.Research },
  { title: "Valuations", entity: StockPathEntity.Valuations },
  { title: "Financials", entity: StockPathEntity.Financials },
  // { title: "Peer Comparisons", entity: StockPathEntity.Comparisons },
  { title: "Ownerships", entity: StockPathEntity.Ownerships },
  { title: "News", entity: StockPathEntity.News },
];