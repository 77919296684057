import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';

import * as queries from 'src/graphql/queries';
import { Symbol } from 'src/types';

type GetRecommendedSymbolsResponse = {
  data: {
    getRecommendedSymbols: Symbol[]
  }
}

async function getRecommendedSymbols(): Promise<Symbol[]> {
  const { data: { getRecommendedSymbols }} = 
    await (API.graphql(graphqlOperation(queries.getRecommendedSymbols)) as Promise<GetRecommendedSymbolsResponse>);

  return getRecommendedSymbols;
}

function useRecommendedSymbols(): UseQueryResult<Symbol[]> {
  const options: UseQueryOptions<Symbol[]> = {
    staleTime: 60 * 1000 * 60 * 24
  };

  return useQuery(
    ['recommendedSymbols'],
    () => getRecommendedSymbols(),
    options
  );
}

export default useRecommendedSymbols;