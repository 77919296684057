import { styled } from '@mui/material/styles';
import Typography from "@mui/material/Typography";

const StyledParagraph = styled(Typography)((props) => ({
  "&&": {
    lineHeight: props.theme.app.typography.paragraph.lineHeight
  }
}));

export {
  StyledParagraph
}