import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';

import * as queries from 'src/graphql/queries';
import { Symbol } from 'src/types';

type GetSymbolResponse = {
  data: {
    getSymbol: Symbol
  }
}

async function getSymbolById(id: string | undefined): Promise<Symbol> {
  const { data: { getSymbol }} = 
    await (API.graphql(graphqlOperation(
      queries.getSymbol, 
      { id: id || '' }
    )) as Promise<GetSymbolResponse>);

  return getSymbol;
}

/* get symbol from Symbol table */
function useSymbol(id: string | undefined): UseQueryResult<Symbol> {
  const options: UseQueryOptions<Symbol> = { 
    enabled: !!id,
    staleTime: 0
  };

  return useQuery(
    ['symbol', id], 
    () => getSymbolById(id), 
    options
  );
}

export default useSymbol;