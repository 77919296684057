import { StockIndexEnum } from "./enums";
import { MarketIndex } from "./types";

export const StockIndices: MarketIndex[] = [
  {
    name: StockIndexEnum.Sp,
    label: 'Sp',
    symbol: '^GSPC',
    market: 'US',
    etf: 'SPY'
  },
  {
    name: StockIndexEnum.Nasdaq,
    label: 'Nasdaq',
    symbol: '^NDX',
    market: 'US',
    etf: 'QQQ'
  },
  {
    name: StockIndexEnum.Dow,
    label: 'Dow',
    symbol: '^DJI',
    market: 'US',
    etf: 'DIA'
  },
  {
    name: StockIndexEnum.Russell,
    label: 'Russell',
    symbol: '^RUT',
    market: 'US',
    etf: 'IWM'
  }
];