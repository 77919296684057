import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { uniqBy } from 'lodash';

import * as queries from 'src/graphql/queries';
import { User } from 'src/types';
import { ModelSortDirection } from 'src/enums';

type GetUserResponse = {
  data: {
    getUser: User
  }
}

async function getUserById(id: string | undefined): Promise<User> {
  const { data: { getUser }} = 
    await (API.graphql(graphqlOperation(queries.getUser,
      // TODO: Refactor to able to fetch unique symbols
      { id: id || '', recentSearchLimit: 50, recentSearchSortDirection: ModelSortDirection.DESC }
    )) as Promise<GetUserResponse>);

  return getUser;
}

/* get user from User table */
function useUser(id: string | undefined): UseQueryResult<User> {

  const options: UseQueryOptions<User> = { 
    enabled: !!id,
    refetchOnWindowFocus: false ,
    staleTime: 0,
    select: (user) => {
      const { recentSearches, follows } = user;
      const uniqueRecentSearches = uniqBy(recentSearches.items, 'symbol.symbol');
      const uniqueFollows = uniqBy(follows.items, 'symbol.symbol').sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

      return {
        ...user,
        recentSearches: {
          ...recentSearches,
          items: uniqueRecentSearches
        },
        follows: {
          ...recentSearches,
          items: uniqueFollows
        }
      };
    }
  };

  return useQuery(
    ['user', id], 
    () => getUserById(id), 
    options
  );
}

export default useUser;