
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import {
  Line,
} from "recharts";
import { useTheme } from '@mui/material/styles';

import {
  Grid,
  Box,
  ChipPrimary,
} from 'src/components/mui';
import { StockChart } from 'src/components/charts';
import { useChartOverview, useMarketQuotes} from "src/hooks";
import { displayNumber, displayPercentage } from "src/utils/numberUtils";
import { getChangeColor } from "src/utils";
import { ranges, ChartRange } from 'src/types.d';

function Market({
  symbols,
} : {
  symbols: string[]
}) {
  const theme = useTheme();

  const [range, setRange] = useState<ChartRange>("6m");
  
  const {
    realtimeQuote,
    isMarketOpen,
    isLoading, 
    isSuccess, 
    isFetched
   } = useMarketQuotes(symbols);
  const { data: chartData } = useChartOverview(range, symbols);

  if(isLoading || !isFetched) {
    return <>
      <Skeleton variant="text" width="100%" />
      <Skeleton variant="rectangular" width="100%" height={theme.app.height.loading.rect} />
    </>;
  }

  const prevCloseColor = getChangeColor({ 
    prevChange: realtimeQuote?.latestPrice, 
    change: realtimeQuote?.previousClose,
    isMarketOpen, 
    theme 
  });
  const openColor = getChangeColor({ 
    prevChange: realtimeQuote?.latestPrice, 
    change: realtimeQuote?.open,
    isMarketOpen, 
    theme 
  });
  const highColor = getChangeColor({ 
    prevChange: realtimeQuote?.latestPrice, 
    change: realtimeQuote?.high,
    isMarketOpen, 
    theme 
  });
  const lowColor = getChangeColor({ 
    prevChange: realtimeQuote?.latestPrice, 
    change: realtimeQuote?.low,
    isMarketOpen, 
    theme 
  });
  const ytdChangeColor = getChangeColor({ isCompare: false, change: realtimeQuote?.ytdChange, isMarketOpen, theme });

  const handleChangeRange = (chartRange: ChartRange) => () => {
    setRange(chartRange);
  }

  if(isSuccess) {
    return (
      <>
        <Grid container pl={1}>
          <Grid item xs={6}>
            <Box>
              <Typography variant="body1" component="span">
                <Box display="flex" justifyContent="space-between" color="text.secondary" mb={0.5}>
                  <Box color="text.secondary">
                    { `High: ` }
                  </Box>
                  <Box color={highColor}>
                    { displayNumber({ input: realtimeQuote?.high }) }
                  </Box>
                </Box>
              </Typography>
              <Typography variant="body1" component="span">
                <Box display="flex" justifyContent="space-between" color="text.secondary" mb={0.5}>
                  <Box color="text.secondary">
                    { `Low: ` }
                  </Box>
                  <Box color={lowColor}>
                    { displayNumber({ input: realtimeQuote?.low }) }
                  </Box>
                </Box>
              </Typography>
              <Typography variant="body1" component="span">
                <Box display="flex" justifyContent="space-between" color="text.secondary" mb={0.5}>
                  <Box color="text.secondary">
                    { `52 Week Low: ` }
                  </Box>
                  <Box color="text.secondary">
                    { displayNumber({ input: realtimeQuote?.week52Low }) }
                  </Box>
                </Box>
              </Typography>
              <Typography variant="body1" component="span">
                <Box display="flex" justifyContent="space-between" color="text.secondary" mb={0.5}>
                  <Box color="text.secondary">
                    { `52 Week High: ` }
                  </Box>
                  <Box color="text.secondary">
                    { displayNumber({ input: realtimeQuote?.week52High }) }
                  </Box>
                </Box>
              </Typography>
              <Typography variant="body1" component="span">
                <Box display="flex" justifyContent="space-between" color="text.secondary" mb={0.5}>
                  <Box color="text.secondary">
                    { `Ytd Change: ` }
                  </Box>
                  <Box color={ytdChangeColor}>
                    { displayPercentage({ input: realtimeQuote?.ytdChange }) }
                  </Box>
                </Box>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} pl={2}>
            <Box>
              <Typography variant="body1" component="span">
                <Box display="flex" justifyContent="space-between" color="text.secondary" mb={0.5}>
                  <Box color="text.secondary">
                    { `Open: ` }
                  </Box>
                  <Box color={openColor}>
                    { displayNumber({ input: realtimeQuote?.open }) }
                  </Box>  
                </Box>
              </Typography>
              <Typography variant="body1" component="span">
                <Box display="flex" justifyContent="space-between" color="text.secondary" mb={0.5}>
                  <Box color="text.secondary">
                    { `Pre Close: ` }
                  </Box>
                  <Box color={prevCloseColor}>
                    { displayNumber({ input: realtimeQuote?.previousClose }) }
                  </Box>
                </Box>
              </Typography>
              <Typography variant="body1" component="span">
                <Box display="flex" justifyContent="space-between" color="text.secondary" mb={0.5}>
                  <Box color="text.secondary">
                    { `Volume: ` }
                  </Box>
                  <Box color="text.secondary">
                    { displayNumber({ input: realtimeQuote?.volume, showUnit: true }) }
                  </Box>
                </Box>
              </Typography>
              <Typography variant="body1" component="span">
                <Box display="flex" justifyContent="space-between" color="text.secondary" mb={0.5}>
                  <Box color="text.secondary">
                    { `Avg. Volume: ` }
                  </Box>
                  <Box color="text.secondary">
                    { displayNumber({ input: realtimeQuote?.avgTotalVolume, showUnit: true }) }
                  </Box>
                </Box>
              </Typography>
              <Typography variant="body1" component="span">
                <Box display="flex" justifyContent="space-between" color="text.secondary" mb={0.5}>
                  <Box color="text.secondary">
                    { `Market Cap: ` }
                  </Box>
                  <Box color="text.secondary">
                    { displayNumber({ input: realtimeQuote?.marketCap, showUnit: true }) }
                  </Box>
                </Box>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box 
          width="100%" 
          mt={1}
          sx={{ 
            background: theme.palette.background.default,
            borderRadius: `${theme.shape.borderRadius}px`,
          }}>
          <Grid container display="flex" justifyContent="flex-start" pt={2} pb={2} pl={2}>
            {
              ranges.map((chartRange) => (
                <Grid item key={chartRange} mr={2}>
                  <ChipPrimary 
                    label={chartRange} 
                    textTransform="uppercase"
                    onClick={handleChangeRange(chartRange)}
                    clickable
                    color={ chartRange === range ? 'primary' : undefined }
                  />
                </Grid>
              ))
            }
          </Grid>
          <StockChart data={chartData} range={range}>
            <Line connectNulls dot={false} dataKey={getDataKey('close')} stroke={theme.palette.primary.main} strokeWidth={1.5} isAnimationActive={false} />
          </StockChart>
        </Box>
      </>
    );
  }

  return null;
}

// Convert string value to number so that min and max values can be calculated by rechart domain correctly
function getDataKey(lineDataKey: 'close') {
  return function(data: any) {
    return data[lineDataKey] ? +data[lineDataKey] : null;
  }
}

export default Market;