import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Grid } from 'src/components/mui';
import SymbolSummary from 'src/components/watchlist/SymbolSummary';
import Insights from 'src/components/watchlist/Insights';
import PressRelease from 'src/components/watchlist/PressRelease';
import FeedsContainer from 'src/containers/feeds';
import { useWatchlistSymbols, useUserFollow } from 'src/hooks';
import { toStock } from 'src/routes/navigation';
import { StockPathEntity } from 'src/routes/paths';
import { useCognitoUser, useModal } from 'src/contexts';
import { ModalType } from 'src/enums';
import { Symbol } from 'src/types';
import { theme } from 'src/theme';

function Watchlist() {
  const { authenticatedUser } = useCognitoUser();
  const { dispatch } = useModal();
  const { symbols, isRecommended, user, userFollows } = useWatchlistSymbols(authenticatedUser?.username);
  const { follow, unfollow } = useUserFollow(user?.id);

  function handleFollow(symbol: Symbol | undefined) {
    return function() {
      if(!symbol) {
        return;
      }

      if(!authenticatedUser) {
        dispatch({ type: ModalType.SignIn });
        return;
      }

      follow.mutate({
        userID: user?.id, 
        symbolID: symbol.id
      });
    }
  }

  function handleUnfollow(id: string | undefined) {
    return function(){
      if(!id) {
        return;
      }

      unfollow.mutate(id);
    }
  }

  return symbols ? (
    <div>
      {
        symbols.map((symbol, index) => {
          const userFollow = userFollows?.find(({ symbol: followedSymbol }) => followedSymbol === symbol);

          return (
            <Grid container spacing={1} key={symbol.symbol} p={2} mb={2} bgcolor="white" borderRadius={`${theme.app.borderRadius.cardPrimary}px`}>
              <Grid item xs={12} md={8}>
                <SymbolSummary 
                  symbol={symbol} 
                  showFollowButton={{
                    handleFollow: handleFollow(symbol),
                    handleUnfollow: handleUnfollow(userFollow?.id)
                  }}
                  isRecommended={isRecommended}
                />
                <Box mb={2} p={1}>
                  <PressRelease symbol={symbol.symbol} limit={10} />
                </Box>
                <Insights symbolName={symbol.symbol} />
              </Grid>
              <Grid container item xs={12} md={4} flexDirection="column" >
                <FeedsContainer symbolNames={[symbol.symbol]} itemsPerRow={12} noOfItems={3} />
                <Link 
                  component={RouterLink} 
                  to={toStock(StockPathEntity.News, symbol.id)}
                  underline="none"
                  mt={2}
                  alignSelf="end"
                >
                  <Typography variant="body2" color="primary"> More news </Typography>
                </Link>
              </Grid>
            </Grid>
          )
        })
      }
    </div>
  ) : null;
}

export default Watchlist;