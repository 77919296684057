import Box, { BoxProps as MuiBoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export type BlurProps = {
  blurDirection: 'left' | 'right' | 'none'
}
export type StyledBlurableBoxProps = BlurProps & MuiBoxProps;

const StyledBoxPrimary = styled(Box)(props => ({
  "&&": {
    backgroundColor: props.theme.app.background.cardPrimary,
    width: '100%',

    [props.theme.breakpoints.down('md')]: {
      borderRadius: 0,
      padding: props.theme.spacing(2),
      boxShadow: 'none',
    },

    [props.theme.breakpoints.up("md")]: {
      borderRadius: props.theme.app.borderRadius.cardPrimary,
      padding: props.theme.spacing(2),
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.075)',
    }
  }
}));

const StyledBoxSecondary = styled(Box)(props => ({
  "&&": {
    backgroundColor: props.theme.app.background.cardSecondary,
    padding: props.theme.spacing(2),
    boxShadow: 'none',
    width: '100%',

    [props.theme.breakpoints.down('md')]: {
      borderRadius: 0,
    },

    [props.theme.breakpoints.up("md")]: {
      borderRadius: props.theme.app.borderRadius.cardSecondary,
    }
  }
}));

const StyledBlurableBox = styled(
  ({ blurDirection, ...otherProps }: StyledBlurableBoxProps) => (<Box {...otherProps} />)
)(({ blurDirection, theme }) => ({
  '&::before': {
    content: "''",
    left: 0,
    position: 'absolute',
    width: (blurDirection === 'none' || blurDirection === 'right') ? 0 : 20,
    height: '100%',
    zIndex: theme.zIndex.mobileStepper,
    marginLeft: 'auto',
    top: 0,
    background: `linear-gradient(to left, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .7) 40%, #fff 70%)`
  },
  '&::after': {
    content: "''",
    right: 0,
    position: 'absolute',
    width: (blurDirection === 'none' || blurDirection === 'left') ? 0 : 20,
    height: '100%',
    zIndex: theme.zIndex.mobileStepper,
    marginLeft: 'auto',
    top: 0,
    background: `linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .7) 40%, #fff 70%)`
  }
}));

const MuiBoxPrimary = (props: MuiBoxProps) => {
  return (
    <StyledBoxPrimary {...props} />
  );
}

const MuiBoxSecondary = (props: MuiBoxProps) => {
  return (
    <StyledBoxSecondary {...props} />
  );
}

const MuiBlurableBox = (props: StyledBlurableBoxProps) => {
  return (
    <StyledBlurableBox {...props} />
  );
}

export type BoxProps = MuiBoxProps;

export {
  Box,
  MuiBoxPrimary,
  MuiBoxSecondary,
  MuiBlurableBox,
};