import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { useTheme } from '@mui/material/styles';

import { 
  Box,
  BoxPrimary,
  BoxSecondary,
  CardHeaderDefault,
  ChipPrimary
} from "src/components/mui";
import OwnershipTable from "src/components/tables/OwnershipTable";
import InsightDefinitionContainer from "src/components/insight/InsightDefinitionContainer";
import InsightContainer from "src/components/insight/InsightContainer";
import TitleWithCurrency from "src/components/TitleWithCurrency";
import { InstitutionalInvestorsConfigs, FundConfigs, InsiderTransactionConfigs } from "./tablesConfig";
import { 
  useOwnershipInstitutionalHolderUi, 
  useOwnershipEtfStockUi, 
  useOwnershipInstitutionalSummaryUi,
  useOwnershipInsiderTransactionUi,
  useInsights
 } from "src/hooks";
import { displayNumber, displayPercentage } from "src/utils/numberUtils";
import { displayString } from "src/utils/stringUtils";
import { renderChangeDirection } from "src/utils";
import { QuoteChange } from "src/enums";
import * as queries from 'src/graphql/queries';

function Ownerships({
  symbol
} : {
  symbol: string | undefined
}) {
  const theme = useTheme();

  const symbols = symbol ? [symbol] : [];

  const { 
    data: institutionalInsightData, 
    isFetched: isInstitutionalInsightFetched, 
    isLoading: isInstitutionalInsightLoading, 
    isSuccess: isInstitutionalInsightSuccess 
  } = useInsights(symbols, queries.getInsightOwnershipInstitution);
  const institutionalInsights = institutionalInsightData?.data.getInsightOwnershipInstitution
    .find(({ symbol: eachSymbol }) => eachSymbol === symbol)?.insights;

  const { 
    data: insiderInsightData, 
    isFetched: isInsiderInsightFetched, 
    isLoading: isInsiderInsightLoading, 
    isSuccess: isInsiderInsightSuccess 
  } = useInsights(symbols, queries.getInsightOwnershipInsider);
  const insiderInsights = insiderInsightData?.data.getInsightOwnershipInsider
    .find(({ symbol: eachSymbol }) => eachSymbol === symbol)?.insights;

  const { 
    data: institutionalData, 
    isLoading: isInstitutionalLoading, 
    isFetched: isInstitutionalFetched, 
    isError: isInstitutionalError 
  } = useOwnershipInstitutionalHolderUi(symbol);

  const { 
    data: fundData, 
    isLoading: isFundLoading, 
    isFetched: isFundFetched, 
    isError: isFundError 
  } = useOwnershipEtfStockUi(symbol);

  const { 
    data: institutionalSummaryData,
    isFetched: isInstitutionalSummaryFetched, 
    isLoading: isInstitutionalSummaryLoading,
  } = useOwnershipInstitutionalSummaryUi(symbol);

  const {
    data: insiderTransData, 
    isLoading: isInsiderTransLoading, 
    isFetched: isInsiderTransFetched, 
    isError: isInsiderTransError
  } = useOwnershipInsiderTransactionUi(symbol);

  const totalInvestorsChange = institutionalSummaryData?.investorsHoldingChange;
  const totalInvestedChange = institutionalSummaryData?.totalInvestedChangePercentage;

  const totalInvestorChangeDirection = Number(totalInvestorsChange) > 0 
    ? QuoteChange.Up
    : Number(totalInvestorsChange) === 0 
    ? QuoteChange.Flat 
    : QuoteChange.Down;

  const totalInvestedChangeDirection = Number(totalInvestedChange) > 0 
    ? QuoteChange.Up 
    : Number(totalInvestedChange) === 0 
    ? QuoteChange.Flat 
    : QuoteChange.Down;

  const totalInvestorsLable = `${renderChangeDirection(totalInvestorChangeDirection)} ${totalInvestorsChange}`;

  const isSummaryLoading = isInstitutionalSummaryLoading || !isInstitutionalSummaryFetched;

  return (
    <>
      <BoxPrimary mb={2}>
        <CardHeaderDefault title="Institutional Investors" sx={{ mb: 1 }} />
        <BoxSecondary display="flex" flexDirection="column" mb={2}>
          <Typography 
            variant="caption" 
            color="textDisabled" 
            sx={{ alignSelf: "flex-end" }}
            mb={1}
          >
            {`Updated: ${displayString(institutionalSummaryData?.date)}`}
          </Typography>
          <Box display="flex" justifyContent="space-evenly">
            <Box>
              <Box display="flex">
                {
                  isSummaryLoading ? (
                    <Skeleton variant="text" width={100} />
                  ) : (
                    <>
                      <Typography 
                        variant="h6" 
                        sx={{ alignSelf: "flex-end", mr: 0.5, color: theme.palette.text.disabled }}
                      >
                        {institutionalSummaryData?.investorsHolding}
                      </Typography>
                      <ChipPrimary 
                        label={totalInvestorsLable}
                        sx={{ 
                          backgroundColor: totalInvestorChangeDirection === QuoteChange.Down ? "#F96E51" : "#2E93FD", 
                          color: "white",
                          fontWeight: 500,
                        }} 
                      />
                    </>
                  )
                }
              </Box>
              <Typography 
                variant="body1"  
                color="textSecondary" 
                sx={{ alignSelf: "flex-end"}}
              >
                Total Investors
              </Typography>
            </Box>
            <Box>
              <Box display="flex">
                {
                  isSummaryLoading ? (
                    <Skeleton variant="text" width={100} />
                  ) : (
                    <>
                      <Typography 
                        variant="h6" 
                        color="textDisabled" 
                        sx={{ alignSelf: "flex-end", mr: 0.5, color: theme.palette.text.disabled }}
                      >
                        {displayNumber({ input: institutionalSummaryData?.totalInvested, showUnit: true })}
                      </Typography>
                      <ChipPrimary 
                        label={displayPercentage({ input: totalInvestedChange, isMultiple: false })}
                        sx={{ 
                          backgroundColor: totalInvestedChangeDirection === QuoteChange.Down ? "#F96E51" : "#2E93FD", 
                          color: "white",
                          fontWeight: 500,
                        }} 
                      />
                    </>
                  )
                }
              </Box>
              <Typography 
                variant="body1"  
                color="textSecondary" 
                sx={{ alignSelf: "flex-end"}}
              >
                Total Shares
              </Typography>
            </Box>
          </Box>
        </BoxSecondary>
        <Box mb={2}>
          <Box display="flex" justifyContent="flex-end" mb={1}>
            <InsightDefinitionContainer />
          </Box>
          <InsightContainer 
            insights={institutionalInsights}
            isLoading={isInstitutionalInsightLoading}
            isSuccess={isInstitutionalInsightSuccess}
            isFetched={isInstitutionalInsightFetched}
          />
        </Box>
        <OwnershipTable 
          rows={institutionalData}
          columns={InstitutionalInvestorsConfigs}
          isLoading={isInstitutionalLoading}
          isFetched={isInstitutionalFetched}
          isError={isInstitutionalError}
        />
      </BoxPrimary>

      <BoxPrimary mb={2}>
        <TitleWithCurrency title="Related Funds" currency="USD" />
        <OwnershipTable 
          rows={fundData}
          columns={FundConfigs}
          isLoading={isFundLoading}
          isFetched={isFundFetched}
          isError={isFundError}
        />
      </BoxPrimary>

      <BoxPrimary mb={2}>
        <TitleWithCurrency title="Insider Transaction" currency="USD" />
        <Box mb={2}>
          <Box display="flex" justifyContent="flex-end" mb={1}>
            <InsightDefinitionContainer />
          </Box>
          <InsightContainer 
            insights={insiderInsights}
            isLoading={isInsiderInsightLoading}
            isSuccess={isInsiderInsightSuccess}
            isFetched={isInsiderInsightFetched}
          />
        </Box>
        <OwnershipTable 
          rows={insiderTransData}
          columns={InsiderTransactionConfigs}
          isLoading={isInsiderTransLoading}
          isFetched={isInsiderTransFetched}
          isError={isInsiderTransError}
        />
      </BoxPrimary>
    </>
  )
}

export default Ownerships;