import { useQueryClient, useMutation, UseMutationResult } from 'react-query';
import { Auth } from 'aws-amplify';

type Error = {
  message: string;
  __type: string;
}

function signOut(): Promise<unknown> {
  return Auth.signOut();
}

/* get the cognito authenticated user */
function useSignOut(): UseMutationResult<unknown, Error, any, unknown> {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, any, unknown>(
    () => signOut(), {
      onSuccess: () => {
        queryClient.invalidateQueries(['authenticatedUser']);
      }
    }
  );
}

export default useSignOut;