import Skeleton from '@mui/material/Skeleton';

import {
  CardHeaderDefault,
  Grid,
  CardSecondary,
} from 'src/components/mui';
import Feed from 'src/components/feed';
import { useCognitoUser } from 'src/contexts';
import { useWatchlistSymbols, useRecommendedFeeds } from 'src/hooks';

function FeedsContainer({
  noOfItems = 10,
  symbolNames,
  itemsPerRow = 12,
} : {
  noOfItems?: number,
  symbolNames?: string[];
  itemsPerRow?: 6 | 12 // grid item value
}) {
  const { authenticatedUser } = useCognitoUser();
  const { symbols } = useWatchlistSymbols(authenticatedUser?.username);
  const symbolsToFetch = symbolNames || (symbols ? symbols?.map(({ symbol }) => symbol) : []);

  const { data, isFetched } = useRecommendedFeeds(symbolsToFetch);

  if(!isFetched) {
    return (
      <>
        <CardHeaderDefault title="Feeds" />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <CardSecondary>
              <Skeleton variant="rectangular" height="100px" width="100%" sx={{ mb: 1 }} />
            </CardSecondary>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardSecondary>
              <Skeleton variant="rectangular" height="100px" width="100%" sx={{ mb: 1 }} />
            </CardSecondary>
          </Grid>
        </Grid>
      </>
    );
  }

  const feeds = data?.slice(0, noOfItems);

  return (
    <>
      <CardHeaderDefault title="Feeds" sx={{ mb: 1 }} />
      <Grid container spacing={1}>
        {
          feeds?.map(({ symbol, title, site, text, url, publishedDate, image }) => {
            const symbolId = symbols?.find(({ symbol: symbolName }) => symbolName === symbol)?.id || '';
            return (
              <Grid item key={title} xs={12} sm={itemsPerRow}>
                <Feed url={url} title={title} site={site} text={text} publishedDate={publishedDate} symbolId={symbolId} symbol={symbol} image={image} />
              </Grid>
            )
          })
        }
      </Grid>
    </>
  );
}

export default FeedsContainer;