import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { startCase, toLower } from 'lodash';

import { SkeletonLoader } from '..';
import { StyledPressReleaseContainer } from './styled';
import { usePressReleaseBySymbol } from 'src/hooks';
import { useModal } from "src/contexts";
import { displayDate } from 'src/utils/dateUtils';
import { displayString } from 'src/utils/stringUtils';
import { PressRelease as TPressRelease } from 'src/types';
import { ModalType } from 'src/enums';

function PressRelease({
  stack = false,
  symbol,
  limit = 5,
} : {
  stack?: boolean;
  symbol: string | null;
  limit?: number;
}) {
  const theme = useTheme();

  const { data, isLoading, isFetched } = usePressReleaseBySymbol(symbol, limit);
  const { dispatch: dispatchModal } = useModal();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down('md'));

  if(isLoading || !isFetched) {
    return (
      <SkeletonLoader noOfLoader={10} height={20} isColumn={true} />
    )
  }

  function handleClickPressReleaseDetail(pressRelease: TPressRelease) {
    return function() {
      dispatchModal({ type: ModalType.PressRelease, data: pressRelease })
    }
  }

  const isStack = stack || matchesDownSm;

  return (
    <>
      {
        data?.pages.map((page) => (
          page.items.map((pressRelease) => (
            <StyledPressReleaseContainer 
              key={pressRelease.id}
              display="flex" 
              flexDirection={isStack ? "column-reverse" : "row"}
              p={1} 
              sx={{ 
                whiteSpace: isStack ? "unset" : "nowrap",
                cursor: "pointer",
                '&:hover': {
                  backgroundColor: theme.palette.background.default
                }
              }}
              onClick={handleClickPressReleaseDetail(pressRelease)}
            >
              <Typography variant="body2" color={theme.palette.text.disabled} mr={2}>{displayDate(pressRelease.datetime)}</Typography>  
              <Typography 
                variant="body1" 
                color={theme.palette.text.secondary}
                noWrap={!isStack}
              >
                {startCase(toLower(displayString(pressRelease.title)))}
              </Typography>
            </StyledPressReleaseContainer>
          ))
        ))
      }
    </>
  );
}

export default PressRelease;