import { useRef } from "react";
import Typography from "@mui/material/Typography";
import CardMedia from '@mui/material/CardMedia';
import Skeleton from '@mui/material/Skeleton';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import {
  Box,
  BoxPrimary,
  CardSecondary,
  ChipSecondary,
  CardHeaderDefault
} from 'src/components/mui';
import { useNewsBySymbol, useIntersectionObserver } from "src/hooks";
import { displayDateLocale } from "src/utils/dateUtils";

function News({
  symbol
} : {
  symbol: string | undefined
}) {

  const theme = useTheme();
  const observerTarget = useRef<HTMLDivElement>(null);
  const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const { data, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage } = useNewsBySymbol(symbol);
  
  useIntersectionObserver({
    target: observerTarget,
    onIntersect: fetchNextPage,
    enabled: hasNextPage || false
  })

  function handleClickSymbol(relatedSymbol: string) {
    return function() {
      console.log(`navigate to ${relatedSymbol}`);
    }
  }

  if(isFetching && !isFetchingNextPage) {
    return (
      <BoxPrimary mb={2} style={{ width: '100%' }}>
        <CardHeaderDefault title="News" sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" height="100px" width="100%" sx={{ mb: 1 }} />
        <Skeleton variant="rectangular" height="100px" width="100%" sx={{ mb: 1 }}  />
        <Skeleton variant="rectangular" height="100px" width="100%" sx={{ mb: 1 }}  />
        <Skeleton variant="rectangular" height="100px" width="100%" sx={{ mb: 1 }}  />
        <Skeleton variant="rectangular" height="100px" width="100%" sx={{ mb: 1 }}  />
      </BoxPrimary>
    );
  }

  return (
    <BoxPrimary mb={2} style={{ width: '100%' }}>
      <CardHeaderDefault title="News" sx={{ mb: 2 }} />
      {
        data?.pages.map((page) => (
          page.items.map((news) => {
            const relatedSymbols = news.relatedSymbols ?? [];

            return (
              <CardSecondary 
                key={news.headline}
                sx={{ display: "flex", justifyContent: "space-between", mt: 1, mb: 1, padding: 2 }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                  <Box sx={{ mb: 1 }}>
                    <Link href={news.url} target="_blank" underline="none" rel="noopener">
                      <Typography variant="body1" color="textSecondary" sx={{ fontWeight: 600, mb: 0.5 }}>
                        {news.headline}
                      </Typography>
                    </Link>
                    
                    <Box sx={{ display: "flex", }}>
                      {
                        relatedSymbols.map((relatedSymbol) => (
                          <ChipSecondary 
                            key={relatedSymbol}
                            label={relatedSymbol} 
                            color="primary"
                            onClick={handleClickSymbol(relatedSymbol)}
                            variant="outlined"
                            sx={{ mr: 1 }} 
                          />
                        ))
                      }
                    </Box>
                  </Box>
                  {/* <Typography variant="body1" color="textSecondary" component="p">
                    {news.summary}
                  </Typography> */}
                  <Box display="flex" mt={1}>
                    <Box mr={1}>
                      <Typography variant="body2" color={theme.palette.text.disabled}>{displayDateLocale(news.datetime)}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" color={theme.palette.text.disabled}>{news.source}</Typography>
                    </Box>
                  </Box>
                </Box>
                {
                  news.image && matchesUpMd ? (
                    <CardMedia
                      component="img"
                      sx={{ width: 186, height: 120 }}
                      image={news.image}
                    />
                  ) : null
                }
              </CardSecondary>
            );
          })
        ))
      }
      {
        !isFetchingNextPage && hasNextPage ? (
          <Box ref={observerTarget} display="flex" justifyContent="center" width="100%" height="1px" />
        ) : null
      }
      {
        isFetchingNextPage ? (
          <Skeleton variant="rectangular" style={{ width: '100%', height: 100 }} />
        ) : null
      }
    </BoxPrimary>
  );
}

export default News;