import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';

import * as queries from 'src/graphql/queries';
import { FmpTicker } from 'src/types';

type GetMostActiveStocksResponse = {
  data: {
    getMostActiveStocks: FmpTicker[]
  }
}

async function getMostActiveStocks(): Promise<FmpTicker[]> {
  const { data: { getMostActiveStocks }} = 
    await (API.graphql(graphqlOperation(queries.getMostActiveStocks)) as Promise<GetMostActiveStocksResponse>);

  return getMostActiveStocks;
}

function useMostActiveStocks(): UseQueryResult<FmpTicker[]> {
  const options: UseQueryOptions<FmpTicker[]> = {
    staleTime: 60 * 1000 * 60 * 12
  };

  return useQuery(
    ['mostActiveStocks'],
    () => getMostActiveStocks(),
    options
  );
}

export default useMostActiveStocks;