import React, { useEffect } from 'react'

interface IntersectionObserverOptions extends IntersectionObserverInit {
  target: React.RefObject<Element | HTMLDivElement>,
  onIntersect: Function,
  enabled: boolean;
}

function useIntersectionObserver({
  root = null,
  target,
  onIntersect,
  threshold = 1.0,
  rootMargin = '0px',
  enabled = true,
}: IntersectionObserverOptions) {
  React.useRef()
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const observer = new IntersectionObserver(
      entries =>
        entries.forEach(entry => entry.isIntersecting && onIntersect()),
      {
        root,
        rootMargin,
        threshold,
      }
    );

    const el = target && target.current;

    if (!el) {
      return;
    }

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    }

  // eslint-disable-next-line
  }, [target.current, enabled, JSON.stringify(threshold), rootMargin]);
}

export default useIntersectionObserver;
