import Typography from "@mui/material/Typography";
import Skeleton from '@mui/material/Skeleton';
import { useValuationUi } from "src/hooks";
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import {
  Grid,
  Box,
  BoxPrimary,
  CardHeaderDefault
} from 'src/components/mui';
import Metrics from "./Metrics";
import TitleWithCurrency from "src/components/TitleWithCurrency";
import { FinancialTable } from "src/components/tables";
import { displayNumber } from "src/utils/numberUtils";
import { displayString } from "src/utils/stringUtils";

function Valuations({
  symbol
} : {
  symbol: string | undefined
}) {

  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down('md'));

  const { data, isLoading, isFetched, isError } = useValuationUi(symbol);

  const sharedPriceTextAlign = matchesDownSm ? 'left' : 'center';

  return (
    <>
      <Grid container spacing={1} mb={2}>
        <Grid item md={6} xs={12}>
          <BoxPrimary>
            <TitleWithCurrency title="Valuations" currency="USD" />
            <Box overflow="auto">
              <FinancialTable 
                data={data?.valuationBrief.valuationBrief} 
                isLoading={isLoading} 
                isFetched={isFetched}
                isError={isError}
              />
            </Box>
          </BoxPrimary>
        </Grid>
        <Grid item container md={6} xs={12} flexDirection="column" spacing={1}>
          <Grid item>
            <BoxPrimary>
              <CardHeaderDefault title="Implied Share Price" sx={{ mb: 1 }} />
              <Typography variant="h6" color="primary" textAlign={sharedPriceTextAlign}>
                {`${displayString(data?.valuationSharePrice.currency)} ${displayNumber({ input: data?.valuationSharePrice.valuationSharePrice })}`}
              </Typography>
            </BoxPrimary>
          </Grid>
          <Grid item>
            <BoxPrimary>
              <CardHeaderDefault title="Assumptions" />
              <Box overflow="auto">
                <FinancialTable 
                  data={data?.valuationAssumptions.valuationAssumptions} 
                  isLoading={isLoading} 
                  isFetched={isFetched}
                  isError={isError}
                  loadingComponent={
                    <>
                      <Skeleton variant="text" width="100%" sx={{ mb: 1 }} />
                      <Skeleton variant="rectangular" height="100px" width="100%" sx={{ mb: 1 }} />
                      <Skeleton variant="text" width="100%" />
                    </>
                  }
                />
              </Box>
            </BoxPrimary>
          </Grid>
        </Grid>
      </Grid>
      <Metrics symbol={symbol} valuationMetrics={data?.valuationMetrics.valuationMetrics} />
      <BoxPrimary mb={2}>
        <TitleWithCurrency title="Financial Projections" currency="USD" />
        <Box overflow="auto">
          <FinancialTable 
            data={data?.valuationProjections.valuationProjections} 
            titleList={data?.valuationProjections.titleList}
            isLoading={isLoading} 
            isFetched={isFetched}
            isError={isError}
          />
        </Box>
      </BoxPrimary>
      <BoxPrimary mb={2}>
        <TitleWithCurrency title="Projected Unlevered Free Cash Flow" currency="USD" />
        <Box overflow="auto">
          <FinancialTable 
            data={data?.valuationFreeCashFlow.valuationFreeCashFlow} 
            titleList={data?.valuationFreeCashFlow.titleList}
            isLoading={isLoading} 
            isFetched={isFetched}
            isError={isError}
          />
        </Box>
      </BoxPrimary>
    </>
    
  )
}

export default Valuations;