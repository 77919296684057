import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { API, graphqlOperation } from 'aws-amplify';

import * as queries from 'src/graphql/queries';
import { Rating, Connection, ModelStringKeyConditionInput } from 'src/types';
import { ModelSortDirection } from 'src/enums';

type Response = {
  data: {
    getRatingsBySymbolByDatetime: Connection<Rating>
  }
}

async function getRatingsBySymbolByDatetime(
  symbol: string | undefined, 
  datetime: Partial<ModelStringKeyConditionInput>
): Promise<Connection<Rating>> {
  const { data: { getRatingsBySymbolByDatetime }} = 
    await (API.graphql(graphqlOperation(queries.getRatingsBySymbolByDatetime,
      { symbol: symbol || '', datetime, filter: { provider: { eq: 'FMP' } }, sortDirection: ModelSortDirection.DESC }
    )) as Promise<Response>);

  return getRatingsBySymbolByDatetime;
}

function useRatingsBySymbolAndDate(
  symbol: string | undefined, 
  datetime: Partial<ModelStringKeyConditionInput>
): UseQueryResult<Connection<Rating>> {

  const options: UseQueryOptions<Connection<Rating>> = { 
    enabled: !!symbol,
  };

  return useQuery(
    ['getRatingsBySymbolByDatetime', symbol, datetime], 
    () => getRatingsBySymbolByDatetime(symbol, datetime), 
    options
  );
}

export default useRatingsBySymbolAndDate;