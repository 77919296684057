const days = ['Sun','Mon','Tue','Wed','Thur','Fri','Sat'];
// const months = ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sep','Oct','Nov','Dec'];

export function displayDate(input: string) {
  if(!input) {
    return "";
  }

  try {
    const date = new Date(input);
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

    if(isNaN(date.getMonth()) || isNaN(date.getDate())) {
      // date format is not support in IE or Safari
      return '';
    }

    return `${date.getFullYear()}-${month}-${day}`;
  } catch(err) {
    console.error(err);
    return "";
  }
}

export function displayDateLocale(input: string) {
  if(!input) {
    return "";
  }

  try {
    const date = new Date(input);

    if(isNaN(date.getDay())) {
      // date format is not support in IE or Safari
      return '';
    }

    return `${days[date.getDay()]} ${date.toLocaleString()}`;
  } catch(err) {
    console.error(err);
    return "";
  }
}

export function displayTime(input: string | undefined): string {
  const miliseconds = Number(input);

  if(!input || isNaN(miliseconds)) {
    return "";
  }

  const time = new Date(miliseconds);

  return time.toLocaleTimeString();
}