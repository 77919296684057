import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { Auth } from 'aws-amplify';

import { useNotification } from 'src/contexts';
import { ActionType } from 'src/parts/Notification/types.d';

type VerifyAttr = 'email' | 'phone_number';

type VerifyCurrentUserEmailSubmitInput = {
  attr: VerifyAttr;
  code: string; 
}

type Error = {
  message: string;
  __type: string;
}

function verifyCurrentUserAttributeSubmit(attr: VerifyAttr, code: string): Promise<string> {
  return Auth.verifyCurrentUserAttributeSubmit(attr, code);
}

function useVerifyCurrentUserAttributeSubmit(): UseMutationResult<string, Error, VerifyCurrentUserEmailSubmitInput, unknown> {
  const queryClient = useQueryClient();
  const { dispatch } = useNotification();

  return useMutation<string, Error, VerifyCurrentUserEmailSubmitInput, unknown>(
    ({ attr, code }) => verifyCurrentUserAttributeSubmit(attr, code),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['authenticatedUser']);
        dispatch({
          type: ActionType.PushToQueue,
          data: {
            message: {
              key: new Date().getTime(),
              message: 'Email is updated successfully!',
              severity: 'success'
            }
          }
        });
      }
    }
  );
}

export default useVerifyCurrentUserAttributeSubmit;