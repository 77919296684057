import Typography from "@mui/material/Typography";

import { Box } from 'src/components/mui';

function NoData() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Typography variant="body1" color="textSecondary" fontWeight={600}>No Data</Typography>
    </Box>
  )
}

export default NoData;